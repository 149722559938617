import axios from "axios";
import { headerConfig } from "./Firebase";

import { apiUrl, AUTODATA_KEY, POWERTRAIN } from "./config";
import {
  IAHBDiscountDev,
  IAHBKpis,
  IAHBMeasures,
  IAHBRatings,
  IAHBS2Cloud,
  IAHBSoV,
  IAHBUserDiscussions,
  ITarget,
  IModel,
  ISummary,
  TargetType,
  IDownloadJob,
  IMarket,
} from "./Ifaces.d";

export const summaryRequest = async (
  region: string,
  targetType: TargetType,
  items: (ITarget | IModel)[],
): Promise<ISummary> => {
  const data = {
    region: region,
    targetType: targetType,
    ahb_ids: (items.length && items.map((i) => i.ahb_id)) || [],
  };
  const res = await axios.get(
    `${apiUrl}/summary?ahb_ids=${data.ahb_ids.join(
      ",",
    )}&region=${region}&target_type=${targetType.toUpperCase()}`,
    await headerConfig(),
  );
  return res.data as ISummary;
};

export const fetchRatings = async (
  region: string,
  ahb_id: string,
): Promise<IAHBRatings> => {
  const res = await axios.get(
    `${apiUrl}/ratings/${ahb_id}?region=${region}`,
    await headerConfig(),
  );
  return res.data as IAHBRatings;
};

export const fetchUserDiscussions = async (
  ahb_id: string,
  targetType: TargetType,
  region: string,
): Promise<IAHBUserDiscussions> => {
  const res = await axios.get(
    `${apiUrl}/user-discussions/${ahb_id}?target_type=${targetType}&region=${region}`,
    await headerConfig(),
  );
  return res.data as IAHBUserDiscussions;
};

export const fetchSoV = async (
  ahb_id: string,
  topic: string,
  targetType: TargetType,
  region: string,
): Promise<IAHBSoV> => {
  const res = await axios.get(
    `${apiUrl}/sov/${ahb_id}?topic=${topic}&target_type=${targetType}&region=${region}`,
    await headerConfig(),
  );
  return res.data as IAHBSoV;
};

export const fetchS2Clouds = async (
  ahb_id: string,
  targetType: TargetType,
  region: string,
): Promise<IAHBS2Cloud> => {
  const res = await axios.get(
    `${apiUrl}/s2-cloud/${ahb_id}?target_type=${targetType}&region=${region}`,
    await headerConfig(),
  );
  return res.data as IAHBS2Cloud;
};

export const fetchDiscountDev = async (
  ahb_id: string,
  targetType: TargetType,
  region: string,
  end_date: string,
  vid: string,
): Promise<IAHBDiscountDev> => {
  const res = await axios.get(
    `${apiUrl}/discount-dev/${ahb_id}?vid=${vid}&end_date=${end_date}&target_type=${targetType}&region=${region}`,
    await headerConfig(),
  );
  return res.data as IAHBDiscountDev;
};

export const fetchMeasures = async (
  ahb_id: string,
  targetType: TargetType,
  region: string,
): Promise<IAHBMeasures> => {
  const res = await axios.get(
    `${apiUrl}/measures/${ahb_id}?target_type=${targetType}&region=${region}`,
    await headerConfig(),
  );
  return res.data as IAHBMeasures;
};

export const fetchKpis = async (): Promise<IAHBKpis> => {
  const res = await axios.get(
    `${apiUrl}/notification-metrics`,
    await headerConfig(),
  );
  return res.data as IAHBKpis;
};

export const createDownloadJob = async (
  brands: ITarget[],
  powertrain: POWERTRAIN[], // If empty, it will return all
  fieldlist: AUTODATA_KEY[],
): Promise<IDownloadJob> => {
  const downloadJobPayload = {
    job_type: "autodata",
    params: {
      brands: brands.map((b) => b.ahb_id),
      powertrain: powertrain,
      fieldlist: fieldlist,
    },
  };

  const res = await axios.post(
    `${apiUrl}/download/jobs`,
    downloadJobPayload,
    await headerConfig(),
  );

  const downloadJobResult = res.data as IDownloadJob;
  return downloadJobResult;
};

export type DownloadURL = string;

export type DownloadJobPayload = { [key: string]: any };

export const createRatingsDownloadJob = async (
  brands: ITarget[],
  models: ITarget[],
  region: IMarket
): Promise<DownloadURL> => {
  const payload: DownloadJobPayload = { job_type: "ratings", params: {
    brands: brands.map(brand => brand.ahb_id),
    models: models.map(model => model.ahb_id),
    region: region.code
  } };

  const res = await axios.post(
    `${apiUrl}/download/jobs`,
    payload,
    await headerConfig(),
  );

  const url = res.data.storage_filepath as DownloadURL;
  return url;
};

export const createShareOfVoiceDownloadJob = async (
  brands: ITarget[],
  models: ITarget[],
  region: IMarket,
  topic?: string
): Promise<DownloadURL> => {

  const payload: DownloadJobPayload = { job_type: "share_of_voice", params: {
    brands: brands.map(brand => brand.ahb_id),
    models: models.map(model => model.ahb_id),
    region: region.code,
    topic
  }};

  const res = await axios.post(
    `${apiUrl}/download/jobs`,
    payload,
    await headerConfig(),
  );

  const url = res.data.storage_filepath as DownloadURL;
  return url;
};


export const createUserDiscussionsDownloadJob = async (
    brands: ITarget[],
    models: ITarget[],
    region: IMarket,
    topic?: string
  ): Promise<DownloadURL> => {
  
  const payload: DownloadJobPayload = { job_type: "user_discussions", params: {
    brands: brands.map(brand => brand.ahb_id),
    models: models.map(model => model.ahb_id),
    region: region.code,
    topic
  } };

  const res = await axios.post(
    `${apiUrl}/download/jobs`,
    payload,
    await headerConfig(),
  );

  const url = res.data.storage_filepath as DownloadURL;
  return url;
};
