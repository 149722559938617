import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import { useCookies } from "react-cookie";

import theme from "../theme";
import { auth } from "../Firebase";
import logoHeartbeat from "../assets/logo-heartbeat.png";
import logoPoweredBy from "../assets/logo-powered-by.png";
import LoginWidget from "../components/LoginWidget";

const bg = "url('" + process.env.PUBLIC_URL + `/assets/berylls-bg.jpg')`;

const styles = (theme: Theme) =>
  createStyles({
    flexRoot: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "stretch",
    },
    introDiv: {
      flexGrow: 1,
      height: "100vh",
      padding: "106px 120px 46px",
      background: `${bg} no-repeat center center fixed`,
      backgroundSize: "cover",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "stretch",
    },
    introDivSmall: {
      flex: 1,
      minHeight: "50%",
      paddingLeft: "30px",
      paddingTop: "30px",
      paddingBottom: "30px",
      background: `${bg} no-repeat center center fixed`,
      backgroundSize: "cover",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "stretch",
    },
    title: {
      maxWidth: 449,
      color: theme.palette.text.secondary,
      textTransform: "uppercase",
      fontFamily: "Rajdhani",
      fontSize: "70px",
      lineHeight: "60px",
    },
    titleSmall: {
      maxWidth: 449,
      color: theme.palette.text.secondary,
      textTransform: "uppercase",
      fontFamily: "Rajdhani",
      fontSize: "2.5rem",
      lineHeight: "45px",
    },
    loginDiv: {
      height: "100vh",
      paddingLeft: "80px",
      paddingRight: "80px",
      background: "#fff",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    reverseColors: {
      color: "#000000",
      backgroundColor: "#ffffff",
      border: "1px solid black",
    },
    noBoxShadow: {
      boxShadow: "none",
    },
    specialPadding: {
      padding: "6px 24px",
    },
  });

const useStyles = makeStyles(styles);

interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

export default function Start() {
  const [isSmall, setIsSmall] = React.useState(false);

  const [windowSize, setWindowSize] = React.useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  const classes = useStyles(theme);

  // https://gdpr.eu/cookies/
  // State variable to control whether to show the cookie consent modal.
  const [showConsentRequest, setShowConsentRequest] = React.useState(false);

  const [cookies, setCookie] = useCookies([
    "privacy_modal_displayed",
    "privacy_consent_functional",
    "preferences",
  ]);

  const [loginErrorMsg, setLoginErrorMsg] = React.useState<string>("");

  React.useEffect(() => {
    if (cookies) {
      if (!cookies.privacy_modal_displayed) {
        setShowConsentRequest(true);
      }
    }
  }, [setShowConsentRequest, cookies]);

  const handleConsentAgreement = () => {
    setShowConsentRequest(false);
    setCookie("privacy_modal_displayed", true, { sameSite: true });
    setCookie("privacy_consent_functional", true, { sameSite: true });
  };

  const handleConsentDisagreement = () => {
    setShowConsentRequest(false);
    setCookie("privacy_modal_displayed", true, { sameSite: true });
    setCookie("privacy_consent_functional", false, { sameSite: true });
  };

  const login = async (email: string, pwd: string) => {
    try {
      await auth.signInWithEmailAndPassword(email, pwd);
    } catch (error: any) {
      console.log(error);
      setLoginErrorMsg(error.message);
    }
  };

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setIsSmall(window.innerWidth < 1000);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={classes.flexRoot}
      style={{
        flexDirection: isSmall ? "column" : "row",
      }}
    >
      <Dialog
        fullWidth
        maxWidth="xs"
        open={showConsentRequest}
        onClose={() => setShowConsentRequest(false)}
        aria-labelledby="1st-party-cookie-consent"
        aria-describedby="1st-party-cookie-consent-description"
      >
        <DialogTitle id="1st-party-cookie-consent" disableTypography={true}>
          Allow to store data?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="1st-party-cookie-consent-description">
            Berylls' Automotive Cockpit requires your permission to store data
            in your browser. The data stored allows the application to remember
            your user settings.
            <br />
            <br />
            Do you grant permission?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConsentDisagreement}
            className={classes.reverseColors}
            classes={{
              root: classes.specialPadding,
              contained: classes.noBoxShadow,
            }}
          >
            disagree
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleConsentAgreement}
            classes={{
              contained: classes.noBoxShadow,
            }}
          >
            agree
          </Button>
        </DialogActions>
      </Dialog>

      <div className={isSmall ? classes.introDivSmall : classes.introDiv}>
        <Typography className={isSmall ? classes.titleSmall : classes.title} >Automotive Heartbeat</Typography>
        <Grid
          container
          direction="column"
          justify="flex-end"
          alignItems="flex-start"
        >
          <img src={logoHeartbeat} alt="Berylls' Automotive Heartbeat" />
          <img
            src={logoPoweredBy}
            alt="Powered by Berylls"
            style={{ marginTop: 24 }}
          />
        </Grid>
      </div>
      <div className={classes.loginDiv}>
        {cookies.privacy_consent_functional === "true" ? (
          <LoginWidget
            email=""
            pwd=""
            errorMsg={loginErrorMsg}
            onClick={login}
          />
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography>
              Login is not possible without permission to store user data in the
              browser.
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setShowConsentRequest(true)}
              classes={{
                contained: classes.noBoxShadow,
              }}
              style={{ marginTop: 40 }}
            >
              Show consent dialog
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
