import * as React from "react";

function SvgCalendarIcon(props) {
  return (
    <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#000" fillRule="evenodd">
        <path d="M2 4h1v13H2zM5 2h1v4H5zM14 2h1v4h-1zM3 16h14v1H3zM5 12h2v1H5zM5 10h2v1H5zM5 8h2v1H5z" />
        <path d="M3 4h15v1H3zM9 12h2v1H9zM9 10h2v1H9zM9 8h2v1H9zM13 12h2v1h-2zM13 10h2v1h-2zM13 8h2v1h-2z" />
        <path d="M17 4h1v13h-1z" />
      </g>
    </svg>
  );
}

export default SvgCalendarIcon;
