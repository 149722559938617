import React from "react";
import {
  Link as RouterLink,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import {
  Container,
  FormGroup,
  Link,
  Paper,
  Tabs,
  Tab,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
} from "recharts";

import theme from "../../theme";
import { StateCtx } from "../../contexts";
import { ActionTypes } from "../../reducers";
import { lineColors } from "../../config";
import {
  IDiscDevItem,
  IMarket,
  ITarget,
  IModel,
  TargetType,
  IAHBDiscountDev,
} from "../../Ifaces.d";
import { DownloadIcon, SmallInfoIcon } from "../../Icons";
import GraphTooltipContent from "../GraphTooltipContent";
import ChartLegendItem from "../ChartLegendItem";
import DateSelector from "../DateSelector";
import * as API from "../../API";

// --------------------------------------------------------------------
// DiscDevcContent.

const months3letters = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

interface IDiscDevContentProps {
  xCount: number;
  xItems: string[];
  selection: (ITarget | IModel)[];
  data: IDiscDevItem[][];
  enabledElems: Map<number, boolean>;
}

function DiscDevContent(props: IDiscDevContentProps) {
  const { selection, data, xCount, xItems, enabledElems } = props;
  const { dispatch } = React.useContext(StateCtx);

  const { path } = useRouteMatch();

  const chartData = React.useMemo(() => {
    if (
      !selection ||
      !data ||
      selection.length === 0 ||
      selection.length !== data.length ||
      !xItems ||
      xItems.length === 0
    ) {
      return [];
    }

    // Each element of data must be an array of xItems.length.
    if (data[0].length !== xItems.length) {
      return [];
    }

    const _chartData = [];
    for (let m = 0; m < xCount; m++) {
      const obj: any = { month: xItems[m] };
      for (let t = 0; t < selection.length; t++) {
        obj[selection[t].label] = data[t][m].value.toFixed(2);
      }
      _chartData.push(obj);
    }
    return _chartData;
  }, [selection, data, xCount, xItems]);

  React.useEffect(() => {
    dispatch({ type: ActionTypes.SET_DETAILS_PATH, details_path: path });
  }, [path, dispatch]);

  const renderLines = () => {
    return selection.map((item: IMarket | ITarget, index: number) => {
      const enabled = enabledElems.get(index);
      return (
        <Line
          key={`chart-line-${index}`}
          type="linear"
          dataKey={item.label}
          stroke={lineColors[index]}
          strokeDasharray="5 0"
          strokeWidth={2}
          {...(enabled === false && { strokeOpacity: 0.1 })}
        />
      );
    });
  };

  const renderCustomXAxisTick = (props: any) => {
    const { payload, x, y, index } = props;
    const bits = payload.value.split("-");

    let showYear = index === 0 || bits[1] === "01" ? true : false;
    return (
      <text
        className={props.className}
        x={x}
        y={y}
        fill="#666"
        textAnchor="middle"
      >
        <tspan x={x} dy=".6em">
          {months3letters[parseInt(bits[1]) - 1]}
        </tspan>
        {showYear && (
          <tspan x={x} dy="1.5em">
            {bits[0]}
          </tspan>
        )}
      </text>
    );
  };

  if (chartData.length === 0) return <span />;

  return (
    <div style={{ paddingBottom: 24, width: 1129, height: 389 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={1129}
          height={389}
          data={chartData}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid
            strokeDasharray="5 2"
            vertical={false}
            stroke="#ccc"
            strokeWidth="0.5"
          />
          <XAxis
            axisLine={false}
            tickLine={false}
            dataKey="month"
            tickMargin={16}
            padding={{ left: 32, right: 32 }}
            tick={renderCustomXAxisTick}
            height={42}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            ticks={[0, 5, 10, 15, 20]}
            width={42}
            height={317}
            type="number"
            unit="%"
            domain={[0, 5]}
          />
          <ChartTooltip content={<GraphTooltipContent />} />
          {renderLines()}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

// --------------------------------------------------------------------
// Main component: DiscountDevTab.

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: "inline",
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    links: {
      "& > *": {
        marginRight: 24,
        display: "inline-flex",
        alignItems: "center",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
        color: "#454c53",
      },
      "& > *:last-child": {
        marginRight: 0,
      },
    },
    tooltip: {
      width: 240,
      marginTop: 1,
      padding: "10px",
      color: "#000",
      backgroundColor: "#fff",
      borderRadius: 2,
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      fontSize: "15px",
      lineHeight: 1.4,
    },
    arrow: {
      color: "#fff",
    },
    icon: {
      marginLeft: 8,
      display: "inline",
      lineHeight: 1.1,
    },
    secondHeader: {
      marginTop: 19,
      marginBottom: 21,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    legendDiv: {
      height: 25,
    },
    content: {
      width: "100%",
    },

    paper: {
      minHeight: 504,
      marginBottom: 5,
      backgroundColor: "white",
      borderRadius: 8,
      padding: "8px 36px 20px",
    },
    tabsWrapper: {},
    tabsDiv: {
      borderBottom: "1px solid #dcdee1",
      "& .MuiTab-root": {
        minWidth: 24,
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "16px",
        letterSpacing: "0.3px",
        padding: "6px 0",
        borderRadius: 0,
      },
      "& .MuiTab-wrapper": {
        padding: "14px 0",
      },
      "& .MuiTab-textColorInherit.Mui-selected": {
        color: "#1c86ed",
      },
      "& .MuiTabs-indicator": {
        height: 2,
      },
      "& A": {
        marginLeft: 32,
      },
      "& A:first-child": {
        marginLeft: 0,
      },
    },

    chart: {
      "& .recharts-text": {
        fontSize: "12px",
        fontWeight: 600,
      },
      "& .recharts-tooltip-wrapper": {
        fontSize: "12px",
        fontWeight: 600,
      },
      "& .recharts-xAxis": {
        fontSize: "12px",
        fontWeight: 600,
      },
      "& .recharts-default-tooltip": {
        borderRadius: 5,
      },
    },

    chartLegend: {
      paddingTop: 16,
    },
  });

const useStyles = makeStyles(styles);

interface IPath2Tab {
  vid: string;
  label: string;
  entries: number;
  path: string;
  tab: number;
}
const path2tab: IPath2Tab[] = [
  {
    vid: "3m",
    label: "3 Months",
    entries: 3,
    path: "/details/discount-development/3-months",
    tab: 0,
  },
  {
    vid: "6m",
    entries: 6,
    label: "6 Months",
    path: "/details/discount-development/6-months",
    tab: 1,
  },
  {
    vid: "9m",
    entries: 9,
    label: "9 Months",
    path: "/details/discount-development/9-months",
    tab: 2,
  },
  {
    vid: "1y",
    entries: 12,
    label: "1 Year",
    path: "/details/discount-development/1-year",
    tab: 3,
  },
];

export default function DetailsVehicleRatingTab() {
  const classes = useStyles(theme);
  const { state } = React.useContext(StateCtx);
  const [tab, setTab] = React.useState(0);
  const [data, setData] = React.useState<IDiscDevItem[][]>([]);
  const [selection, setSelection] = React.useState<(ITarget | IModel)[]>([]);
  const [enabledElems, setEnabledElems] = React.useState(new Map());

  const tsel = React.useMemo(() => {
    if (state.target === TargetType.brands) return state.brand_selection;
    else return state.model_selection;
  }, [state.target, state.brand_selection, state.model_selection]);

  React.useEffect(() => {
    const elem: IPath2Tab | undefined = path2tab.find((item: IPath2Tab) =>
      state.details_path.startsWith(item.path),
    );
    setTab(elem !== undefined ? elem.tab : 0);
  }, [state.details_path, setTab]);

  React.useEffect(() => {
    setSelection([]); // Reset selection.
  }, [state.target]);

  const getRelativeMonth = (month: number) => {
    const d = new Date(state.date);
    let y = d.getFullYear();
    let m = d.getMonth();
    let new_m = 0;
    if (m + month < 0) {
      new_m = 12 + m + month;
      y -= 1;
    } else if (m + month > 11) {
      new_m = m - month - 12;
      y += 1;
    } else {
      new_m = m + month;
    }
    return new Date(y, new_m);
  };

  const getXItems = () => {
    const xItems: string[] = [];
    const xCount = path2tab[tab].entries; // 3 or 6 or 9 or 12.
    for (let n = xCount; n > 0; n--) {
      const d = getRelativeMonth(-n + 1);
      const _m = d.getMonth() + 1;
      const _m_str = _m < 10 ? `0${_m}` : `${_m}`;
      const _date_str = `${d.getFullYear()}-${_m_str}`;
      xItems.push(_date_str);
    }
    return xItems;
  };

  const fetchDsiscountDev = React.useCallback(
    async (
      ahb_id: string,
      targetType: TargetType,
      end_date: string,
      vid: string,
    ): Promise<IAHBDiscountDev | undefined> => {
      try {
        const disc_dev = await API.fetchDiscountDev(
          ahb_id,
          targetType,
          state.region.code.toUpperCase(),
          end_date,
          vid,
        );
        return disc_dev;
      } catch (error) {
        console.log(error);
      }
    },
    [state.region],
  );

  const getElementData = React.useCallback(
    async (ahb_id: string) => {
      let _m = state.date.getMonth() + 1;
      let end_m = _m < 10 ? `0${_m}` : `${_m}`;
      const end_date = `${state.date.getFullYear()}-${end_m}`;

      const vid = path2tab[tab].vid;

      const discount_dev = await fetchDsiscountDev(
        ahb_id,
        state.target,
        end_date,
        vid,
      );
      if (discount_dev) {
        return discount_dev.values;
      } else {
        return [];
      }
    },
    [state.date, state.target, tab, fetchDsiscountDev],
  );

  React.useEffect(() => {
    if (tsel.length !== selection.length) {
      const _selection = [...selection];

      // New items found in tsel.
      const newSelection = [];
      for (let i = 0; i < tsel.length; i++) {
        const pos = selection.findIndex(
          (item) => item.ahb_id === tsel[i].ahb_id,
        );
        if (pos === -1) {
          newSelection.push(tsel[i]);
        }
      }
      // Delete those items that no longer exist in the tsel.
      const delSelection = [];
      for (let i = 0; i < _selection.length; i++) {
        const pos = tsel.findIndex(
          (item) => item.ahb_id === _selection[i].ahb_id,
        );
        if (pos === -1) {
          delSelection.push(_selection[i]);
        }
      }
      if (delSelection.length) {
        for (let i = 0; i < delSelection.length; i++) {
          _selection.splice(_selection.indexOf(delSelection[i]), 1);
        }
      }
      setSelection([..._selection, ...newSelection]);
    }
  }, [tsel, selection, setSelection]);

  React.useEffect(() => {
    const fetchData = async () => {
      const _data = [];

      for (let i = 0; i < selection.length; i++) {
        const data = await getElementData(selection[i].ahb_id);
        _data.push(data);
      }
      setData(_data);
    };
    fetchData();
  }, [selection, tab, setData, getElementData]);

  const toggleEntry = React.useCallback(
    (index: number) => {
      const newMap = new Map(enabledElems);
      let currValue = newMap.get(index);
      currValue = currValue === undefined ? true : currValue;
      newMap.set(index, !currValue);
      setEnabledElems(newMap);
    },
    [enabledElems],
  );

  return (
    <Container maxWidth="lg">
      <Typography className={classes.header}>
        Discount level at dealerships
        <Tooltip
          arrow
          placement="top"
          title="Discount level: indicator about how much in % the retail price differs from MSRP"
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
        >
          <span className={classes.icon}>
            <SmallInfoIcon />
          </span>
        </Tooltip>
      </Typography>

      <div className={classes.secondHeader}>
        <div className={classes.legendDiv} />
        <div className={classes.links}>
          <DateSelector hidden />
          <Link
            href="#"
            onClick={(ev: React.SyntheticEvent) => ev.preventDefault()}
          >
            <DownloadIcon />
            &nbsp;&nbsp;Download as PDF
          </Link>
        </div>
      </div>

      <div>
        <Paper className={classes.paper}>
          <div className={classes.tabsWrapper}>
            <Tabs
              id="details-sov"
              value={tab}
              onChange={(event, value) => setTab(value)}
              indicatorColor="primary"
              className={classes.tabsDiv}
              centered
            >
              {path2tab.map((item: IPath2Tab, index: number) => (
                <Tab
                  key={`tab-${index}`}
                  component={RouterLink}
                  to={item.path}
                  label={item.label}
                />
              ))}
            </Tabs>

            <div className={classes.chart} style={{ marginTop: 58 }}>
              <Switch>
                {path2tab.map((item: IPath2Tab, index: number) => {
                  return (
                    <Route exact path={item.path} key={`route-${index}`}>
                      <DiscDevContent
                        xCount={item.entries}
                        xItems={getXItems()}
                        selection={selection}
                        data={data}
                        enabledElems={enabledElems}
                      />
                    </Route>
                  );
                })}
                <Route path="/details/discount-development">
                  <DiscDevContent
                    xCount={path2tab[tab].entries}
                    xItems={getXItems()}
                    selection={selection}
                    data={data}
                    enabledElems={enabledElems}
                  />
                </Route>
              </Switch>
            </div>
          </div>
        </Paper>

        <div className={classes.chartLegend}>
          <FormGroup style={{ flexDirection: "row" }}>
            {selection.map((item: IMarket | ITarget, index: number) => (
              <ChartLegendItem
                key={`chart-legend-item-${index}`}
                index={index}
                label={item.label}
                color={lineColors[index]}
                checked={
                  enabledElems.get(index) === undefined
                    ? true
                    : enabledElems.get(index)
                }
                onChange={toggleEntry}
              />
            ))}
          </FormGroup>
        </div>
      </div>
    </Container>
  );
}
