import React from "react";
import clsx from "clsx";
import {
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
  Paper,
  Grid,
  Link,
  Tooltip,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SmallInfoIcon } from "../Icons";
import theme from "../theme";
import { StateCtx } from "../contexts";
import { ScrollToTop } from "../utils";
import { ALLOWED_AUTODATA_KEYS, AUTODATA_KEY, POWERTRAIN } from "../config";
import { createDownloadJob } from "../API";
import { getDownloadURL, analytics } from "../Firebase";

import { BrandsAutocomplete } from "../components/BrandsAutocomplete";
import { ModelsAutocomplete } from "../components/ModelsAutocomplete";

// import FeedbackWidget from "../components/FeedbackWidget";

const styles = (theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
    },
    pageRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    paperRoot: {
      padding: 28,
      flexGrow: 1,
      borderRadius: 8,
      backgroundColor: "#fff",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "transparent",
    },
    mainHeader: {
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "32px",
      textTransform: "uppercase",
      marginTop: "70px",
    },
    secondaryHeader: {
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "1.3rem",
      textTransform: "uppercase",
      marginTop: "40px",
    },
    selectText: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    largeMagnifier: {
      "& .MuiSvgIcon-fontSizeLarge": {
        fontSize: "4rem",
      },
    },
    header: {
      display: "inline",
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    checkboxRoot: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#000",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#000",
      },
    },
    download: {
      marginTop: "40px",
      width: "100%",
      height: "80px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      fontFamily: "Rajdhani",
      fontWeight: 600,
      fontSize: "1.3rem",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: "#FFF",
      cursor: "pointer",
      background: "#2563EB",
      borderRadius: "10px",
      userSelect: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    groupSelectionBtn: {
      cursor: "pointer",
      fontSize: "0.7em",
      fontWeight: 400,
      color: "#888",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    tooltip: {
      width: 240,
      marginTop: 1,
      padding: "10px",
      color: "#000",
      backgroundColor: "#fff",
      borderRadius: 2,
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      fontSize: "15px",
      lineHeight: 1.4,
    },
    arrow: {
      color: "#fff",
    },
    smallIcon: {
      marginLeft: 8,
      display: "inline",
      lineHeight: 1.1,
    },
  });

const useStyles = makeStyles(styles);

interface GroupSelectionProps {
  onSelectAll: () => void;
  onUnselectAll: () => void;
}
const GroupSelectionButtons = (props: GroupSelectionProps) => {
  const classes = useStyles(theme);
  return (
    <div style={{ display: "flex" }}>
      <div className={classes.groupSelectionBtn} onClick={props.onSelectAll}>
        Select all
      </div>
      <div
        className={classes.groupSelectionBtn}
        onClick={props.onUnselectAll}
        style={{ marginLeft: "10px" }}
      >
        Unselect all
      </div>
    </div>
  );
};

const FilterOptions = () => {
  const classes = useStyles(theme);
  return (
    <Paper className={classes.paperRoot} style={{ marginTop: 40 }}>
      <Typography
        className={classes.secondaryHeader}
        style={{ marginTop: "0px" }}
      >
        Filter by brand
      </Typography>
      <GroupSelectionButtons onSelectAll={() => {}} onUnselectAll={() => {}} />
      <div className={classes.content}>
        <BrandsAutocomplete
          placeholder={"Select brands to filter results or leave empty"}
          autodataBrands
        />
      </div>
      <Typography className={classes.secondaryHeader}>
        Filter by model
      </Typography>
      <ModelsAutocomplete />
    </Paper>
  );
};

interface PowertrainSelectionToolProps {
  onPowertrainChange: (powertrainTypes: POWERTRAIN[]) => void;
}

const PowertrainSelectionTool = (props: PowertrainSelectionToolProps) => {
  const POWERTRAIN_CHECKBOXES_INIT_STATE = [
    {
      name: "ICE",
      key: POWERTRAIN.ICE,
      value: true,
      tooltip: "Internal Combustion Engine",
    },
    {
      name: "BEV",
      key: POWERTRAIN.BEV,
      value: true,
      tooltip: "Battery Electric Vehicle",
    },
    {
      name: "PHEV",
      key: POWERTRAIN.PHEV,
      value: true,
      tooltip: "Plug-in Hybrid Electric Vehicle",
    },
    {
      name: "MHEV",
      key: POWERTRAIN.MHEV,
      value: true,
      tooltip: "Mild Hybrid Electric Vehicle",
    },
    {
      name: "FHEV",
      key: POWERTRAIN.FHEV,
      value: true,
      tooltip: "Full Hybrid Electric Vehicle",
    },
    {
      name: "FCEV",
      key: POWERTRAIN.FCEV,
      value: true,
      tooltip: "Fuel Cell Electric Vehicle",
    },
  ];

  const classes = useStyles(theme);
  const [checkboxes, setCheckboxes] = React.useState<
    typeof POWERTRAIN_CHECKBOXES_INIT_STATE
  >(POWERTRAIN_CHECKBOXES_INIT_STATE);

  const handleChange = (checked: boolean, key: POWERTRAIN) => {
    const newCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.key === key) {
        return { ...checkbox, value: checked };
      } else {
        return checkbox;
      }
    });
    setCheckboxes(newCheckboxes);
    props.onPowertrainChange(
      newCheckboxes.filter((c) => c.value).map((c) => c.key),
    );
  };

  return (
    <Grid container spacing={2} style={{ marginTop: "10px" }}>
      {checkboxes.map((item: any, index: number) => (
        <Grid item xs={3} sm={2} key={"grid_item" + index}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkboxRoot}
                disableRipple
                color="default"
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                name={item.key}
                icon={<span className={classes.icon} />}
                inputProps={{ "aria-label": "decorative checkbox" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(event.target.checked, item.key)
                }
                checked={item.value}
              />
            }
            label={
              <div style={{ fontSize: "0.9rem" }}>
                {item.name}
                <Tooltip
                  arrow
                  placement="top"
                  title={item.tooltip}
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow,
                  }}
                >
                  <span className={classes.smallIcon}>
                    <SmallInfoIcon />
                  </span>
                </Tooltip>
              </div>
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default function Dashboard() {
  const classes = useStyles(theme);
  const { state } = React.useContext(StateCtx);
  const [loadingDownloadURL, setLoadingDownloadURL] = React.useState(false);

  const [checkboxes, setCheckboxes] = React.useState<
    typeof ALLOWED_AUTODATA_KEYS
  >(ALLOWED_AUTODATA_KEYS);

  // FILTERS
  const [selectedPowertrainKeys, setSelectedPowertrainKeys] = React.useState<
    Array<POWERTRAIN>
  >([]);

  const handleChange = (checked: boolean, key: AUTODATA_KEY) => {
    const newCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.key === key) {
        return { ...checkbox, value: checked };
      } else {
        return checkbox;
      }
    });
    setCheckboxes(newCheckboxes);
  };

  const handleSelectAll = (group: number, action: "select" | "unselect") => {
    const newCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.group === group) {
        return { ...checkbox, value: action === "select" };
      } else {
        return checkbox;
      }
    });
    setCheckboxes(newCheckboxes);
  };

  const handleDownloadJob = async () => {
    if (loadingDownloadURL) return;
    setLoadingDownloadURL(true);
    try {
      const _selectedAutodataKeys = checkboxes
        .filter((checkbox) => checkbox.value)
        .map((checkbox) => checkbox.key);

      const result = await createDownloadJob(
        state.brand_selection,
        selectedPowertrainKeys,
        _selectedAutodataKeys,
      );
      const downloadURL = await getDownloadURL(result.storage_filepath);
      fetch(downloadURL)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          //  create a computed anchor tag and trigger a computed click action on it
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "car_specs_download.csv";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          //  log event
          const eventData = {
            brand: state.brand_selection.map((brand) => brand.ahb_id),
            powertrain_keys: selectedPowertrainKeys,
            autodata_keys: _selectedAutodataKeys,
          };
          analytics.logEvent("car_specs_download", eventData);
        })
        .catch((e) => {
          //  download failed
          analytics.logEvent("car_specs_download_error", { error: e.toString() });
          setLoadingDownloadURL(false);
        })
        .finally(() => {
          setLoadingDownloadURL(false);
        });
    } catch (error) {
      setLoadingDownloadURL(false);
    }
  };

  const groupSelectionButtons = (group: number) => {
    return (
      <GroupSelectionButtons
        onSelectAll={() => handleSelectAll(group, "select")}
        onUnselectAll={() => handleSelectAll(group, "unselect")}
      />
    );
  };

  const getCheckboxGrid = (group: number) => (
    <Grid container spacing={2} style={{ marginTop: "10px" }}>
      {checkboxes.map((item: any, index: number) => {
        return item.group === group ? (
          <Grid item xs={12} sm={6} md={4} key={"grid_item" + index}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkboxRoot}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  name={item.key}
                  icon={<span className={classes.icon} />}
                  inputProps={{ "aria-label": "decorative checkbox" }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(event.target.checked, item.key)
                  }
                  checked={item.value}
                />
              }
              label={
                <div style={{ fontSize: "0.9rem" }}>
                  {item.name}
                  <span style={{ fontSize: "0.7rem", color: "#777" }}>
                    {item.unit ? ` (${item.unit})` : null}
                  </span>
                </div>
              }
            />
          </Grid>
        ) : null;
      })}
    </Grid>
  );

  return (
    <div className={classes.pageRoot}>
      <ScrollToTop />
      <Container style={{ margin: "auto", padding: "0px 30px" }}>
        
        {/* //////////////////////////////////////////////////////////////// */}
        {/* ////                 BRAND AND MODEL FILTERS                //// */}
        {/* //////////////////////////////////////////////////////////////// */}

        <Typography className={classes.mainHeader}>Car Specs</Typography>
        <FilterOptions />

        {/* //////////////////////////////////////////////////////////////// */}
        {/* ////                     DOWNLOAD BUTTON                    //// */}
        {/* //////////////////////////////////////////////////////////////// */}

        <Link className={classes.download} onClick={handleDownloadJob}>
          {loadingDownloadURL ? "DOWNLOADING..." : "DOWNLOAD"}
        </Link>

        {/* //////////////////////////////////////////////////////////////// */}
        {/* ////                 DOWNLOAD DATA SELECTION                //// */}
        {/* //////////////////////////////////////////////////////////////// */}

        <Paper className={classes.paperRoot} style={{ marginTop: 40 }}>
          <Typography
            className={classes.secondaryHeader}
            style={{ marginTop: "10px" }}
          >
            Filter by powertrain
          </Typography>
          <PowertrainSelectionTool
            onPowertrainChange={setSelectedPowertrainKeys}
          />
          <Typography className={classes.secondaryHeader}>
            General information
          </Typography>
          {groupSelectionButtons(0)}
          {getCheckboxGrid(0)}
          <Typography className={classes.secondaryHeader}>
            Electric cars and hybrids specs
          </Typography>
          {groupSelectionButtons(1)}
          {getCheckboxGrid(1)}
          <Typography className={classes.secondaryHeader}>
            Performance specs
          </Typography>
          {groupSelectionButtons(2)}
          {getCheckboxGrid(2)}
          <Typography className={classes.secondaryHeader}>
            ENGINE SPECS
          </Typography>
          {groupSelectionButtons(3)}
          {getCheckboxGrid(3)}
          <Typography className={classes.secondaryHeader}>
            SPACE, VOLUME, WEIGHT
          </Typography>
          {groupSelectionButtons(4)}
          {getCheckboxGrid(4)}
          <Typography className={classes.secondaryHeader}>
            DIMENSIONS
          </Typography>
          {groupSelectionButtons(5)}
          {getCheckboxGrid(5)}
          <Typography className={classes.secondaryHeader}>
            DRIVETRAIN BRAKES AND SUSPENSION SPECS
          </Typography>
          {groupSelectionButtons(6)}
          {getCheckboxGrid(6)}
        </Paper>
        {/* <FeedbackWidget /> */}
      </Container>
    </div>
  );
}
