import React from "react";
import { LinearProgress } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";

import theme from "../../theme";

const GreenLine = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      width: "100%",
      borderRadius: "3px 0 0 3px",
      marginRight: 2,
    },
    colorPrimary: {
      backgroundColor: "#dcdee1",
    },
    bar: {
      borderRadius: 0,
      backgroundColor: "#70d57d",
    },
  }),
)(LinearProgress);

const GrayLine = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      width: "100%",
      marginRight: 2,
      borderRadius: 0,
    },
    colorPrimary: {
      backgroundColor: "#dcdee1",
    },
    bar: {
      borderRadius: 0,
      backgroundColor: "#b2b7bc",
    },
  }),
)(LinearProgress);

const OrangeLine = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      width: "100%",
      borderRadius: "0 3px 3px 0",
    },
    colorPrimary: {
      backgroundColor: "#dcdee1",
    },
    bar: {
      borderRadius: 0,
      backgroundColor: "#f08766",
    },
  }),
)(LinearProgress);

const LightLine = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      width: "100%",
      borderRadius: 3,
    },
    colorPrimary: {
      backgroundColor: "#dcdee1",
    },
    bar: {
      borderRadius: 3,
      backgroundColor: "#f6f7f8",
    },
  }),
)(LinearProgress);

// --------------------------------------------------------------------
// ColoredLine.

interface IValueLineProps {
  value: number;
  ptype: "positive" | "neutral" | "negative" | "empty";
}

function ValueLine(props: IValueLineProps) {
  const renderLine = () => {
    if (props.ptype === "positive") {
      return (
        <GreenLine
          variant="determinate"
          value={100}
          style={{ width: `${Math.round(props.value)}%` }}
        />
      );
    } else if (props.ptype === "neutral") {
      return (
        <GrayLine
          variant="determinate"
          value={100}
          style={{ width: `${Math.round(props.value)}%` }}
        />
      );
    } else if (props.ptype === "negative") {
      return (
        <OrangeLine
          variant="determinate"
          value={100}
          style={{ width: `${Math.round(props.value)}%` }}
        />
      );
    } else {
      return (
        <LightLine
          variant="determinate"
          value={100}
          style={{ width: "100%" }}
        />
      );
    }
  };

  return renderLine();
}

// --------------------------------------------------------------------

const styles = (theme: Theme) =>
  createStyles({
    numbers: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      "& .span": {
        flexGrow: 1,
        textAlign: "center",
      },
    },
    progressLines: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    tooltipDiv: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      "& > DIV": {
        flexGrow: 1,
      },
      "& UL": {
        paddingInlineStart: 0,
      },
      "& LI": {
        padding: "4px 0",
        listStyleType: "none",
        opacity: "90%",
        color: "black",
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    tooltipHeaders: {
      fontWeight: "normal",
      letterSpacing: "0.5px",
      lineHeight: "16px",
      color: "#1c86ed",
      fontSize: "12px",
      textTransform: "uppercase",
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  ratings: number[];
}

export default function TernaryBar(props: IProps) {
  const classes = useStyles(theme);
  const [positive, neutral, negative] = props.ratings;

  const getFlexGrow = (value: number) => {
    const ret = Math.floor(value / 10);
    if (ret < 1) return 1;
    else return ret;
  };

  if (props.ratings.some((item) => item === -1)) {
    return (
      <React.Fragment>
        <div className={classes.numbers}>
          <span
            style={{
              display: "inline",
              width: "100%",
              textAlign: "left",
              lineHeight: "unset",
              textDecoration: "none",
            }}
          >
            No data available
          </span>
        </div>
        <div className={classes.progressLines}>
          <ValueLine ptype="empty" value={100} />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className={classes.numbers}>
          <span
            style={{
              flexGrow: getFlexGrow(positive),
              textAlign: "left",
            }}
          >
            {Math.round(positive)}%
          </span>
          <span
            style={{
              flexGrow: getFlexGrow(neutral),
              textAlign: "center",
            }}
          >
            {Math.round(neutral)}%
          </span>
          <span
            style={{
              flexGrow: getFlexGrow(negative),
              textAlign: "right",
            }}
          >
            {Math.round(negative)}%
          </span>
        </div>
        <div className={classes.progressLines}>
          <ValueLine ptype="positive" value={props.ratings[0]} />
          <ValueLine ptype="neutral" value={neutral} />
          <ValueLine ptype="negative" value={negative} />
        </div>
      </React.Fragment>
    );
  }
}
