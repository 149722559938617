import axios from "axios";
import React from "react";
import { RequestProvider } from "react-request-hook";

import AppRouter from "./AppRouter";
import { apiUrl, markets } from "./config";
import { IMarket, ITarget, IUserDefaults } from "./Ifaces";
import { ActionTypes } from "./reducers";
import { AuthCtx, StateCtx } from "./contexts";
import LoadingPage from "./pages/LoadingPage";
import NoDefaultsPage from "./pages/NoDefaultsPage";
import StartPage from "./pages/Start";
import { headerConfig, firestore } from "./Firebase";

const axiosInstance = axios.create({ baseURL: apiUrl });

axiosInstance.interceptors.request.use(async function (config) {
  const headers = await headerConfig();
  config.headers = headers["headers"];
  return config;
});

type DefType = IUserDefaults | undefined;

export default function Main() {
  const { user, loading: loadingUser } = React.useContext(AuthCtx);
  const { state, dispatch } = React.useContext(StateCtx);
  const [hasDefs, setHasDefs] = React.useState(true);

  const fetchBrands = async (region: string): Promise<any[]> => {
    const get_query = await firestore.collection(`${region}_BRANDS`).get();
    return get_query.docs.map((d)=> {
      const data = d.data();
      return {
        label: data.label,
        ahb_id: data.ahb_id,
      };
    });
  }

  const fetchAutodataBrands = async (): Promise<any[]> => {
    const get_query = await firestore.collection(`AUTODATA_BRANDS`).get();
    return get_query.docs.map((d)=> {
      const data = d.data();
      return {
        label: data.label,
        ahb_id: data.ahb_id,
      };
    });
  }

  const fetchModels = async (region: string): Promise<any[]> => {
    // TODO: Use the region instead of fetching US_BRANDS.
    const get_query = await firestore.collection(`US_BRANDS`).get();
    return get_query.docs.map((d)=> {
      const data = d.data();
      return {
        label: data.label,
        ahb_id: data.ahb_id,
      };
    });
  }

  React.useEffect(() => {
    const fetch_and_dispatch = async (region: string) => {
      const brands = await fetchBrands(region);
      const autodataBrands = await fetchAutodataBrands();
      const models = await fetchModels(region);
      dispatch({
        type: ActionTypes.RESET_BRANDS_AND_MODELS,
        brands: brands,
        autodataBrands: autodataBrands,
        models: models
      });
    }
    fetch_and_dispatch(state.region.code.toUpperCase());
  }, [state.region, dispatch]);

  React.useEffect(() => {
    const getUserDefaults = async (uid: string): Promise<DefType> => {
      const docRef = firestore.collection('USER_DEFAULTS').doc(uid);
      const doc = await docRef.get();
      if (!doc.exists) {
        return undefined
      } else {
        const obj = doc.data();
        return obj as IUserDefaults;
      }
    }

    const _dispatch_defaults = async (uid: string) => {
      const user_defs = await getUserDefaults(uid);
      if (user_defs !== undefined) {
        const brand = state.brands.find(
          (item: ITarget) => item.ahb_id === user_defs.own_brand
        );
        const region = markets.find(
          (item: IMarket) => item.code === user_defs.region
        );
        if (!brand || !region) {
          setHasDefs(false);
        } else {
          dispatch({
            type: ActionTypes.LOGIN,
            brand: brand,
            region: region,
            alerts: user_defs.alerts || 0
          });
        }
      } else {
        setHasDefs(false);
      }
    }

    if (!loadingUser && user !== null && state.brands.length > 0) {
      _dispatch_defaults(user.uid);
    }
  }, [user, loadingUser, state.brands, dispatch]);

  if (loadingUser)
    return <LoadingPage />;
  else if (user === null)
    return <StartPage />;
  // else if (hasDefs && !state.brand)
  //   return <LoadingPage />;
  else if (!hasDefs)
      return <NoDefaultsPage />;
  else
    return (
      <RequestProvider value={axiosInstance}>
        <AppRouter />
      </RequestProvider>
    );
}
