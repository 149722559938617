import React from "react";
import {
  Container,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import theme from "../theme";
import { AuthCtx } from "../contexts";
import FooterWidget from "../components/FooterWidget";

const styles = (theme: Theme) =>
  createStyles({
    pageRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    mainHeader: {
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "32px",
      textTransform: "uppercase",
      marginTop: "70px",
    },
    secondaryHeader: {
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "1.3rem",
      textTransform: "uppercase",
      marginTop: "40px",
    },
    checkboxRoot: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  });

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles(theme);
  const { user } = React.useContext(AuthCtx);
  //   const { state, dispatch } = React.useContext(StateCtx);

  React.useEffect(() => {}, []);

  return (
    <div className={classes.pageRoot}>
      <Container style={{ margin: "auto", padding: "0px 30px" }}>
        <Typography className={classes.mainHeader}>
          Welcome {user && user.displayName ? user.displayName : ""}
        </Typography>
      </Container>
      <Container
        maxWidth="lg"
        style={{ margin: "auto", padding: "0px 30px", marginTop: "22px" }}
      >
        <FooterWidget />
      </Container>
    </div>
  );
}
