import * as React from "react";

function SvgSmallInfoIcon(props) {
  return (
    <svg width={12} height={12} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#8F979F" cx={6} cy={6} r={6} />
        <path
          d="M6.11 3.48c.193 0 .355-.06.485-.18a.576.576 0 00.195-.44.635.635 0 00-.195-.465.658.658 0 00-.485-.195.598.598 0 00-.46.195.662.662 0 00-.18.465c0 .173.06.32.18.44s.273.18.46.18zm2.13 5.8v-.67h-1.7V4.28H4v.67h1.74v3.66H4v.67h4.24z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgSmallInfoIcon;
