import * as React from "react";

function SvgShareVoiceOnIcon(props) {
  return (
    <svg width={120} height={120} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#000" strokeWidth={2} fillRule="evenodd">
        <path
          d="M95 85a3 3 0 003-3V28a3 3 0 00-3-3H25a3 3 0 00-3 3v54a3 3 0 003 3h11v16l16-16h43z"
          strokeLinejoin="round"
        />
        <g strokeLinecap="round">
          <path d="M44 70V52M52 70V40M60 70V49M68 70V55M76 70V45" />
        </g>
      </g>
    </svg>
  );
}

export default SvgShareVoiceOnIcon;
