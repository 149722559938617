import React from "react";
import { useHistory, useLocation } from "react-router";
import { Drawer, IconButton, Link, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import theme from "../theme";
import { AuthCtx } from "../contexts";
import NavigationItem from "./NavigationItem";
import { storage, auth } from "../Firebase";
import {
  Berylls,
  ReleaseNavLeftArrow,
  RatingStarIcon,
  FAQIcon,
  HomeIcon,
  SpeechBubble,
  SpeechBubbleSoV,
  WrenchIcon,
} from "../Icons";
import defaultAvatar from "../assets/avatar-default.png";

const closedWidth = 72;
const openedWidth = 200;

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: closedWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    content: {
      padding: "114px 8px 0",
    },
    drawerOpen: {
      width: openedWidth,
      maxWidth: openedWidth,
      backgroundColor: "#000",
      color: theme.palette.text.secondary,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    drawerClose: {
      overflowX: "hidden",
      width: closedWidth,
      backgroundColor: "#000",
      color: theme.palette.text.secondary,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    sectionsWrapper: {
      "& > *": {
        marginBottom: 4,
      },
    },
    navTopBtn: {
      color: theme.palette.text.secondary,
      position: "fixed",
      top: "10px",
      left: "169px",
    },
    hide: {
      display: "none",
    },
    userArea: {
      maxWidth: 160,
      marginTop: 70,
      paddingLeft: 16,
      alignItems: "center",
      display: "flex",
      flexDirection: "column"
    },
    emailText: {
      fontFamily: "Open Sans",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
    },
    logoutText: {
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      color: "#ffffff",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      }
    },
    navLogo: {
      color: theme.palette.text.secondary,
      position: "fixed",
      bottom: "12px",
      left: "12px",
    },
    logoVertical: {
      left: 4,
      bottom: 55,
      transform: "rotate(270deg)",
    },
    logoHorizontal: {
      paddingLeft: 52,
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  open?: boolean;
  onClose?: (event: React.KeyboardEvent | React.MouseEvent) => void;
  handleLogout?: () => void;
}

export default function NavigationBar(props: IProps) {
  const classes = useStyles(theme);

  const { user } = React.useContext(AuthCtx);
  // const { state } = React.useContext(StateCtx);

  const [imageURL, setImageURL] = React.useState("");
  const [keepOpen, setKeepOpen] = React.useState(false);
  const [open, setOpen] = React.useState<boolean>(false);

  const { t: translate } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  // TODO: Reactivate ToogleNav
  // const toggleNav = (open: boolean) => (
  //   event: React.KeyboardEvent | React.MouseEvent
  // ) => {
  //   if (
  //     event.type === "keydown" &&
  //     ((event as React.KeyboardEvent).key === "Tab" ||
  //       (event as React.KeyboardEvent).key === "Shift")
  //   ) {
  //     return;
  //   }

  //   if (!open) {
  //     const elem = event.target as HTMLElement;
  //     if (elem.hasAttribute("name")) {
  //       const name = elem.getAttribute("name");
  //       if (name === "country" || name === "language") return;
  //     }
  //   }
  //   setOpenNav(open);
  // };

  function handleOnMouseEnter() {
    if (open === false) {
      setOpen(true);
    }
  }

  function handleOnMouseLeave() {
    if (open === true) {
      setOpen(false);
    }
  }

  function onClose() {
    setKeepOpen(false);
    setOpen(false);
  }

  function handleLogout() {
    if (props.handleLogout) {
      props.handleLogout();
      history.push("/")
    }
  }

  // Initialize as open if possible
  React.useEffect(() => {
    if (props.open) {
      setOpen(true);
    }
  }, [props.open]);

  const getImageURL = (uid: string) => {
    try {
      storage
        .child(`user_images/${uid}/${uid}.jpg`)
        .getDownloadURL()
        .then((url) => {
          setImageURL(url);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      return "";
    }
  };

  React.useEffect(() => {
    if (auth.currentUser) {
      getImageURL(auth.currentUser.uid);
    }
  }, []);

  return (
    <Drawer
      key="theDrawer"
      open={open || keepOpen}
      onClose={onClose}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: keepOpen,
        [classes.drawerClose]: !keepOpen,
      })}
      variant="permanent"
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open || keepOpen,
          [classes.drawerClose]: !open && !keepOpen,
        }),
      }}
      anchor="left"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {/* <IconButton
        size="small"
        color="inherit"
        arial-label="open drawer"
        className={clsx(classes.navTopBtn, {
          [classes.hide]: !open || keepOpen,
        })}
        onClick={() => setKeepOpen(true)}
      >
        <FixNavRightArrow />
      </IconButton> */}

      <IconButton
        size="small"
        color="inherit"
        arial-label="open drawer"
        className={clsx(classes.navTopBtn, {
          [classes.hide]: !keepOpen,
        })}
        onClick={onClose}
      >
        <ReleaseNavLeftArrow />
      </IconButton>

      <div className={classes.content}>
        <div className={classes.sectionsWrapper}>
          <NavigationItem
            for="cockpit"
            label={translate("Home")}
            active={location.pathname === "/"}
            showFull={open || keepOpen}
            icon={<HomeIcon width={27} height={27} />}
            onClick={() => history.push("/")}
          />
          <NavigationItem
            for="car-specs"
            label={translate("Car Specs")}
            active={location.pathname.startsWith("/car-specs")}
            showFull={open || keepOpen}
            icon={<WrenchIcon width={27} height={27} />}
            onClick={() => history.push("/car-specs")}
          />
          <NavigationItem
            for="vehicle-rating"
            label={translate("vehicle ratings")}
            active={location.pathname.startsWith("/vehicle-rating")}
            showFull={open || keepOpen}
            icon={<RatingStarIcon width={50} height={50} />}
            onClick={() => history.push("/vehicle-rating")}
            // badgeContent={"SOON"}
          />
          <NavigationItem
            for="ratings"
            label={translate("user discussions")}
            active={location.pathname.startsWith("/user-discussions")}
            showFull={open || keepOpen}
            icon={<SpeechBubble width={27} height={27} />}
            onClick={() => history.push("/user-discussions")}
            // badgeContent={"SOON"}
          />
          <NavigationItem
            for="share-of-voice"
            label={translate("Share of voice")}
            active={location.pathname.startsWith("/share-of-voice")}
            showFull={open || keepOpen}
            icon={<SpeechBubbleSoV width={27} height={27} />}
            onClick={() => history.push("/share-of-voice")}
            // badgeContent={"SOON"}
          />
          <NavigationItem
            for="faq"
            label={translate("faq")}
            active={location.pathname.startsWith("/faq")}
            showFull={open || keepOpen}
            icon={<FAQIcon width={27} height={27}  />}
            onClick={() => history.push("/faq")}
          />

          {/* <NavigationItem
            for="details"
            label={translate("details")}
            active={location.pathname.startsWith("/details")}
            showFull={open || keepOpen}
            icon={<NavChart />}
            onClick={() => history.push(state.details_path)}
          />
          <NavigationItem
            for="measures"
            label={translate("measures")}
            active={location.pathname.startsWith("/measures")}
            showFull={open || keepOpen}
            icon={<NavMeasures />}
            onClick={() => history.push("/measures")}
          />
          <NavigationItem
            for="caution"
            label={translate("caution")}
            active={location.pathname.startsWith("/caution")}
            showFull={open || keepOpen}
            icon={<NavCaution />}
            onClick={() => history.push("/caution")}
            {...(state.alerts !== 0 && { badgeContent: state.alerts })}
          /> */}

          {(open || keepOpen) && (
            <div className={classes.userArea}>
              <div
                style={{
                  marginLeft: 5,
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  height="40"
                  width="40"
                  style={{ borderRadius: "48px", marginBottom: "20px"}}
                  src={imageURL || defaultAvatar}
                  alt={"Profile"}
                />
                {/* <Link component={RouterLink} to="/">
          <AutomotiveHeartbeat width="119" />
        </Link> */}
              </div>
              <Typography className={classes.emailText} noWrap>
                {user && user.displayName !== undefined
                  ? user.displayName
                  : null}
              </Typography>
              <Link className={classes.logoutText} onClick={handleLogout}>
                Logout
              </Link>
            </div>
          )}
        </div>
      </div>

      <Link href="https://www.berylls.com/en/" target="_new">
        <span
          className={clsx(classes.navLogo, {
            [classes.logoVertical]: !open && !keepOpen,
            [classes.logoHorizontal]: open || keepOpen,
          })}
        >
          <Berylls />
        </span>
      </Link>
    </Drawer>
  );
}
