import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { FormControl, FormControlLabel, RadioGroup } from "@material-ui/core";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import clsx from "clsx";

import theme from "../theme";
import { TargetType } from "../Ifaces.d";

// --------------------------------------------------------------------
// Our styled Radio button.

const radioStyles = makeStyles({
  root: {
    padding: 8,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    marginTop: -2,
    borderRadius: "50%",
    width: 12,
    height: 12,
    border: "1px solid #1c86ed",
    backgroundColor: "#ffffff",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19, 124, 189, .6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206, 217, 224, .5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#ffffff",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 9,
      left: 11,
      width: 6,
      height: 6,
      display: "block",
      backgroundColor: "#1c86ed",
      borderRadius: "50%",
    },
  },
});

function StyledRadio(props: RadioProps) {
  const classes = radioStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

// --------------------------------------------------------------------
// Styles and ModelsBrandsRadioWidget.

const styles = (theme: Theme) =>
  createStyles({
    rootFormControlWithPadding: {
      display: "inline",
      paddingTop: "7px"
    },
    rootFormControl: {
      display: "inline",
    },
    rootFormGroup: {
      flexDirection: "row",
    },
    label: {
      marginTop: 1,
      marginLeft: 0,
      marginRight: 20,
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "24px",
    },
    choice: {
      padding: 0,
      alignItems: "flex-start",
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  addPadding?: boolean
  value: TargetType;
  onChange: (value: TargetType) => void;
}

export default function TopRadioWidget(props: IProps) {
  const classes = useStyles(theme);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as TargetType;
    props.onChange(value);
  };

  return (
    <FormControl
      component="fieldset"
      classes={{
        root: props.addPadding ? classes.rootFormControlWithPadding : classes.rootFormControl,
      }}
    >
      <RadioGroup
        name="models-or-brands"
        value={props.value}
        onChange={handleChange}
        classes={{
          root: classes.rootFormGroup,
        }}
      >
        <FormControlLabel
          key="brands"
          value={TargetType.brands}
          control={<StyledRadio />}
          label="Brands"
          classes={{
            root: classes.choice,
            label: classes.label,
          }}
        />

        <FormControlLabel
          key="models"
          value={TargetType.models}
          control={<StyledRadio />}
          label="Models"
          classes={{
            root: classes.choice,
            label: classes.label,
          }}
        />
      </RadioGroup>
    </FormControl>
  );
}
