import React from "react";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import theme from "../../theme";

const styles = (theme: Theme) =>
  createStyles({
    tooltipDiv: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      "& > DIV": {
        flexGrow: 1,
      },
      "& UL": {
        padding: "0 16px",
        paddingInlineStart: 0,
      },
      "& LI": {
        padding: "4px 0",
        listStyleType: "none",
        opacity: "90%",
        color: "black",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    tooltipHeaders: {
      letterSpacing: "0.5px",
      lineHeight: "16px",
      color: "#1c86ed",
      fontWeight: 600,
      fontSize: "12px",
      textTransform: "uppercase",
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  positive: number;
  neutral: number;
  negative: number;
}

export default function LongTooltipTitle(props: IProps) {
  const classes = useStyles(theme);
  const { positive, neutral, negative } = props;

  return (
    <div className={classes.tooltipDiv}>
      <div>
        <Typography className={classes.tooltipHeaders}>
          {Math.round(positive)}% positive statements
        </Typography>
        <ul>
          <li>
            <q>The exterior is very tech.</q>
          </li>
          <li>
            <q>I like the young exterior.</q>
          </li>
          <li>
            <q>The color is nice.</q>
          </li>
          <li>
            <q>Nice exterior.</q>
          </li>
        </ul>
      </div>

      <div>
        <Typography className={classes.tooltipHeaders}>
          {Math.round(neutral)}% neutral statements
        </Typography>
        <ul>
          <li>
            <q>Does the job.</q>
          </li>
          <li>
            <q>Nothing to complain about.</q>
          </li>
          <li>
            <q>Quite okay.</q>
          </li>
          <li>
            <q>What I expected.</q>
          </li>
        </ul>
      </div>

      <div>
        <Typography className={classes.tooltipHeaders}>
          {Math.round(negative)}% negative statements
        </Typography>
        <ul>
          <li>
            <q>The exterior is very old.</q>
          </li>
          <li>
            <q>I don't like the yound exterior.</q>
          </li>
          <li>
            <q>The color is not nice.</q>
          </li>
          <li>
            <q>Not nice exterior.</q>
          </li>
        </ul>
      </div>
    </div>
  );
}
