import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { FormControl, InputLabel } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import theme from "../theme";

const checkboxStyles = makeStyles({
  root: {
    padding: 8,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiIconButton-label": {
      border: "1px solid #b2b7bc",
      borderRadius: 2,
    },
  },
  icon: {
    width: 20,
    height: 20,
    backgroundColor: "#f5f8fa",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#f6f7f8",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#f6f7f8",
    "&:before": {
      display: "block",
      position: "relative",
      left: 1,
      top: 1,
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23000'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#f6f7f8",
    },
  },
});

function StyledCheckbox(props: CheckboxProps) {
  const classes = checkboxStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

// --------------------------------------------------------------------

const styles = (theme: Theme) =>
  createStyles({
    label: {
      display: "inline-flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: 2,
      fontFamily: "Open Sans",
      fontStyle: "italic",
      fontSize: "12px",
      fontWeight: "normal",
      lineHeight: "20px",
      color: "#000",
    },
    choice: {
      padding: 0,
      alignItems: "flex-start",
    },
    colorLine: {
      width: 24,
      height: 6,
      paddingRight: 8,
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  index: number;
  label: string;
  color: string;
  checked: boolean;
  onChange: (index: number) => void;
}

export default function ChartLegendItem(props: IProps) {
  const classes = useStyles(theme);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingRight: 16,
      }}
    >
      <FormControl>
        <StyledCheckbox
          id={`chart-legend-item-${props.index}`}
          value={props.index}
          checked={props.checked}
          onChange={() => props.onChange(props.index)}
        />
      </FormControl>
      <InputLabel
        htmlFor={`chart-legend-item-${props.index}`}
        className={classes.label}
      >
        <span
          className={classes.colorLine}
          style={{ backgroundColor: props.color }}
        />
        <span style={{ paddingLeft: 8 }}>{props.label}</span>
      </InputLabel>
    </div>
  );
}
