import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import {
  FormControl,
  Icon,
  Select,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";

import theme from "../theme";
import { markets } from "../config";
import { IMarket } from "../Ifaces";

import ArrowDownIcon from "../Icons/ArrowDownIcon";
import ArrowUpIcon from "../Icons/ArrowUpIcon";
import { firestore } from "../Firebase";

const styles = (theme: Theme) =>
  createStyles({
    select: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    selectIcon: {
      width: 12,
      height: 14,
      cursor: "pointer",
    },
    iconFlex: {
      display: "flex",
    },
    iconRoot: {
      minWidth: 31,
    },
    label: {
      fontFamily: "Open Sans",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "24px",
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  market: IMarket;
  onChange: (market: IMarket) => void;
}

export default function MarketWidget(props: IProps) {
  const classes = useStyles(theme);
  const [open, setOpen] = React.useState<boolean>(false);
  const [availableMarkets, setAvailableMarkets] = React.useState<IMarket[]>([]);

  React.useEffect(() => {
    const fetchRegions = async (): Promise<string[] | undefined> => {
      const docRef = firestore.collection('CONFIG').doc('SETTINGS');
      const doc = await docRef.get();
      if (!doc.exists) {
        return undefined;
      } else {
        const obj = doc.data();
        return (obj as any).regions as string[];
      }
    }

    const _initialize_availableMarkets = async () => {
      const regions = await fetchRegions();
      if (regions !== undefined) {
        const _markets = markets.filter(
          (item: IMarket) => regions.indexOf(item.code) !== -1
        );
        setAvailableMarkets(_markets);
      }
    }

    _initialize_availableMarkets();
  }, []);

  const flag = React.useMemo(() => {
    const idx = markets.findIndex((item) => item.code === props.market.code);
    return markets[idx].src;
  }, [props.market]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (markets.length > 1) {
      const src = event.target.value as string;
      const idx = markets.findIndex((item) => item.src === src);
      props.onChange(markets[idx]);
    }
  };

  const renderSelectIcon = () => {
    if (markets.length === 1) return <React.Fragment></React.Fragment>;

    let icon = open ? <ArrowUpIcon /> : <ArrowDownIcon />;
    return (
      <span onClick={() => setOpen(!open)} className={classes.selectIcon}>
        <Icon classes={{ root: classes.iconFlex }}>{icon}</Icon>
      </span>
    );
  };

  return (
    <FormControl>
      <Select
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        name="flag"
        value={flag}
        onChange={handleChange}
        IconComponent={() => renderSelectIcon()}
        classes={{
          select: classes.select,
        }}
      >
        {availableMarkets.map((option, key) => (
          <MenuItem value={option.src} key={key}>
            <ListItemIcon
              classes={{
                root: classes.iconRoot,
              }}
            >
              <img width={24} src={option.src} alt={option.label} />
            </ListItemIcon>
            <span className={classes.label}>{option.label}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
