import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { IPeTable, OrderType } from "./Ifaces.d";

export function splitInLines(title: string) {
  const res = title.replaceAll(" ", "<br/>");
  return { __html: res };
}

export function createPeData(
  place: number,
  brand: string,
  current_value: number,
  past_value: number,
  average_value: number,
): IPeTable {
  return { place, brand, current_value, past_value, average_value };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: OrderType,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function ScrollToTop() {
  const [currPath, setCurrPath] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    const currPath_parts = currPath.split("/");
    const pathname_parts = pathname.split("/");
    if (
      currPath_parts.length > 1 &&
      pathname_parts.length > 1 &&
      currPath_parts[1] !== pathname_parts[1]
    ) {
      window.scrollTo(0, 0);
      setCurrPath(pathname);
    } else if (currPath === "") {
      window.scrollTo(0, 0);
      setCurrPath(pathname);
    }
  }, [pathname, currPath]);

  return null;
}

export function getMaxDate() {
  const mDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const date = new Date();
  const month = date.getMonth() === 0 ? 11 : date.getMonth() - 1;
  const year = month === 0 ? date.getFullYear() - 1 : date.getFullYear();
  const day = mDays[month];
  return new Date(year, month, day);
}
