import React from "react";
import { Link } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import theme from "../theme";
import { getMaxDate } from "../utils";
import { StateCtx } from "../contexts";
import { ActionTypes } from "../reducers";
import { CalendarIcon } from "../Icons";

const styles = (theme: Theme) =>
  createStyles({
    dateSelector: {
      width: 90,
      display: "inline-flex",
      justifyContent: "flex-start",
      alignItems: "center",

      "& .MuiFormControl-root": {
        display: "inline-flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },

      "& .MuiInputBase-root": {
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
        color: "#454c53",
      },
      "& .MuiInputBase-input": {
        height: "unset",
        padding: "unset",
        display: "inline",
        cursor: "pointer",
      },
      "& .MuiInput-underline": {
        "&::before": {
          content: '""',
        },
        "&::after": {
          content: '""',
        },
      },
    },
    link: {
      height: 20,
      minWidth: 20,
      marginRight: 8,
    },
  });

const useStyles = makeStyles(styles);

export default function DateSelector({hidden=false}: {hidden?: boolean}) {
  const classes = useStyles(theme);
  const [open, setOpen] = React.useState(false);
  const { state, dispatch } = React.useContext(StateCtx);
  const [value, setValue] = React.useState<Date | null>(state.date);

  React.useEffect(() => {
    if (value && value !== state.date) {
      dispatch({ type: ActionTypes.SET_DATE, date: value });
    }
  }, [value, state.date, dispatch]);

  const handleClick = (ev: React.SyntheticEvent) => {
    setOpen(true);
  };

  return (
    hidden ? null :
      <div className={classes.dateSelector}>
        <Link href="#" onClick={handleClick} className={classes.link}>
          <CalendarIcon />
        </Link>
        <DatePicker
          disableFuture
          variant="inline"
          views={["year", "month"]}
          format="MMM yyyy"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={value}
          onChange={(newValue) => setValue(newValue)}
          onMonthChange={() => setOpen(false)}
          maxDate={getMaxDate()}
        />
      </div>
  );
}
