import * as React from "react";

function SvgArrowUpIcon(props) {
  return (
    <svg width={12} height={12} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 8L6 4 2 8"
        stroke="#000"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgArrowUpIcon;
