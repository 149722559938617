import React from "react";
import {
  Link as RouterLink,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import {
  Container,
  LinearProgress,
  Link,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import clsx from "clsx";

import theme from "../../theme";
import { StateCtx } from "../../contexts";
import { ActionTypes } from "../../reducers";
import { IAHBSoV, ITarget, IModel, ISoVItem, TargetType } from "../../Ifaces.d";
import { DownloadIcon, SmallInfoIcon } from "../../Icons";
import DateSelector from "../DateSelector";
import TernaryBar from "./TernaryBar";
import LongTooltipContent from "./LongTooltipContent";
import SoVPopover from "./SoVPopover";
import * as API from "../../API";

const BlueLine = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 3,
    },
    colorPrimary: {
      backgroundColor: "#dcdee1",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1c86ed",
    },
  }),
)(LinearProgress);

const EmptyLine = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 3,
    },
    colorPrimary: {
      backgroundColor: "#dcdee1",
    },
    bar: {
      borderRadius: 3,
      backgroundColor: "#f6f7f8",
    },
  }),
)(LinearProgress);

// --------------------------------------------------------------------
// OverviewContent.

interface ISoVContentProps {
  classes: ReturnType<typeof useStyles>;
  selection: (ITarget | IModel)[];
  data: ISoVItem[];
}

function SoVContent(props: ISoVContentProps) {
  const { classes } = props;
  const { dispatch } = React.useContext(StateCtx);

  const { path } = useRouteMatch();

  React.useEffect(() => {
    dispatch({ type: ActionTypes.SET_DETAILS_PATH, details_path: path });
  }, [path, dispatch]);

  const getTitle = ([positive, neutral, negative]: number[]) => (
    <LongTooltipContent
      positive={positive}
      neutral={neutral}
      negative={negative}
    />
  );

  if (props.selection.length !== props.data.length) return <span />;

  return (
    <div className={classes.content}>
      {props.selection.map((item: ITarget | IModel, idx: number) => (
        <div
          key={`chart-card-${item.ahb_id}`}
          style={{ margin: "24px 0 25px" }}
        >
          <Typography className={clsx("title")}>{item.label}</Typography>
          {props.data[idx].value === -1 ? (
            <React.Fragment>
              <Typography className={clsx("text")}>
                No data available
              </Typography>
              <EmptyLine variant="determinate" value={100} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography className={clsx("text")}>
                {props.data[idx].value.toFixed(1)}
              </Typography>
              <BlueLine variant="determinate" value={props.data[idx].value} />
            </React.Fragment>
          )}
        </div>
      ))}
      <div style={{ margin: "27px 0 30px" }}>
        <Typography className={clsx("text")}>
          <span className={clsx("bold")}>In total:</span>&nbsp;
          {Math.round(Math.random() * 1000)} mentions
        </Typography>
      </div>
      <div className={clsx("divider")} />
      <Typography style={{ margin: "31px 0 21px" }} className={clsx("header")}>
        Are the mentions positive, negative or neutral?
      </Typography>
      <div className={clsx("gridPanel")}>
        {props.selection.map((item: ITarget | IModel, idx: number) => (
          <div key={`card-${item.ahb_id}`}>
            {props.data[idx].value !== -1 ? (
              <SoVPopover
                item={item}
                content={getTitle(props.data[idx].ratings)}
              />
            ) : (
              <Typography className={clsx("title")}>{item.label}</Typography>
            )}
            <TernaryBar ratings={props.data[idx].ratings} />
          </div>
        ))}
      </div>
    </div>
  );
}

// --------------------------------------------------------------------
// ShareOfVoiceTab, main component.

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: "block",
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
    },
    secondHeader: {
      marginTop: 19,
      marginBottom: 21,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    legendDiv: {
      height: 25,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "& .text": {
        fontFamily: "Open Sans",
        fontStyle: "italic",
        fontSize: "12px",
      },
      "& .positive": {
        width: 20,
        height: 6,
        backgroundColor: "#70d57d",
        marginRight: 8,
      },
      "& .neutral": {
        marginLeft: 20,
        width: 20,
        height: 6,
        backgroundColor: "#b2b7bc",
        marginRight: 8,
      },
      "& .negative": {
        marginLeft: 20,
        width: 20,
        height: 6,
        backgroundColor: "#ee754f",
        marginRight: 8,
      },
    },
    links: {
      "& > *": {
        marginRight: 24,
        display: "inline-flex",
        alignItems: "center",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
        color: "#454c53",
      },
      "& > *:last-child": {
        marginRight: 0,
      },
    },
    tooltip: {
      width: 240,
      marginTop: 1,
      padding: "10px",
      color: "#000",
      backgroundColor: "#fff",
      borderRadius: 2,
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      fontSize: "15px",
      lineHeight: 1.4,
    },
    arrow: {
      color: "#fff",
    },
    icon: {
      marginLeft: 8,
      display: "inline",
      lineHeight: 1.1,
    },

    paper: {
      marginBottom: 5,
      backgroundColor: "white",
      borderRadius: 8,
      padding: "8px 36px 20px",
    },
    tabsWrapper: {
      marginBottom: 32,
    },
    tabsDiv: {
      borderBottom: "1px solid #dcdee1",
      "& .MuiTab-root": {
        minWidth: 24,
        // maxWidth: 155,
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "16px",
        letterSpacing: "0.3px",
        padding: "6px 0",
        borderRadius: 0,
      },
      "& .MuiTab-wrapper": {
        padding: "14px 0",
      },
      "& .MuiTab-textColorInherit.Mui-selected": {
        color: "#1c86ed",
      },
      "& .MuiTabs-indicator": {
        height: 2,
      },
      "& A": {
        marginLeft: 32,
      },
      "& A:first-child": {
        marginLeft: 0,
      },
    },
    content: {
      "& .MuiTypography-body1": {
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontWeight: 400,
      },
      "& .header": {
        fontFamily: "Rajdhani",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "0.5px",
        textTransform: "uppercase",
      },
      "& .title": {
        fontSize: "12px",
        display: "inline",
        fontWeight: 700,
        lineHeight: "16px",
      },
      "& .text": {
        lineHeight: "20px",
        letterSpacing: "0.3px",
      },
      "& .bold": {
        fontWeight: 700,
      },
      "& .underline": {
        textDecoration: "underline",
      },
      "& .pointer": {
        cursor: "pointer",
      },
      "& .MuiLinearProgress-root": {
        margin: "4px 0",
      },
      "& .divider": {
        height: 1,
        width: "100%",
        backgroundColor: "#dcdee1",
      },
      "& .gridPanel": {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "328px 328px 328px",
        gridGap: "26px 60px",
        padding: 0,
        paddingBottom: 16,
      },
    },
  });

const useStyles = makeStyles(styles);

interface IPath2Tab {
  label: string;
  path: string;
  topic: string;
  tab: number;
}
const path2tab: IPath2Tab[] = [
  {
    label: "Connectivity",
    path: "/details/share-of-voice/connectivity",
    topic: "con",
    tab: 0,
  },
  {
    label: "Autonomous Driving",
    path: "/details/share-of-voice/autonomous-driving",
    topic: "ad",
    tab: 1,
  },
  {
    label: "Topic 3",
    path: "/details/share-of-voice/topic-3",
    topic: "t3",
    tab: 2,
  },
  {
    label: "Topic 4",
    path: "/details/share-of-voice/topic-4",
    topic: "t4",
    tab: 3,
  },
  {
    label: "Topic 5",
    path: "/details/share-of-voice/topic-5",
    topic: "t5",
    tab: 4,
  },
];

export default function ShareOfVoiceTab() {
  const classes = useStyles(theme);
  const { state } = React.useContext(StateCtx);
  const [tab, setTab] = React.useState(0);
  const [topic, setTopic] = React.useState("con");
  const [data, setData] = React.useState<ISoVItem[]>([]);
  const [selection, setSelection] = React.useState<(ITarget | IModel)[]>([]);

  const tsel = React.useMemo(() => {
    if (state.target === TargetType.brands) return state.brand_selection;
    else return state.model_selection;
  }, [state.target, state.brand_selection, state.model_selection]);

  React.useEffect(() => {
    const elem: IPath2Tab | undefined = path2tab.find((item: IPath2Tab) =>
      state.details_path.startsWith(item.path),
    );
    setTab(elem !== undefined ? elem.tab : 0);
    setTopic(elem !== undefined ? elem.topic : "con");
  }, [state.details_path, setTab, setTopic]);

  React.useEffect(() => {
    setSelection([]); // Reset selection.
  }, [state.target]);

  const fetchSoV = React.useCallback(
    async (
      ahb_id: string,
      targetType: TargetType,
    ): Promise<IAHBSoV | undefined> => {
      try {
        const sov = await API.fetchSoV(
          ahb_id,
          topic,
          targetType,
          state.region.code.toUpperCase(),
        );
        return sov;
      } catch (error) {
        console.log(error);
      }
    },
    [state.region, topic],
  );

  const getElementData = React.useCallback(
    async (ahb_id: string) => {
      const ahb_sov = await fetchSoV(ahb_id, state.target);
      if (ahb_sov) {
        return ahb_sov.topic;
      } else {
        return {
          topic_id: topic,
          value: -1,
          ratings: [-1, -1, -1],
        };
      }
    },
    [fetchSoV, state.target, topic],
  );

  React.useEffect(() => {
    if (tsel.length !== selection.length) {
      const _selection = [...selection];

      // New items found in state.selection.
      const newSelection = [];
      for (let i = 0; i < tsel.length; i++) {
        const pos = selection.findIndex(
          (item) => item.ahb_id === tsel[i].ahb_id,
        );
        if (pos === -1) {
          newSelection.push(tsel[i]);
        }
      }
      // Delete those items that no longer exist in the tsel.
      const delSelection = [];
      for (let i = 0; i < _selection.length; i++) {
        const pos = tsel.findIndex(
          (item) => item.ahb_id === _selection[i].ahb_id,
        );
        if (pos === -1) {
          delSelection.push(_selection[i]);
        }
      }
      if (delSelection.length) {
        for (let i = 0; i < delSelection.length; i++) {
          _selection.splice(_selection.indexOf(delSelection[i]), 1);
        }
      }
      setSelection([..._selection, ...newSelection]);
    }
  }, [tsel, selection, setSelection]);

  React.useEffect(() => {
    const fetchData = async () => {
      const _data: ISoVItem[] = [];

      for (let i = 0; i < selection.length; i++) {
        const sov = await getElementData(selection[i].ahb_id);
        _data.push(sov);
      }
      setData(_data);
    };
    fetchData();
  }, [selection, setData, getElementData]);

  return (
    <Container maxWidth="lg">
      <Typography className={classes.header}>
        How many consumers talk about a brand when talking about ...
        <Tooltip
          arrow
          placement="top"
          title="This is what shows up when I hover the small info icon"
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
        >
          <span className={classes.icon}>
            <SmallInfoIcon />
          </span>
        </Tooltip>
      </Typography>

      <div className={classes.secondHeader}>
        <div className={classes.legendDiv}>
          <span className={clsx("positive")} />
          <Typography className={clsx("text")}>Positive</Typography>
          <span className={clsx("neutral")} />
          <Typography className={clsx("text")}>Neutral</Typography>
          <span className={clsx("negative")} />
          <Typography className={clsx("text")}>Negative</Typography>
        </div>
        {/* <div className={classes.links}>
          <DateSelector hidden />
          <Link
            href="#"
            onClick={(ev: React.SyntheticEvent) => ev.preventDefault()}
          >
            <DownloadIcon />
            &nbsp;&nbsp;Download as PDF
          </Link>
        </div> */}
      </div>

      <div style={{ minHeight: 453 }}>
        <Paper className={classes.paper}>
          <div className={classes.tabsWrapper}>
            <Tabs
              id="details-sov"
              value={tab}
              onChange={(event, value) => setTab(value)}
              indicatorColor="primary"
              className={classes.tabsDiv}
              centered
            >
              {path2tab.map((item: IPath2Tab, index: number) => (
                <Tab
                  key={`tab-${index}`}
                  component={RouterLink}
                  to={item.path}
                  label={item.label}
                />
              ))}
            </Tabs>

            <div style={{ marginTop: 58 }}>
              <Switch>
                {path2tab.map((item: IPath2Tab, index: number) => {
                  return (
                    <Route exact path={item.path} key={`route-${index}`}>
                      <SoVContent
                        classes={classes}
                        selection={selection}
                        data={data}
                      />
                    </Route>
                  );
                })}
                <Route path="/details/share-of-voice">
                  <SoVContent
                    classes={classes}
                    selection={selection}
                    data={data}
                  />
                </Route>
              </Switch>
            </div>
          </div>
        </Paper>
      </div>
    </Container>
  );
}
