import * as React from "react";

function SvgArrowDownIcon(props) {
  return (
    <svg width={12} height={12} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2 4l4 4 4-4"
        stroke="#000"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgArrowDownIcon;
