import * as React from "react";

function WrenchIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}
			version="1.1" x="0px" y="0px" viewBox="0 0 1024 1024" transform="scale(0.95)" fill={props.fill ? props.fill : "#FFF"} {...props}>
			<path fill={props.fill ? props.fill : "#FFF"}  d="M788,952.3c-19.5,0-38.9-7.4-53.8-22.2L423.6,619.4c-43.5,11.9-89.3,13-133.6,3.3  c-52-11.4-99.4-37.4-137.2-75.2c-37.8-37.8-63.8-85.2-75.2-137.1c-11.1-50.5-8-103,8.9-151.8l15.7-45.2l144.6,144.8l96.9-14.5  l14.5-96.9L213.3,102.2l45.2-15.7c48.8-17,101.3-20.1,151.8-8.9c51.9,11.4,99.3,37.4,137.1,75.2c37.8,37.8,63.8,85.3,75.2,137.2  c9.7,44.3,8.6,90.1-3.3,133.6L930,734.2c29.6,29.6,29.6,77.9,0,107.5L841.8,930C826.9,944.9,807.5,952.3,788,952.3z M440,550.9  l336.7,336.7c6.2,6.2,16.4,6.2,22.7,0l88.3-88.3c6.2-6.2,6.2-16.4,0-22.7L550.9,440l6.2-17.8c27.4-78.9,7-168-52-227  c-47.6-47.6-112.3-70-177-63.2l93.9,93.7l-25.5,170.7l-170.7,25.5L132,328.1c-6.8,64.7,15.6,129.4,63.2,177c59,59,148.1,79.5,227,52  L440,550.9z" />
		</svg>
	);
}

export default WrenchIcon;