import React, { ChangeEvent } from "react";
import { IconButton, MenuItem, Select, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import theme from "../theme";
import { topics } from "../config"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = (theme: Theme) =>
  createStyles({
    autocompleteRoot: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
      "& .MuiOutlinedInput-root": {
        paddingLeft: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    autocompleteInputPlaceholder: {
      fontSize: "13px",
      lineHeight: "24px",
      color: "#8f979f",
    },
    autocompleteInputValue: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: "15px",
      lineHeight: "24px",
    },
    dropdownStyle: {
      backgroundColor:'white',
      borderRadius: 0,
      "& li": {
        marginTop: 0,
        marginBottom: 0,
        fontSize: "15px",
        lineHeight: "24px",
        "&::hover": {
          backgroundColor: "#f6f7f8",
        },
      },
      minWidth: "300px"
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  onChange: (params: string)=>void
}
export function TopicSelect(props: IProps) {
  const classes = useStyles(theme);
  const [value, setValue] = React.useState(topics[0]);
  const [open, setOpen] = React.useState(false)
  const {onChange} = props
  React.useEffect(() => {
    onChange(value)
  }, [value]);

  const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    console.log(event.target.value)
    if( event.target.value ){
      console.log({value})
      setValue(event.target.value as string);
    }
  }
  const renderValue = (value: string) => {
    if( value ){
      return <Typography className={classes.autocompleteInputValue}>{value.toUpperCase()}</Typography>
    }else{
      return <Typography className={classes.autocompleteInputPlaceholder}>Select Topic</Typography>
    }
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.autocompleteRoot}>
      <Select 
        fullWidth
        IconComponent={(...props)=> <IconButton onClick={handleOpen}>
          {console.log(props)}
          <ArrowDropDownIcon/>
        </IconButton>}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        renderValue={ (value: unknown) => renderValue(value as string) } 
        value={value}
        onChange={handleChange}>
          {topics.map( topic  => <MenuItem value={topic}>{topic.toUpperCase()}</MenuItem>)}
      </Select>
    </div>
  );
}
