import * as React from "react";

function FAQIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" x="0px" y="0px" fill={props.fill ? props.fill : "#FFF"} transform="scale(0.83)" {...props}>
			<path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z" />
			<path d="M12.717,5.063A4,4,0,0,0,8,9a1,1,0,0,0,2,0,2,2,0,0,1,2.371-1.967,2.024,2.024,0,0,1,1.6,1.6,2,2,0,0,1-1.2,2.22A2.916,2.916,0,0,0,11,13.58V14a1,1,0,0,0,2,0v-.42a.938.938,0,0,1,.527-.882,4,4,0,0,0-.81-7.635Z" />
			<circle cx="12" cy="18" r="1" />
		</svg>
	);
}

export default FAQIcon;