import * as React from "react";

function SvgDownloadIcon(props) {
  return (
    <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#333" fill="none" fillRule="evenodd">
        <path d="M10 2v12M15 9l-5 5-5-5M2 17h16" />
      </g>
    </svg>
  );
}

export default SvgDownloadIcon;
