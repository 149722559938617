import { ITarget, IModel, IMarket, ISummary, TargetType } from "./Ifaces.d";
import { markets } from "./config";
import { getMaxDate } from "./utils";

export interface IState {
  logged_in: boolean;
  date: Date;
  region: IMarket;
  brand: ITarget | undefined;
  brands: ITarget[];
  autodataBrands: ITarget[];
  models: IModel[];
  target: TargetType;
  brand_selection: ITarget[];
  model_selection: IModel[];
  summary: ISummary | undefined;
  alerts: number;
  details_path: string;
}

export enum ActionTypes {
  LOGIN = "LOGIN",
  SET_DATE = "SET_DATE",
  SET_REGION = "SET_REGION",
  SET_TARGET = "SET_TARGET",
  RESET_BRANDS_AND_MODELS = "RESET_BRANDS_AND_MODELS",
  SET_BRAND_SELECTION = "SET_BRAND_SELECTION",
  SET_MODEL_SELECTION = "SET_MODEL_SELECTION",
  SET_SUMMARY = "SET_SUMMARY",
  SET_DETAILS_PATH = "SET_DETAILS_PATH",
}

export interface ILogin {
  type: ActionTypes.LOGIN;
  brand: ITarget;
  region: IMarket;
  alerts: number;
}

export interface ISetDate {
  type: ActionTypes.SET_DATE;
  date: Date;
}

export interface ISetRegion {
  type: ActionTypes.SET_REGION;
  region: IMarket;
}

export interface ISetTarget {
  type: ActionTypes.SET_TARGET;
  target: TargetType;
}

export interface IResetBrandsAndModels {
  type: ActionTypes.RESET_BRANDS_AND_MODELS;
  brands: ITarget[];
  autodataBrands: ITarget[];
  models: IModel[];
}

export interface ISetBrandSelection {
  type: ActionTypes.SET_BRAND_SELECTION;
  brand_selection: ITarget[];
}

export interface ISetModelSelection {
  type: ActionTypes.SET_MODEL_SELECTION;
  model_selection: IModel[];
}

export interface ISetSummary {
  type: ActionTypes.SET_SUMMARY;
  summary: ISummary | undefined;
}

export interface ISetDetailsPath {
  type: ActionTypes.SET_DETAILS_PATH;
  details_path: string;
}

/*
 * Actions.
 */
export type Actions =
  | ILogin
  | ISetDate
  | ISetRegion
  | ISetTarget
  | IResetBrandsAndModels
  | ISetBrandSelection
  | ISetModelSelection
  | ISetSummary
  | ISetDetailsPath;

/*
 * Initial state for the score panel application.
 */
export const initialState: IState = {
  logged_in: false,
  date: getMaxDate(),
  region: markets[0],
  brand: undefined,
  brands: [],
  autodataBrands: [],
  models: [],
  target: TargetType.brands,
  brand_selection: [],
  model_selection: [],
  summary: undefined,
  alerts: 0,
  details_path: "/details/vehicle-rating",
};

function cleanUpBrandSelection(brands: ITarget[], brand_selection: ITarget[]) {
  const _selection = [...brand_selection];
  for (let i = 0; i < _selection.length; i++) {
    const pos = brands.findIndex(
      (b: ITarget) => b.ahb_id === _selection[i].ahb_id
    );
    if (pos === -1) {
      _selection.splice(i, 1);
    }
  }
  return _selection as ITarget[];
}

function cleanUpModelSelection(models: IModel[], model_selection: IModel[]) {
  const _selection = [...model_selection];
  for (let i = 0; i < _selection.length; i++) {
    const pos = models.findIndex(
      (m: IModel) => m.ahb_id === _selection[i].ahb_id
    );
    if (pos === -1) {
      _selection.splice(i, 1);
    }
  }
  return _selection as IModel[];
}
/*
 * App reducer.
 */
export function reducer(state: IState, action: Actions) {
  switch (action.type) {
    case ActionTypes.LOGIN: {
      return {
        ...state,
        logged_in: true,
        brand: action.brand,
        region: action.region,
        alerts: action.alerts
      };
    }
    case ActionTypes.SET_DATE: {
      return { ...state, date: action.date };
    }
    case ActionTypes.SET_REGION: {
      return { ...state, region: action.region };
    }
    case ActionTypes.SET_TARGET: {
      return { ...state, target: action.target };
    }
    case ActionTypes.RESET_BRANDS_AND_MODELS: {
      const { brand_selection: brand_sel, model_selection: model_sel } = state;
      return {
        ...state,
        brands: action.brands,
        autodataBrands: action.autodataBrands,
        brand_selection: cleanUpBrandSelection(action.brands, brand_sel),
        model_selection: cleanUpModelSelection(action.models, model_sel)
      };
    }
    case ActionTypes.SET_BRAND_SELECTION: {
      return { ...state, brand_selection: action.brand_selection };
    }
    case ActionTypes.SET_MODEL_SELECTION: {
      return { ...state, model_selection: action.model_selection };
    }
    case ActionTypes.SET_SUMMARY: {
      return { ...state, summary: action.summary };
    }
    case ActionTypes.SET_DETAILS_PATH: {
      return { ...state, details_path: action.details_path };
    }
    default:
      return state;
  }
}
