import React from "react";
import firebase from "firebase/app";

import { IState, Actions } from "./reducers";

// --------------------------------------------------------
// Context to hold App State.

interface IStateCtxProps {
  state: IState;
  dispatch: (action: Actions) => void;
}

export const StateCtx = React.createContext({} as IStateCtxProps);

// --------------------------------------------------------
// Context to hold Authenticated user.

export type User = firebase.User | null;

interface IAuthStateProps {
  user: User;
  loading: boolean;
}

export const AuthCtx = React.createContext({} as IAuthStateProps);
