import { IMarket } from "./Ifaces.d";

import cnFlag from "./assets/flag-cn.svg";
import deFlag from "./assets/flag-de.svg";
import ukFlag from "./assets/flag-uk.svg";
import usFlag from "./assets/flag-us.svg";

export const apiUrl = "https://us-central1-automotiveheartbeat.cloudfunctions.net/api";

// export const apiUrl = 'http://localhost:5000/automotiveheartbeat/us-central1/api';

export const lineColors = [
  "#1c86ed",
  "#8e969e",
  "#79e08f",
  "#fdcc28",
  "#f08766",
  "#b47ee3",
  "#88a11f",
  "#4ac9ba",
  "#d81415",
  "#000000",
];

export const notifPerPage = 10;

export const markets: IMarket[] = [
  { label: "China", src: cnFlag, code: "CN" },
  { label: "Germany", src: deFlag, code: "DE" },
  { label: "UK", src: ukFlag, code: "UK" },
  { label: "USA", src: usFlag, code: "US" },
];

// --------------------------------------------------------------------
// Used in "Details -> User Discussions -> Overview" view.

export const cloudTerms: string[] = [
  "Battery performance",
  "Boot",
  "Brake",
  "Cable",
  "Charging",
  "Charging stations",
  "Doors",
  "Ecology",
  "Key",
  "Light",
  "Noise",
  "Range",
  "Rims",
  "Seats",
  "Trunk lid",
];

export const topics: string[] = [
  "connectivity",
  "autonomous driving",
];


export enum AUTODATA_KEY {
  // GI_BRAND = "GI_BRAND",
  // GI_MODEL = "GI_MODEL",
  GI_GENERATION = "GI_GENERATION",
  // GI_ENGINE = "GI_ENGINE",
  GI_START_OF_PRODUCTION = "GI_START_OF_PRODUCTION",
  GI_POWERTRAIN_ARCHITECTURE = "GI_POWERTRAIN_ARCHITECTURE",
  GI_BODY_TYPE = "GI_BODY_TYPE",
  GI_SEATS = "GI_SEATS",
  GI_DOORS = "GI_DOORS",
  EHS_BATTERY_CAPACITY = "EHS_BATTERY_CAPACITY",
  EHS_ALL_ELECTRIC_RANGE = "EHS_ALL_ELECTRIC_RANGE",
  EHS_AVERAGE_ENERGY_CONSUMPTION_WLTP = "EHS_AVERAGE_ENERGY_CONSUMPTION_WLTP",
  EHS_MAXIMUM_SPEED_ELECTRIC = "EHS_MAXIMUM_SPEED_ELECTRIC",
  EHS_SYSTEM_POWER = "EHS_SYSTEM_POWER",
  EHS_ELECTRIC_MOTOR_POWER_1 = "EHS_ELECTRIC_MOTOR_POWER_1",
  EHS_ELECTRIC_MOTOR_TORQUE_1 = "EHS_ELECTRIC_MOTOR_TORQUE_1",
  EHS_ELECTRIC_MOTOR_ENGINE_LOCATION_1 = "EHS_ELECTRIC_MOTOR_ENGINE_LOCATION_1",
  EHS_ELECTRIC_MOTOR_POWER_2 = "EHS_ELECTRIC_MOTOR_POWER_2",
  EHS_ELECTRIC_MOTOR_TORQUE_2 = "EHS_ELECTRIC_MOTOR_TORQUE_2",
  EHS_ELECTRIC_MOTOR_ENGINE_LOCATION_2 = "EHS_ELECTRIC_MOTOR_ENGINE_LOCATION_2",
  PeS_FUEL_CONSUMPTION_AT_LOW_SPEED_WPLT = "PeS_FUEL_CONSUMPTION_AT_LOW_SPEED_WPLT",
  PeS_FUEL_CONSUMPTION_AT_VERY_HIGH_SPEED_WPLT = "PeS_FUEL_CONSUMPTION_AT_VERY_HIGH_SPEED_WPLT",
  PeS_COMBINED_FUEL_CONSUMPTION_WPLT = "PeS_COMBINED_FUEL_CONSUMPTION_WPLT",
  PeS_CO2_EMISSIONS_WLTP = "PeS_CO2_EMISSIONS_WLTP",
  PeS_FUEL_CONSUMPTION_URBAN = "PeS_FUEL_CONSUMPTION_URBAN",
  PeS_FUEL_CONSUMPTION_EXTRA_URBAN = "PeS_FUEL_CONSUMPTION_EXTRA_URBAN",
  PeS_FUEL_CONSUMPTION_EXTRA_COMBINED = "PeS_FUEL_CONSUMPTION_EXTRA_COMBINED",
  PeS_CO2_EMISSIONS_NEDC = "PeS_CO2_EMISSIONS_NEDC",
  PeS_CO2_EMISSIONS = "PeS_CO2_EMISSIONS",
  PeS_FUEL_TYPE = "PeS_FUEL_TYPE",
  PeS_ACCELERATION_0_100_KMH = "PeS_ACCELERATION_0_100_KMH",
  PeS_ACCELERATION_0_200_KMH = "PeS_ACCELERATION_0_200_KMH",
  PeS_ACCELERATION_0_62_MPH = "PeS_ACCELERATION_0_62_MPH",
  PeS_ACCELERATION_0_60_MPH_BY_AUTODATA = "PeS_ACCELERATION_0_60_MPH_BY_AUTODATA",
  PeS_MAXIMUM_SPEED = "PeS_MAXIMUM_SPEED",
  PeS_EMISSION_STANDARD = "PeS_EMISSION_STANDARD",
  PeS_WEIGHT_TO_POWER_RATIO = "PeS_WEIGHT_TO_POWER_RATIO",
  EnS_POWER = "EnS_POWER",
  EnS_POWER_PER_LITRE = "EnS_POWER_PER_LITRE",
  EnS_TORQUE = "EnS_TORQUE",
  EnS_ENGINE_LOCATION = "EnS_ENGINE_LOCATION",
  EnS_ENGINE_DISPLACEMENT = "EnS_ENGINE_DISPLACEMENT",
  EnS_NUMBER_OF_CYLINDERS = "EnS_NUMBER_OF_CYLINDERS",
  EnS_POSITION_OF_CYLINDERS = "EnS_POSITION_OF_CYLINDERS",
  EnS_CYLINDER_BORE = "EnS_CYLINDER_BORE",
  SVW_KERB_WEIGHT = "SVW_KERB_WEIGHT",
  SVW_MAX_WEIGHT = "SVW_MAX_WEIGHT",
  SVW_MAX_LOAD = "SVW_MAX_LOAD",
  SVW_TRUNK_BOOT_SPACE_MINIMUM = "SVW_TRUNK_BOOT_SPACE_MINIMUM",
  SVW_TRUNK_BOOT_SPACE_MAXIMUM = "SVW_TRUNK_BOOT_SPACE_MAXIMUM",
  SVW_FUEL_TANK_CAPACITY = "SVW_FUEL_TANK_CAPACITY",
  SVW_ADBLUE_TANK = "SVW_ADBLUE_TANK",
  SVW_MAX_ROOF_LOAD = "SVW_MAX_ROOF_LOAD",
  SVW_PERMITTED_TRAILER_LOAD_WITH_BRAKES_8_PERCENT = "SVW_PERMITTED_TRAILER_LOAD_WITH_BRAKES_8_PERCENT",
  SVW_PERMITTED_TRAILER_LOAD_WITH_BRAKES_12_PERCENT = "SVW_PERMITTED_TRAILER_LOAD_WITH_BRAKES_12_PERCENT",
  SVW_PERMITTED_TRAILER_LOAD_WITHOUT_BRAKES = "SVW_PERMITTED_TRAILER_LOAD_WITHOUT_BRAKES",
  SVW_PERMITTED_TOWBAR_DOWNLOAD = "SVW_PERMITTED_TOWBAR_DOWNLOAD",
  DI_LENGTH = "DI_LENGTH",
  DI_WIDTH = "DI_WIDTH",
  DI_WIDTH_WITH_MIRRORS_FOLDED = "DI_WIDTH_WITH_MIRRORS_FOLDED",
  DI_WIDTH_INCLUDING_MIRRORS = "DI_WIDTH_INCLUDING_MIRRORS",
  DI_HEIGHT = "DI_HEIGHT",
  DI_WHEELBASE = "DI_WHEELBASE",
  DI_FRONT_TRACK = "DI_FRONT_TRACK",
  DI_REAR_BACK_TRACK = "DI_REAR_BACK_TRACK",
  DI_FRONT_OVERHANG = "DI_FRONT_OVERHANG",
  DI_REAR_OVERHANG = "DI_REAR_OVERHANG",
  DI_DRAG_COEFFICIENT = "DI_DRAG_COEFFICIENT",
  DI_MINIMUM_TURING_CIRCLE_TURNING_DIAMETER = "DI_MINIMUM_TURING_CIRCLE_TURNING_DIAMETER",
  DBS_DRIVETRAIN_ARCHITECTURE = "DBS_DRIVETRAIN_ARCHITECTURE",
  DBS_DRIVE_WHEEL = "DBS_DRIVE_WHEEL",
  DBS_NUMBER_OF_GEARS_MANUAL_TRANSMISION = "DBS_NUMBER_OF_GEARS_MANUAL_TRANSMISION",
  DBS_NUMBER_OF_GEARS_AUTOMATIC_TRANSMISION = "DBS_NUMBER_OF_GEARS_AUTOMATIC_TRANSMISION",
  DBS_FRONT_SUSPENSION = "DBS_FRONT_SUSPENSION",
  DBS_REAR_SUSPENSION = "DBS_REAR_SUSPENSION",
  DBS_FRONT_BREAKS = "DBS_FRONT_BREAKS",
  DBS_REAR_BREAKS = "DBS_REAR_BREAKS",
  DBS_ASSISTING_SYSTEMS = "DBS_ASSISTING_SYSTEMS",
  DBS_STEERING_TYPE = "DBS_STEERING_TYPE",
  DBS_POWER_STEERING = "DBS_POWER_STEERING",
  DBS_TIRES_SIZE = "DBS_TIRES_SIZE",
  DBS_WHEEL_RIMS_SIZE = "DBS_WHEEL_RIMS_SIZE",
}

export enum UNIT {
  HORSE_POWER = "Hp",
  POWER_PER_LITRE = "Hp/l",
  LITER_PER_100_KM = "l/100km",
  GRAM_PER_100_KM = "g/100km",
  SECONDS = "sec",
  KILOMETER_PER_HOUR = "km/h",
  KILOMETER = "km",
  KILOGRAM = "Kg",
  MILIMETER = "mm",
  CENTIMETER = "cm",
  NEWTON_METER = "Nm",
  METER = "m",
  LITRE = "l",
  KILOWATT_HOUR = "kWh",
  KILOWATT_HOUR_PER_100_KILOMETER = "kWh/100km",
}

export const ALLOWED_AUTODATA_KEYS = [
  {
    group: 0,
    name: "Generation",
    key: AUTODATA_KEY.GI_GENERATION,
    unit: null,
    value: true,
  },
  {
    group: 0,
    name: "Start of production",
    key: AUTODATA_KEY.GI_START_OF_PRODUCTION,
    unit: null,
    value: true,
  },
  {
    group: 0,
    name: "Powertrain Architecture",
    key: AUTODATA_KEY.GI_POWERTRAIN_ARCHITECTURE,
    unit: null,
    value: true,
  },
  {
    group: 0,
    name: "Body type",
    key: AUTODATA_KEY.GI_BODY_TYPE,
    unit: null,
    value: true,
  },
  {
    group: 0,
    name: "Seats",
    key: AUTODATA_KEY.GI_SEATS,
    unit: null,
    value: true,
  },
  {
    group: 0,
    name: "Doors",
    key: AUTODATA_KEY.GI_DOORS,
    unit: null,
    value: true,
  },
  {
    group: 1,
    name: "Battery capacity",
    key: AUTODATA_KEY.EHS_BATTERY_CAPACITY,
    unit: UNIT.KILOWATT_HOUR,
    value: false,
  },
  {
    group: 1,
    name: "All-electric range",
    key: AUTODATA_KEY.EHS_ALL_ELECTRIC_RANGE,
    unit: UNIT.KILOMETER,
    value: false,
  },
  {
    group: 1,
    name: "Average Energy consumption (WLTP)",
    key: AUTODATA_KEY.EHS_AVERAGE_ENERGY_CONSUMPTION_WLTP,
    unit: UNIT.KILOWATT_HOUR_PER_100_KILOMETER,
    value: false,
  },
  {
    group: 1,
    name: "Max speed (electric)",
    key: AUTODATA_KEY.EHS_MAXIMUM_SPEED_ELECTRIC,
    unit: UNIT.KILOMETER_PER_HOUR,
    value: false,
  },
  {
    group: 1,
    name: "System power",
    key: AUTODATA_KEY.EHS_SYSTEM_POWER,
    unit: UNIT.HORSE_POWER,
    value: false,
  },
  {
    group: 1,
    name: "Electric motor power 1",
    key: AUTODATA_KEY.EHS_ELECTRIC_MOTOR_POWER_1,
    unit: null,
    value: false,
  },
  {
    group: 1,
    name: "Electric motor Torque 1",
    key: AUTODATA_KEY.EHS_ELECTRIC_MOTOR_TORQUE_1,
    unit: null,
    value: false,
  },
  {
    group: 1,
    name: "Engine location 1",
    key: AUTODATA_KEY.EHS_ELECTRIC_MOTOR_ENGINE_LOCATION_1,
    unit: null,
    value: false,
  },
  {
    group: 1,
    name: "Electric motor power 2",
    key: AUTODATA_KEY.EHS_ELECTRIC_MOTOR_POWER_2,
    unit: null,
    value: false,
  },
  {
    group: 1,
    name: "Electric motor Torque 2",
    key: AUTODATA_KEY.EHS_ELECTRIC_MOTOR_TORQUE_2,
    unit: null,
    value: false,
  },
  {
    group: 1,
    name: "Engine location 2",
    key: AUTODATA_KEY.EHS_ELECTRIC_MOTOR_ENGINE_LOCATION_2,
    unit: null,
    value: false,
  },
  {
    group: 2,
    name: "Fuel consumption at Low speed (WLTP)",
    key: AUTODATA_KEY.PeS_FUEL_CONSUMPTION_AT_LOW_SPEED_WPLT,
    unit: UNIT.LITER_PER_100_KM,
    value: false,
  },
  {
    group: 2,
    name: "Fuel consumption at very high speed (WLTP)",
    key: AUTODATA_KEY.PeS_FUEL_CONSUMPTION_AT_VERY_HIGH_SPEED_WPLT,
    unit: UNIT.LITER_PER_100_KM,
    value: false,
  },
  {
    group: 2,
    name: "Combined fuel consumption (WLTP)",
    key: AUTODATA_KEY.PeS_COMBINED_FUEL_CONSUMPTION_WPLT,
    unit: UNIT.LITER_PER_100_KM,
    value: false,
  },
  {
    group: 2,
    name: "CO2 emissions (WLTP)",
    key: AUTODATA_KEY.PeS_CO2_EMISSIONS_WLTP,
    unit: UNIT.GRAM_PER_100_KM,
    value: false,
  },
  {
    group: 2,
    name: "Fuel consumption (economy) - urban (NEDC, WLTP equivalent)",
    key: AUTODATA_KEY.PeS_FUEL_CONSUMPTION_URBAN,
    unit: UNIT.LITER_PER_100_KM,
    value: false,
  },
  {
    group: 2,
    name: "Fuel consumption (economy) - extra urban (NEDC, WLTP equivalen",
    key: AUTODATA_KEY.PeS_FUEL_CONSUMPTION_EXTRA_URBAN,
    unit: UNIT.LITER_PER_100_KM,
    value: false,
  },
  {
    group: 2,
    name: "Fuel consumption (economy) - combined (NEDC, WLTP equivalent)",
    key: AUTODATA_KEY.PeS_FUEL_CONSUMPTION_EXTRA_COMBINED,
    unit: UNIT.LITER_PER_100_KM,
    value: false,
  },
  {
    group: 2,
    name: "CO2 emissions (NEDC, WLTP equivalent)",
    key: AUTODATA_KEY.PeS_CO2_EMISSIONS_NEDC,
    unit: UNIT.GRAM_PER_100_KM,
    value: false,
  },
  {
    group: 2,
    name: "CO2 emissions",
    key: AUTODATA_KEY.PeS_CO2_EMISSIONS,
    unit: UNIT.GRAM_PER_100_KM,
    value: false,
  },
  {
    group: 2,
    name: "Fuel Type",
    key: AUTODATA_KEY.PeS_FUEL_TYPE,
    unit: null,
    value: false,
  },
  {
    group: 2,
    name: "Acceleration 0 - 100 km/h",
    key: AUTODATA_KEY.PeS_ACCELERATION_0_100_KMH,
    unit: UNIT.SECONDS,
    value: false,
  },
  {
    group: 2,
    name: "Acceleration 0 - 200 km/h",
    key: AUTODATA_KEY.PeS_ACCELERATION_0_200_KMH,
    unit: UNIT.SECONDS,
    value: false,
  },
  {
    group: 2,
    name: "Acceleration 0 - 62 mph",
    key: AUTODATA_KEY.PeS_ACCELERATION_0_62_MPH,
    unit: UNIT.SECONDS,
    value: false,
  },
  {
    group: 2,
    name: "Maximum speed",
    key: AUTODATA_KEY.PeS_MAXIMUM_SPEED,
    unit: UNIT.KILOMETER_PER_HOUR,
    value: false,
  },
  {
    group: 2,
    name: "Emission standard",
    key: AUTODATA_KEY.PeS_EMISSION_STANDARD,
    unit: null,
    value: false,
  },
  {
    group: 2,
    name: "Weight-to-power ratio",
    key: AUTODATA_KEY.PeS_WEIGHT_TO_POWER_RATIO,
    unit: null,
    value: false,
  },
  {
    group: 3,
    name: "Power",
    key: AUTODATA_KEY.EnS_POWER,
    unit: UNIT.HORSE_POWER,
    value: false,
  },
  {
    group: 3,
    name: "Power per litre",
    key: AUTODATA_KEY.EnS_POWER_PER_LITRE,
    unit: UNIT.POWER_PER_LITRE,
    value: false,
  },
  {
    group: 3,
    name: "Torque",
    key: AUTODATA_KEY.EnS_TORQUE,
    unit: UNIT.NEWTON_METER,
    value: false,
  },
  {
    group: 3,
    name: "Engine Location",
    key: AUTODATA_KEY.EnS_ENGINE_LOCATION,
    unit: null,
    value: false,
  },
  {
    group: 3,
    name: "Engine displacement",
    key: AUTODATA_KEY.EnS_ENGINE_DISPLACEMENT,
    unit: null,
    value: false,
  },
  {
    group: 3,
    name: "Number of cylinders",
    key: AUTODATA_KEY.EnS_NUMBER_OF_CYLINDERS,
    unit: null,
    value: false,
  },
  {
    group: 3,
    name: "Position of cylinders",
    key: AUTODATA_KEY.EnS_POSITION_OF_CYLINDERS,
    unit: null,
    value: false,
  },
  {
    group: 3,
    name: "Cylinder Bore",
    key: AUTODATA_KEY.EnS_CYLINDER_BORE,
    unit:  UNIT.MILIMETER,
    value: false,
  },
  {
    group: 4,
    name: "Kerb Weight",
    key: AUTODATA_KEY.SVW_KERB_WEIGHT,
    unit: UNIT.KILOGRAM,
    value: false,
  },
  {
    group: 4,
    name: "Max. weight",
    key: AUTODATA_KEY.SVW_MAX_WEIGHT,
    unit: UNIT.KILOGRAM,
    value: false,
  },
  {
    group: 4,
    name: "Max load",
    key: AUTODATA_KEY.SVW_MAX_LOAD,
    unit: UNIT.KILOGRAM,
    value: false,
  },
  {
    group: 4,
    name: "Trunk (boot) space - minimum",
    key: AUTODATA_KEY.SVW_TRUNK_BOOT_SPACE_MINIMUM,
    unit: UNIT.LITRE,
    value: false,
  },
  {
    group: 4,
    name: "Trunk (boot) space - maximum",
    key: AUTODATA_KEY.SVW_TRUNK_BOOT_SPACE_MAXIMUM,
    unit: UNIT.LITRE,
    value: false,
  },
  {
    group: 4,
    name: "Fuel tank capacity",
    key: AUTODATA_KEY.SVW_FUEL_TANK_CAPACITY,
    unit: UNIT.LITRE,
    value: false,
  },
  {
    group: 4,
    name: "AdBlue tank",
    key: AUTODATA_KEY.SVW_ADBLUE_TANK,
    unit: UNIT.LITRE,
    value: false,
  },
  {
    group: 4,
    name: "Max. roof load",
    key: AUTODATA_KEY.SVW_MAX_ROOF_LOAD,
    unit: UNIT.KILOGRAM,
    value: false,
  },
  {
    group: 4,
    name: "Permitted trailer load with brakes (8%)",
    key: AUTODATA_KEY.SVW_PERMITTED_TRAILER_LOAD_WITH_BRAKES_8_PERCENT,
    unit: UNIT.KILOGRAM,
    value: false,
  },
  {
    group: 4,
    name: "Permitted trailer load with brakes (12%)",
    key: AUTODATA_KEY.SVW_PERMITTED_TRAILER_LOAD_WITH_BRAKES_12_PERCENT,
    unit: UNIT.KILOGRAM,
    value: false,
  },
  {
    group: 4,
    name: "Permitted trailer load without brakes",
    key: AUTODATA_KEY.SVW_PERMITTED_TRAILER_LOAD_WITHOUT_BRAKES,
    unit: UNIT.KILOGRAM,
    value: false,
  },
  {
    group: 4,
    name: "Permitted towbar download",
    key: AUTODATA_KEY.SVW_PERMITTED_TOWBAR_DOWNLOAD,
    unit: UNIT.KILOGRAM,
    value: false,
  },
  {
    group: 5,
    name: "Length",
    key: AUTODATA_KEY.DI_LENGTH,
    unit: UNIT.MILIMETER,
    value: false,
  },
  {
    group: 5,
    name: "Width",
    key: AUTODATA_KEY.DI_WIDTH,
    unit: UNIT.MILIMETER,
    value: false,
  },
  {
    group: 5,
    name: "Width with mirrors folded",
    key: AUTODATA_KEY.DI_WIDTH_WITH_MIRRORS_FOLDED,
    unit: UNIT.MILIMETER,
    value: false,
  },
  {
    group: 5,
    name: "Width including mirrors",
    key: AUTODATA_KEY.DI_WIDTH_INCLUDING_MIRRORS,
    unit: UNIT.MILIMETER,
    value: false,
  },
  {
    group: 5,
    name: "Height",
    key: AUTODATA_KEY.DI_HEIGHT,
    unit: UNIT.MILIMETER,
    value: false,
  },
  {
    group: 5,
    name: "Wheelbase",
    key: AUTODATA_KEY.DI_WHEELBASE,
    unit: UNIT.MILIMETER,
    value: false,
  },
  {
    group: 5,
    name: "Front track",
    key: AUTODATA_KEY.DI_FRONT_TRACK,
    unit: UNIT.MILIMETER,
    value: false,
  },
  {
    group: 5,
    name: "Rear (Back) track",
    key: AUTODATA_KEY.DI_REAR_BACK_TRACK,
    unit: UNIT.MILIMETER,
    value: false,
  },
  {
    group: 5,
    name: "Front overhang",
    key: AUTODATA_KEY.DI_FRONT_OVERHANG,
    unit: UNIT.MILIMETER,
    value: false,
  },
  {
    group: 5,
    name: "Rear overhang",
    key: AUTODATA_KEY.DI_REAR_OVERHANG,
    unit: UNIT.MILIMETER,
    value: false,
  },
  {
    group: 5,
    name: "Drag coefficient (Cd)",
    key: AUTODATA_KEY.DI_DRAG_COEFFICIENT,
    unit: null,
    value: false,
  },
  {
    group: 5,
    name: "Minimum turning circle",
    key: AUTODATA_KEY.DI_MINIMUM_TURING_CIRCLE_TURNING_DIAMETER,
    unit: UNIT.METER,
    value: false,
  },
  {
    group: 6,
    name: "Drivetrain Architecture",
    key: AUTODATA_KEY.DBS_DRIVETRAIN_ARCHITECTURE,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Drive wheel",
    key: AUTODATA_KEY.DBS_DRIVE_WHEEL,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Number of Gears (manual transmission)",
    key: AUTODATA_KEY.DBS_NUMBER_OF_GEARS_MANUAL_TRANSMISION,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Number of Gears (automatic transmission)",
    key: AUTODATA_KEY.DBS_NUMBER_OF_GEARS_AUTOMATIC_TRANSMISION,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Front suspension",
    key: AUTODATA_KEY.DBS_FRONT_SUSPENSION,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Rear suspension",
    key: AUTODATA_KEY.DBS_REAR_SUSPENSION,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Front brakes",
    key: AUTODATA_KEY.DBS_FRONT_BREAKS,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Rear brakes",
    key: AUTODATA_KEY.DBS_REAR_BREAKS,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Assisting systems",
    key: AUTODATA_KEY.DBS_ASSISTING_SYSTEMS,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Steering type",
    key: AUTODATA_KEY.DBS_STEERING_TYPE,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Power steering",
    key: AUTODATA_KEY.DBS_POWER_STEERING,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Tires size",
    key: AUTODATA_KEY.DBS_TIRES_SIZE,
    unit: null,
    value: false,
  },
  {
    group: 6,
    name: "Wheel rims size",
    key: AUTODATA_KEY.DBS_WHEEL_RIMS_SIZE,
    unit: null,
    value: false,
  },
];

export enum POWERTRAIN {
  MHEV = "MHEV",
  BEV = "BEV",
  ICE = "ICE",
  PHEV = "PHEV",
  FHEV = "FHEV",
  FCEV = "FCEV",
}

export const validThresholds = [
  { value: 1, label: "-1% / +1%" },
  { value: 5, label: "-5% / +5%" },
  { value: 10, label: "-10% / +10%" },
];
