import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/analytics";

let config = {
  apiKey: "AIzaSyCKCoRqZ3G0hC7COrYyZ8QKgKwb9rlRF_w",
  authDomain: "automotiveheartbeat.firebaseapp.com",
  databaseURL: "https://automotiveheartbeat.firebaseio.com",
  projectId: "automotiveheartbeat",
  storageBucket: "automotiveheartbeat.appspot.com",
  messagingSenderId: "1032388533221",
  appId: "1:1032388533221:web:f9c609a6b634cd937aed0c",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const storage = firebase.storage().ref();
const database = firebase.database;
const firestore = firebase.firestore();
const analytics = firebase.analytics();

const headerConfig = async (): Promise<{ [key: string]: any }> => {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    return { headers: { Authorization: "Bearer " + token } };
  } else {
    return {};
  }
};

const getDownloadURL = async (filepath: string) => {
  const url = await storage.child(filepath).getDownloadURL();
  return url
};

export { auth, storage, database, analytics, firestore, getDownloadURL, headerConfig };
