import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  IconButton,
  // Button,
  // FormControl,
  // Link,
  // TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { auth } from "../Firebase";
import firebase from "firebase/app";
import theme from "../theme";
import LightMicrosoftSignIn from "../Icons/LightMicrosoftSignIn";
import axios from "axios";

const styles = (theme: Theme) =>
  createStyles({
    loginBox: {
      width: 320,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
      marginBottom: 96,
    },
    loginTitle: {
      marginBottom: 53,
      textTransform: "uppercase",
      fontFamily: "Rajdhani",
      fontSize: "20px",
      lineHeight: "24px",
    },
    alertDiv: {
      marginBottom: theme.spacing(3),
    },
    alertMsg: {
      fontWeight: "normal",
      fontSize: "15px",
    },
    label: {
      fontFamily: "Open Sans",
      fontWeight: "bold",
      fontSize: "12px",
    },
    formControl: {
      width: "100%",
      marginTop: 7,
      marginBottom: 35,
    },
    input: {
      fontFamily: "Open Sans",
      fontWeight: "normal",
      fontSize: "15px",
      borderRadius: 2,
    },
    labelAndLink: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    forgotPwdLink: {
      fontFamily: "Open Sans",
      fontWeight: "bold",
      fontSize: "12px",
      color: "#EE754F",
    },
    loginBtn: {
      marginTop: 25,
    },
    microsoftLoginBtn: {
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "#FFF",
      },
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  email: string;
  pwd: string;
  errorMsg: string;
  onClick: (email: string, pwd: string) => void;
}

export default function LoginWidget(props: IProps) {
  const classes = useStyles(theme);

  const [email, setEmail] = React.useState<string>(props.email || "");
  const [pwd, setPwd] = React.useState<string>(props.pwd || "");

  const { t: translate } = useTranslation();

  const handleChange = (field_name: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (event.target as HTMLInputElement).value;
    if (field_name === "email") {
      setEmail(value);
    } else if (field_name === "pwd") {
      setPwd(value);
    }
  };

  const getMicrosoftProfilePicture = async (
    accessToken: string,
  ): Promise<any> => {
    const res = await axios.get(
      "https://graph.microsoft.com/v1.0/me/photo/$value",
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "image/jpg",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return res.data;
  };

  const handleSignInWithMicrosoft = async () => {
    var provider = new firebase.auth.OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      tenant: "fc4b9672-10b3-4192-bf77-973d3433b822",
    });
    try {
      const result = await auth.signInWithPopup(provider);
      const credential = result.credential;
      const accessToken = (credential as any).accessToken;
      const data = await getMicrosoftProfilePicture(accessToken);

      var storageRef = firebase.storage().ref();
      var userPhoto = storageRef.child(
        `user_images/${auth.currentUser?.uid}/${auth.currentUser?.uid}.jpg`,
      );
      var metadata = {
        contentType: "image/jpeg",
      };
      userPhoto.put(data, metadata);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div className={classes.loginBox}>
      {/* <Typography className={classes.loginTitle}>
        {translate("login.title")}
      </Typography>

      {props.errorMsg.length > 0 && (
        <div className={classes.alertDiv}>
          <Alert classes={{ message: classes.alertMsg }} severity="error">
            {props.errorMsg}
          </Alert>
        </div>
      )} */}
      {/* <Typography className={classes.label}> */}
        {/* {translate("login.email")}
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          required
          id="login-email"
          variant="outlined"
          value={email}
          onChange={handleChange("email")}
          InputProps={{ className: classes.input }}
          inputProps={{ tabIndex: 2 }}
        />
      </FormControl>
      <div className={classes.labelAndLink}>
        <Typography className={classes.label}>
          {translate("login.pwd")}
        </Typography>
        <Link href="/reset-pwd" className={classes.forgotPwdLink}>
          Forgot password?
        </Link>
      </div>
      <FormControl className={classes.formControl}>
        <TextField
          required
          id="login-pwd"
          type="password"
          placeholder={translate("login.pwd_placeholder")}
          variant="outlined"
          value={pwd}
          onChange={handleChange("pwd")}
          InputProps={{ className: classes.input }}
          inputProps={{ tabIndex: 2 }}
        />
      </FormControl>
      <Button
        color="secondary"
        variant="contained"
        className={classes.loginBtn}
        onClick={() => props.onClick(email, pwd)}
        tabIndex="3"
      >
        {translate("login.login")}
      </Button> */}
      {/* <p
        style={{
          display: "block",
          textAlign: "center",
        }}
      >
        or
      </p> */}
      <IconButton
        className={classes.microsoftLoginBtn}
        onClick={() => handleSignInWithMicrosoft()}
      >
        <LightMicrosoftSignIn />
      </IconButton>
    </div>
  );
}
