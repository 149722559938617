import React from "react";
import { Container, Typography, Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DataDownloadButton from "../components/DataDownloadButton";
import theme from "../theme";
import { VehicleRatingTab } from "../components/details";
import { analytics, getDownloadURL } from "../Firebase";
import { createRatingsDownloadJob } from "../API";
import TopRadioWidget from "../components/TopRadioWidget";
import { IMarket, TargetType, ChangeImpact, ITarget } from "../Ifaces.d";

import { BrandsAutocomplete } from "../components/BrandsAutocomplete";
import { ModelsAutocomplete } from "../components/ModelsAutocomplete";
import MarketWidget from "../components/MarketWidget";
import { StateCtx } from "../contexts";

type Parameters = {
  models?: Array<ITarget>
  brands?: Array<ITarget>
  region: IMarket
}

const styles = (theme: Theme) =>
  createStyles({
    rootRadioControl: {
      display: "inline",
      paddingTop: "7px"
    },
    pageRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    paperRoot: {
      padding: 28,
      flexGrow: 1,
      borderRadius: 8,
      backgroundColor: "#fff",
    },
    mainHeader: {
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "32px",
      textTransform: "uppercase",
      marginTop: "50px",
    },
    secondaryHeader: {
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "1.3rem",
      textTransform: "uppercase",
      marginTop: "40px",
    },
  });

const useStyles = makeStyles(styles);

export default function UserRatingsPage() {
  const classes = useStyles(theme);
  const [target, setTarget] = React.useState<TargetType>(TargetType.brands)
  const { state } = React.useContext(StateCtx);
  const [parameters, setParameters] = React.useState<Parameters>({region: state.region})

  const handleDownloadJob = async () => {
    try {
      const result = await createRatingsDownloadJob(
        target === TargetType.brands ? parameters.brands ?? [] : [], 
        target === TargetType.models ? parameters.models ?? [] : [], 
        parameters.region
      );
      const downloadURL = await getDownloadURL(result);
      fetch(downloadURL)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          // create a computed anchor tag and trigger a computed click action on it
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "ratings.xlsx";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          // log event
          analytics.logEvent("ratings_download");
        })
        .catch((e) => {
          // download failed
          analytics.logEvent("ratings_download_error", { error: e.toString() });
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.pageRoot}>
      <Container style={{ margin: "auto", padding: "0px 30px" }}>
        <Typography className={classes.mainHeader}>Vehicle Ratings</Typography>
        <Paper className={classes.paperRoot} style={{ marginTop: 40 }}>
          <Typography
            className={classes.secondaryHeader}
            style={{ marginTop: "0px" }}
          >
            Filter by
          </Typography>
          <TopRadioWidget
            addPadding
            value={target}
            onChange={(value) => setTarget(value)}
          />
          <MarketWidget
            market={parameters.region}
            onChange={(value)=> setParameters({...parameters, region: value})}
          />
          {
            target === TargetType.brands ?
              <BrandsAutocomplete placeholder="Select Brands" changeImpact={ChangeImpact.local} onChange={(value)=> setParameters({...parameters, brands: value})} />
            :
              <ModelsAutocomplete placeholder="Select Models" changeImpact={ChangeImpact.local} onChange={(value)=> setParameters({...parameters, models: value})} />
          }
        </Paper>
        <DataDownloadButton handleDownloadButtonPressed={handleDownloadJob} />
        <Paper className={classes.paperRoot} style={{ marginTop: 30 }}>
          <VehicleRatingTab />
        </Paper>
      </Container>
    </div>
  );
}
