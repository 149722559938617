export interface IUserDefaults {
  own_brand: string;  // ahb_id.
  region: string;  // Value to match with IMarket.code.
  alerts: number;
}

export type OrderType = "asc" | "desc";

export enum ChangeImpact {
  global = "global",
  local = "local"
}

export enum TargetType {
  brands = "brands",
  models = "models",
}

export enum TrendType {
  up = "up",
  steady = "steady",
  down = "down",
}

export interface IMarket {
  label: string;
  src: string;
  code: string;
}

export interface ITarget {
  ahb_id: string; // Brand or model code.
  label: string; // Brand or model name.
}

export interface IModel extends ITarget {
  srcurl: string; // Model's image URL.
}

export interface IMarketTable {
  ahb_id: string;
  rows: IMarketTableRow[];
}

export interface IMarketTableRow {
  market: string;
  overall_performance: number;
  customer_satisfaction: number;
  blast: number;
  perception: number;
  sales_trend_forecast: string;
}

export interface IPeTable {
  place: number;
  brand: string;
  current_value: number;
  past_value: number;
  average_value: number;
}

export interface ICautionTable {
  id: string;
  alert: boolean;
  alert_scope: string;
  tolerance_gap: number;
  threshold: number;
  status: "active" | "inactive";
}

export interface IMetricItem {
  id: string;
  label: string;
}

// Data type to send (create, update) Notification data to the backend.
export interface IPostNotif {
  brand_a: string;
  model_a?: string;
  brand_b: string;
  model_b?: string;
  metric: string;  // Metric ID.
  threshold: number;
}

// Data type to receive (list) notification data from the backend.
export interface IGetNotif {
  id: string;
  alert: boolean;
  alert_scope: string;
  tolerance_gap: number;
  threshold: number;
  active: boolean;
  brand_a: string;
  model_a?: string;
  brand_b: string;
  model_b?: string;
  metric: string;  // Metric ID.
}

////////////////////////////////////////////////////////////////////////////////
////                              API INTERFACE                             ////
////////////////////////////////////////////////////////////////////////////////

// TODO: To be updated as soon as the response coming from backend is
// conceptually redesigned.
export interface IPerformanceSummaryData {
  ahb_id: string;
  kpis: IPerformanceSummaryKPIs;
}

export interface IPerformanceSummaryKPIs {
  // OVERALL PERFORMANCE
  overall_performance: number;
  overall_performance_rank: number;
  // CUSTOMER SATISFACTION
  customer_satisfaction: number;
  customer_satisfaction_rank: number;
  // PERCEPTION
  perception: number;
  perception_rank: number;
  // BLAST
  blast: number;
  blast_rank: number;
  // SALES TREND FORECAST
  sales_trend_forecast: TrendType;
}

// --------------------------------------------------------------------
// Interfaces related to Measures (from low to high level).

export interface ISubMeasureItem {
  sub_measure_id: string;
  label: string;
  items: Array<string>;
}

export interface IMeasureItem {
  measure_id: string;
  label: string;
  sub_measures: ISubMeasureItem[];
}

export interface IMeasures {
  ahb_id: string;
  measures: IMeasureItem[];
}

// --------------------------------------------------------------------

export interface ISummary {
  targetType: TargetType;
  performanceSummaryData: IPerformanceSummaryData[];
  globalMarketComparison: IMarketTable[];
}

export interface ISoVItem {
  topic_id: string;
  value: number;
  ratings: number[];
}

export interface ISoVData {
  ahb_id: string;
  topics: ISoVItem[];
}

interface ICloudItem {
  term_id: string;
  label: string;
  percentage: number;
  weight: number;
}

interface ICloud {
  ahb_id: string;
  cloud: ICloudItem[];
}

interface IDiscDevItem {
  date: string;  // Format: year-month -> "2020-01", "2021-12", ...
  value: number;
}

interface IDiscDevData {
  ahb_id: string;
  values: IDiscDevItem[];
}

interface IAHBRatings {
  region: string;
  ahb_id: string;
  ratings: number[];
}

interface IAHBUserDiscussions {
  ratings: number[][];
  ahb_id: string;
  region: string;
}

interface IAHBSoV {
  topic: ISoVItem;
  ahb_id: string;
  region: string;
}

interface IAHBS2Cloud {
  cloud: ICloudItem[];
  ahb_id: string;
  region: string;
}

interface IAHBDiscountDev {
  values: IDiscDevItem[];
  ahb_id: string;
  region: string;
}

interface IAHBMeasures {
  data: Array<IMeasureItem[]>;
  ahb_id: string;
  region: string;
}

export type IAHBKpis = Array<IMetricItem>

// --------------------------------------------------------------------
// Interfaces relatedto Download Jobs

export interface IDownloadJob {
  storage_filepath: string
}
