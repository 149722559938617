import * as React from "react";

function SvgReleaseNavLeftArrow(props) {
  return (
    <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(-295 -224)"
        fill="none"
        fillRule="evenodd"
      >
        <g stroke="#FFF">
          <path d="M313 234h-12M306 229l-5 5 5 5M298.5 241v-14" />
        </g>
      </g>
    </svg>
  );
}

export default SvgReleaseNavLeftArrow;
