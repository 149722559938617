import React from "react";
import { Link, Popover, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

import theme from "../../theme";
import TernaryBar from "./TernaryBar";
import LongTooltipTitle from "./LongTooltipContent";
import { IAHBKpis } from "../../Ifaces";

const styles = (theme: Theme) =>
  createStyles({
    kpiLine: {
      height: 46,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    popoverDiv: {
      marginTop: -8,
    },
    linkHover: {
      textDecoration: "none",
      color: "#1c86ed",
    },
    notLinkHover: {
      textDecoration: "underline",
      color: "inherit",
    },
    withTip: {
      fontFamily: "Open Sans",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "34px",
      cursor: "pointer",
    },
    withoutTip: {
      textDecoration: "none",
      fontFamily: "Open Sans",
      fontSize: "12px",
      lineHeight: "34px",
    },
    rightSide: {
      width: 165,
      height: 26,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  ratings: number[][];
  kpis: IAHBKpis;
}

type AnchorType = HTMLElement | null;

export default function UserDiscussionDetailList(props: IProps) {
  const classes = useStyles(theme);
  const [anchors, setAnchors] = React.useState<AnchorType[]>([]);

  const handleOpenPopover = (index: number, value: boolean) => (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchors((prevAnchors) => {
      const anchorsArray = [...prevAnchors];
      anchorsArray[index] = value === false ? null : event.currentTarget;
      return anchorsArray;
    });
  };

  const isUnavailable = (values: number[]) => {
    return values.some((item) => item === -1);
  };

  const getContent = ([positive, neutral, negative]: number[]) => {
    return (
      <div
        style={{
          padding: "24px 24px 8px",
          backgroundColor: "#fff",
        }}
      >
        <LongTooltipTitle
          positive={positive}
          neutral={neutral}
          negative={negative}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      {props.kpis.map((kpi, index: number) => (
        <div className={classes.kpiLine} key={`kpi-${kpi.id}`}>
          {isUnavailable(props.ratings[index]) ? (
            <Typography className={classes.withoutTip}>{kpi.label}</Typography>
          ) : (
            <React.Fragment>
              <Link
                className={clsx(classes.withTip, {
                  [classes.linkHover]: Boolean(anchors[index]),
                  [classes.notLinkHover]: !Boolean(anchors[index]),
                })}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleOpenPopover(index, true)(event);
                }}
              >
                {kpi.label}
              </Link>
              <Popover
                id={`popover-${index}`}
                open={Boolean(anchors[index])}
                onClose={handleOpenPopover(index, false)}
                anchorEl={anchors[index]}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                classes={{
                  paper: classes.popoverDiv,
                }}
              >
                {getContent(props.ratings[index])}
              </Popover>
            </React.Fragment>
          )}
          <div className={classes.rightSide}>
            <TernaryBar ratings={props.ratings[index]} />
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}
