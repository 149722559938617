import React from "react";
import { Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";

import theme from "../theme";
import { StateCtx } from "../contexts";
import { ITarget, ChangeImpact } from "../Ifaces.d";
import TopPopper from "./TopPopper";
import { ActionTypes } from "../reducers";


const styles = (theme: Theme) =>
  createStyles({
    autocompleteRoot: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
      "& .MuiOutlinedInput-root": {
        paddingLeft: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    autocompleteInput: {
      fontSize: "13px",
      lineHeight: "24px",
      color: "#8f979f",
    },
    myNoOptions: {
      backgroundColor: "#fff",
      borderTop: "1px solid #efefef",
      fontSize: "14px",
      lineHeight: 1,
      color: "#8f979f",
    },
  });

const useStyles = makeStyles(styles);

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option: ITarget) => option.label,
});

interface IProps {
  includeFixedOptions?: boolean;
  placeholder?: string;
  autodataBrands?: boolean;
  changeImpact?: ChangeImpact
  onChange?: (params: Array<ITarget>)=>void
}

export function BrandsAutocomplete(props: IProps) {
  const classes = useStyles(theme);
  const { state, dispatch } = React.useContext(StateCtx);

  // const fixedOptions = state.brands.filter((brand: ITarget) => {
  //   let pos = state.brand_selection.findIndex(
  //     (b: ITarget) => b.ahb_id === (state.brand as ITarget).ahb_id,
  //   );
  //   return (
  //     pos === -1 &&
  //     state.brand_selection.length < 10 &&
  //     brand.ahb_id === (state.brand as ITarget).ahb_id
  //   );
  // });

  const [value, setValue] = React.useState<ITarget[]>([
    // ...fixedOptions,
    ...state.brand_selection,
  ]);

  const { enqueueSnackbar } = useSnackbar();

  // React.useEffect(() => {
  //   /* This useEffect is here only to preload the 'value' with the
  //    * 'fixedOptions'. It should not be here if the React.useState of
  //    * 'values, setValues' worked on the first load of the component. But it does not.
  //    */
  //   if (
  //     props.includeFixedOptions &&
  //     state.brand_selection.length === 0 &&
  //     value.length === 0 &&
  //     fixedOptions.length > 0
  //   ) {
  //     setValue([...fixedOptions]);
  //   }
  // }, [value, fixedOptions, state.brand_selection, props.includeFixedOptions]);

  React.useEffect(() => {
    if( props.changeImpact === ChangeImpact.local ){
      if( props.onChange ){
        props.onChange(value)
      }
    }else{
      dispatch({
        type: ActionTypes.SET_BRAND_SELECTION,
        brand_selection: value,
      });
    }
  }, [value, dispatch]);

  const handleChange = (event: any, newValue: (string | ITarget)[] | null) => {
    if (newValue !== null && typeof newValue[0] !== "string") {
      if (newValue.length <= 10) {
        setValue(newValue as ITarget[]);
      } else {
        enqueueSnackbar("There is a limit of 10 brands.", { variant: "info" });
      }
    }
  };

  const getPlaceholder = () => {
    if (state.brand_selection.length < 10)
      return props.placeholder
        ? props.placeholder
        : "Add brands for comparison";
    else return "";
  };

  return (
    <div className={classes.autocompleteRoot}>
      <Autocomplete
        multiple
        id="tags"
        onChange={handleChange}
        options={props.autodataBrands ? state.autodataBrands : state.brands}
        filterOptions={filterOptions}
        getOptionLabel={(option: ITarget) => option.label}
        getOptionSelected={(option: ITarget, value: ITarget) => {
          return option.ahb_id === value.ahb_id;
        }}
        value={value}
        renderTags={(value: ITarget[], getTagProps) =>
          value.map((option: ITarget, index: number) => (
            <Chip
              variant="outlined"
              label={option.label}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={getPlaceholder()}
          />
        )}
        classes={{
          input: classes.autocompleteInput,
          noOptions: classes.myNoOptions,
        }}
        PopperComponent={TopPopper}
        noOptionsText={"No options"}
      />
    </div>
  );
}

export function OneBrandAutocomplete() {
  const classes = useStyles(theme);
  const { state } = React.useContext(StateCtx);
  const [value] = React.useState<ITarget[]>(state.brand ? [state.brand] : []);

  return (
    <div className={classes.autocompleteRoot}>
      <Autocomplete
        id="tags"
        multiple
        onChange={() => {}}
        options={value}
        filterOptions={filterOptions}
        getOptionLabel={(option: ITarget) => option.label}
        value={value}
        renderTags={(value: ITarget[], getTagProps) =>
          value.map((option: ITarget, index: number) => (
            <Chip
              variant="outlined"
              label={option.label}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="" />
        )}
        classes={{
          input: classes.autocompleteInput,
          noOptions: classes.myNoOptions,
        }}
        PopperComponent={TopPopper}
        noOptionsText={"No options"}
        disabled={true}
      />
    </div>
  );
}
