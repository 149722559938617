import React from "react";
import { Container, Link, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import theme from "../../theme";
import { CalendarIcon, DownloadIcon, SmallInfoIcon } from "../../Icons";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: "inline",
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    link: {
      marginRight: 24,
      display: "inline-flex",
      alignItems: "center",
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: "#454c53",
      "&:last-child": {
        marginRight: 0,
      },
    },
    tooltip: {
      width: 240,
      marginTop: 1,
      padding: "10px",
      color: "#000",
      backgroundColor: "#fff",
      borderRadius: 2,
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      fontSize: "15px",
      lineHeight: 1.4,
    },
    arrow: {
      color: "#fff",
    },
    icon: {
      marginLeft: 8,
      display: "inline",
      lineHeight: 1.1,
    },
  });

const useStyles = makeStyles(styles);

export default function DetailsVehicleRatingTab() {
  const classes = useStyles(theme);

  return (
    <Container maxWidth="lg">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography className={classes.header}>
          Key Influencer Identification
          <Tooltip
            arrow
            placement="top"
            title="This is what shows up when I hover the small info icon"
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
          >
            <span className={classes.icon}>
              <SmallInfoIcon />
            </span>
          </Tooltip>
        </Typography>
        <div>
          <Link
            href="#"
            className={classes.link}
            onClick={(ev: React.SyntheticEvent) => ev.preventDefault()}
          >
            <DownloadIcon />
            &nbsp;&nbsp;Download as PDF
          </Link>
        </div>
      </div>
    </Container>
  );
}
