import * as React from "react";

function SvgRatingStarIcon(props) {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 100" transform="scale(1.35)" {...props}>
      <g transform="translate(0,-952.36218)">
        <path stroke="#fff" d="m 31.946105,1032.3814 a 1.9851646,2.0153489 0 0 1 -1.83988,-2.257 l 2.41485,-19.0289 -12.95587,-13.97 a 1.9851646,2.0153489 0 0 1 1.07327,-3.34658 l 18.55219,-3.54116 9.04611,-16.84968 a 1.9851646,2.0153489 0 0 1 3.48812,0 l 9.04611,16.84968 18.59052,3.54116 a 1.9851646,2.0153489 0 0 1 1.07327,3.34658 l -12.95587,13.97 2.41485,19.0289 a 1.9851646,2.0153489 0 0 1 -2.83649,2.0624 l -17.05729,-8.2497 -17.09561,8.2497 a 1.9851646,2.0153489 0 0 1 -0.95828,0.1946 z m 2.56818,-5.4479 14.64243,-7.0045 a 1.9851646,2.0153489 0 0 1 1.68656,0 l 14.64244,7.0045 -2.06988,-16.266 a 1.9851646,2.0153489 0 0 1 0.53664,-1.6733 l 11.11598,-11.94653 -15.94569,-3.03527 a 1.9851646,2.0153489 0 0 1 -1.37991,-1.01176 l -7.78119,-14.43702 -7.74286,14.43702 a 1.9851646,2.0153489 0 0 1 -1.37991,1.01176 l -15.90736,3.03527 11.11599,11.94653 a 1.9851646,2.0153489 0 0 1 0.53663,1.6733 l -2.06987,16.266 z" fill="#fff" fill-opacity="1" marker="none" visibility="visible" display="inline" overflow="visible" />
      </g>
    </svg>
  );
}

export default SvgRatingStarIcon;
