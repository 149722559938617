import { Popper } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

import theme from "../theme";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 16,
      backgroundColor: "#ffffff",
    },
    children: {
      "& .MuiAutocomplete-listbox": {
        padding: 0,
        backgroundColor: "#ffffff",
        "& li": {
          marginTop: 0,
          marginBottom: 0,
          fontSize: "15px",
          lineHeight: "24px",
          "&::hover": {
            backgroundColor: "#f6f7f8",
          },
        },
      },
      "& .MuiPaper-rounded": {
        borderRadius: 0,
      },
    },
  });

const useStyles = makeStyles(styles);

export default function MyPopper(props: any) {
  const classes = useStyles(theme);

  return (
    <Popper
      {...props}
      placement="bottom-start"
      className={classes.root}
      style={{ width: 300 }}
      open={true}
    >
      <div className={classes.children}>{props.children}</div>
    </Popper>
  );
}
