import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

import theme from "../theme";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#000",
      },
    },
  });

const useStyles = makeStyles(styles);

export default function NoDefaultsPage() {
  const classes = useStyles(theme);

  return (
    <Container className={classes.container}>
      <Typography variant="h4">User not initialized yet.</Typography>
      <Typography variant="body1" style={{ marginTop: 16, maxWidth: 528, textAlign: "center" }}>Please, get in touch with our support team at <span style={{fontFamily: "monospace", fontSize: "0.9em", color: "#77f"}}>cockpit@berylls.com</span> and communicate this problem.</Typography>
    </Container>
  );
}