import React from "react";
import { Container, Typography, Paper} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import theme from "../theme";

const styles = (theme: Theme) =>
  createStyles({
    pageRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    paperRoot: {
      padding: 28,
      flexGrow: 1,
      borderRadius: 8,
      backgroundColor: "#fff",
    },
    mainHeader: {
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "32px",
      textTransform: "uppercase",
      marginTop: "70px",
    },
    container: {
      margin: "auto",
      padding: "0px 30px",
    },
  });

const useStyles = makeStyles(styles);

export default function FAQPage() {
  const classes = useStyles(theme);

  React.useEffect(() => {}, []);

  return (
    <div className={classes.pageRoot}>
      <Container className={classes.container}>
        <Typography className={classes.mainHeader}>FAQ</Typography>
        <Paper className={classes.paperRoot} style={{ marginTop: 40 }}>FAQs</Paper>
      </Container>
    </div>
  );
}
