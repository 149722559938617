import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import theme from "../theme";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#000",
      },
    },
  });

const useStyles = makeStyles(styles);

export default function LoadingPage() {
  const classes = useStyles(theme);

  return (
    <Container className={classes.container}>
      <CircularProgress />
    </Container>
  );
}
