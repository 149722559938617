import * as React from "react";

function SvgStarIcon(props) {
  return (
    <svg width={273} height={154} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#000"
        strokeWidth={2}
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path
          d="M24.455 67l-8.23 4.326 1.572-9.163-6.657-6.49 9.2-1.336L24.455 46l4.114 8.337 9.2 1.337-6.657 6.49 1.572 9.162zM60.455 67l-8.23 4.326 1.572-9.163-6.657-6.49 9.2-1.336L60.455 46l4.114 8.337 9.2 1.337-6.657 6.49 1.572 9.162z"
        />
        <path d="M96.455 67l-8.23 4.326 1.572-9.163-6.657-6.49 9.2-1.336L96.455 46l4.114 8.337 9.2 1.337-6.657 6.49 1.572 9.162z" />
      </g>
    </svg>
  );
}

export default SvgStarIcon;
