import * as React from "react";

function SvgUserDiscussionsIcon(props) {
  return (
    <svg width={120} height={120} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#000"
        strokeWidth={2}
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path d="M92 19c8.837 0 16 7.163 16 16 0 6.326-3.671 11.795-9 14.391V61l-9.231-10H56c-8.837 0-16-7.163-16-16s7.163-16 16-16h36zM28 63c-8.837 0-16 7.163-16 16 0 6.326 3.671 11.795 9 14.391V105l9.231-10H64c8.837 0 16-7.163 16-16s-7.163-16-16-16H28z" />
      </g>
    </svg>
  );
}

export default SvgUserDiscussionsIcon;
