import { Cell, Pie, PieChart } from "recharts";

const RADIAN = Math.PI / 180;

const renderCustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const positive = "#70d57d";
const neutral = "#b2b7bc";
const negative = "#ee754f";

interface IProps {
  positive: number;
  neutral: number;
  negative: number;
}

export default function UserDiscussionChart(props: IProps) {
  return (
    <PieChart width={350} height={350}>
      <Pie
        dataKey="value"
        data={[
          { value: props.positive },
          { value: props.neutral },
          { value: props.negative },
        ]}
        innerRadius={62}
        outerRadius={111}
        fill="#8884d8"
        labelLine={false}
        label={renderCustomLabel}
      >
        <Cell fill={positive} />
        <Cell fill={neutral} />
        <Cell fill={negative} />
      </Pie>
    </PieChart>
  );
}
