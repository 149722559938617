import { Badge, Grid, Icon, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import theme from "../theme";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 48,
      margin: "8px 0 0",
      flexWrap: "nowrap",
      overflowX: "hidden",
      width: 184,
      borderRadius: 3,
      userSelect: "none"
    },
    icon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.text.secondary,
    },
    buttonDiv: {
      width: 56,
      height: 48,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 3,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#454c53",
      },
    },
    rootFull: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#454c53",
      },
    },
    fullDiv: {
      height: 48,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 3,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#454c53",
      },
    },
    active: {
      backgroundColor: "#454c53",
    },
    label: {
      marginTop: 1,
      paddingTop: 0,
      fontFamily: "Open Sans",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      textTransform: "uppercase",
    },
    badge: {
      color: "#fff",
      backgroundColor: "#2563EB",
      fontSize: "0.5em"
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  label: string;
  for: string;
  badgeContent?: number | string;
  active: boolean;
  showFull: boolean;
  icon: JSX.Element;
  onClick: (choice: string) => void;
}

export default function NavigationItem(props: IProps) {
  const classes = useStyles(theme);

  const renderIcon = () => {
    const def: JSX.Element = (
      <Icon classes={{ root: classes.icon }}>{props.icon}</Icon>
    );
    if (props.badgeContent) {
      return (
        <Badge
          badgeContent={props.badgeContent}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          classes={{
            badge: classes.badge,
          }}
        >
          {def}
        </Badge>
      );
    }

    return def;
  };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      onClick={() => props.onClick(props.for)}
      className={clsx(classes.root, {
        [classes.rootFull]: props.showFull,
        [classes.active]: props.showFull && props.active,
      })}
    >
      <div
        className={clsx(classes.buttonDiv, {
          [classes.fullDiv]: props.showFull,
          [classes.active]: props.active,
        })}
      >
        {renderIcon()}
      </div>
      <Grid item zeroMinWidth style={{ paddingLeft: 7 }}>
        <Typography className={classes.label} color="textSecondary">
          {props.label}
        </Typography>
      </Grid>
    </Grid>
  );
}
