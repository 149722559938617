import React from "react";
import { useRouteMatch } from "react-router";
import {
  Container,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import clsx from "clsx";

import theme from "../../theme";
import { StateCtx } from "../../contexts";
import { ActionTypes } from "../../reducers";
import { IAHBRatings, ITarget, IModel, TargetType } from "../../Ifaces.d";
import { DownloadIcon, SmallInfoIcon } from "../../Icons";
import DateSelector from "../DateSelector";
import * as API from "../../API";

// --------------------------------------------------------------------
const YellowLine = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      width: 204,
      borderRadius: 3,
    },
    colorPrimary: {
      backgroundColor: "#dcdee1",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#fdcc29",
    },
  }),
)(LinearProgress);

const DarkBlueLine = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      width: 204,
      borderRadius: 3,
    },
    colorPrimary: {
      backgroundColor: "#dcdee1",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1c86ed",
    },
  }),
)(LinearProgress);

const LightBlueLine = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      width: 204,
      borderRadius: 3,
    },
    colorPrimary: {
      backgroundColor: "#dcdee1",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#75b4f2",
    },
  }),
)(LinearProgress);

interface ILineItemProps {
  classes: ReturnType<typeof useStyles>;
  color: string;
  value: number;
  bold: boolean;
}

function LineItem(props: ILineItemProps) {
  const { classes } = props;

  const renderLine = () => {
    const value = props.value > 100 ? props.value - 100 : props.value;
    if (props.color === "yellow") {
      return <YellowLine variant="determinate" value={value} />;
    } else if (props.color === "darkblue") {
      return <DarkBlueLine variant="determinate" value={value} />;
    } else {
      return <LightBlueLine variant="determinate" value={value} />;
    }
  };

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={clsx("value")}
    >
      {renderLine()}
      <Typography {...(props.bold && { className: classes.bold })}>
        {props.value > 100
          ? ((props.value - 100) / 100).toFixed(1)
          : (props.value / 100).toFixed(1)}
      </Typography>
    </Grid>
  );
}

// --------------------------------------------------------------------
// KPIsColumn component.

interface IColumnProps {
  classes: ReturnType<typeof useStyles>;
}

function KPIsColumn(props: IColumnProps) {
  const { classes } = props;
  return (
    <div className={clsx(classes.column, classes.kpisColumn)}>
      <Typography className={clsx("col1cel1", classes.bold)}>
        Overall Rating
      </Typography>
      <div className={classes.divider} style={{ marginBottom: 15 }} />
      <Typography>Brand</Typography>
      <Typography>Value for money</Typography>
      <Typography>Quality & reliability</Typography>
      <Typography>Customer service</Typography>
      <Typography>Safety</Typography>
      <Typography>Comfort</Typography>
      <div className={classes.divider} style={{ margin: "13px 0 15px" }} />
      <Typography>Configuration</Typography>
      <Typography>Exterior</Typography>
      <Typography>Interior</Typography>
      <Typography>Space</Typography>
      <Typography>Performance</Typography>
      <Typography>Maneuverability</Typography>
      <div className={classes.divider} style={{ margin: "13px 0 15px" }} />
      <Typography>Charging</Typography>
      <Typography>Ecology</Typography>
      <Typography>Fuel / power efficiency</Typography>
    </div>
  );
}

interface ITargetColumnProps {
  classes: ReturnType<typeof useStyles>;
  target: TargetType;
  item: ITarget | IModel;
  ratings: number[];
}

function ItemColumn(props: ITargetColumnProps) {
  const { classes } = props;

  if (!props.ratings || props.ratings.length !== 16) return <span />;

  return (
    <div className={classes.column}>
      <div className={classes.itemTopCell}>
        <div className={classes.itemTopCellHead}>
          <span>{props.item.label}</span>
          {props.target === TargetType.brands && (
            <img
              src={`/assets/brands/${props.item.ahb_id}.svg`}
              alt={props.item.label}
              height="20"
            />
          )}
        </div>
      </div>
      <LineItem
        key={`${props.item.ahb_id}-kpi${0}`}
        classes={classes}
        color={props.ratings[0] > 100 ? "yellow" : "darkblue"}
        value={props.ratings[0]}
        bold={true}
      />
      <div className={classes.divider} style={{ margin: "14px 0 15px" }} />
      {props.ratings.slice(1, 7).map((num: number, index: number) => (
        <LineItem
          key={`${props.item.ahb_id}-kpi${index}`}
          classes={classes}
          color={num > 100 ? "yellow" : "lightblue"}
          value={num}
          bold={false}
        />
      ))}
      <div className={classes.divider} style={{ margin: "13px 0 15px" }} />
      {props.ratings.slice(7, 13).map((num: number, index: number) => (
        <LineItem
          key={`${props.item.ahb_id}-kpi${index + 7}`}
          classes={classes}
          color={num > 100 ? "yellow" : "lightblue"}
          value={num}
          bold={false}
        />
      ))}
      <div className={classes.divider} style={{ margin: "13px 0 15px" }} />
      {props.ratings.slice(13, 16).map((num: number, index: number) => (
        <LineItem
          key={`${props.item.ahb_id}-kpi${index + 13}`}
          classes={classes}
          color={num > 100 ? "yellow" : "lightblue"}
          value={num}
          bold={false}
        />
      ))}
    </div>
  );
}

// --------------------------------------------------------------------
// Exported component (VehicleRatingTab).

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: "block",
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
    },
    secondHeader: {
      marginTop: 19,
      marginBottom: 21,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    links: {
      "& > *": {
        marginRight: 24,
        display: "inline-flex",
        alignItems: "center",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
        color: "#454c53",
      },
      "& > *:last-child": {
        marginRight: 0,
      },
    },
    tooltip: {
      width: 240,
      marginTop: 1,
      padding: "10px",
      color: "#000",
      backgroundColor: "#fff",
      borderRadius: 2,
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      fontSize: "15px",
      lineHeight: 1.4,
    },
    arrow: {
      color: "#fff",
    },
    icon: {
      marginLeft: 8,
      display: "inline",
      lineHeight: 1.1,
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    paper: {
      marginBottom: 5,
      backgroundColor: "white",
      borderRadius: 8,
      padding: "25px 36px 20px",
    },
    column: {
      minWidth: 309,
      maxWidth: 309,
      "& .MuiTypography-body1": {
        fontFamily: "Open Sans",
        fontSize: "12px",
        lineHeight: "20px",
      },
      "& .value": {
        padding: "6px 44px 6px 28px",
      },
    },
    kpisColumn: {
      minWidth: 142,
      "& .MuiTypography-body1": {
        padding: "6px 0",
      },
      "& .col1cel1": {
        paddingTop: 50,
        height: 89,
      },
    },
    itemTopCell: {
      height: 43,
      padding: "7px 44px 16px 28px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    itemTopCellHead: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      overflow: "hidden",
      fontFamily: "Rajdhani",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "black",
    },
    divider: {
      height: 1,
      backgroundColor: "#dcdee1",
    },
    kpiText: {},
    bold: {
      fontWeight: 700,
    },
    dropDiv: {
      width: "100%",
    },
    legendDiv: {
      height: 25,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "& .text": {
        fontFamily: "Open Sans",
        fontStyle: "italic",
        fontSize: "12px",
      },
      "& .bestInClass": {
        width: 20,
        height: 6,
        backgroundColor: "#fdcc29",
        marginRight: 8,
      },
    },
  });

const useStyles = makeStyles(styles);

export default function DetailsVehicleRatingTab() {
  const classes = useStyles(theme);
  const { state, dispatch } = React.useContext(StateCtx);
  const [ratings, setRatings] = React.useState<any[]>([]);
  const [selection, setSelection] = React.useState<(ITarget | IModel)[]>([]);
  const { path } = useRouteMatch();

  const tsel = React.useMemo(() => {
    if (state.target === TargetType.brands) return state.brand_selection;
    else return state.model_selection;
  }, [state.target, state.brand_selection, state.model_selection]);

  const fetchRatings = React.useCallback(
    async (ahb_id: string): Promise<IAHBRatings | undefined> => {
      try {
        const ratings = await API.fetchRatings(
          state.region.code.toUpperCase(),
          ahb_id,
        );
        return ratings;
      } catch (error) {
        console.log(error);
      }
    },
    [state.region],
  );

  const getElementRatings = React.useCallback(
    async (ahb_id: string): Promise<number[]> => {
      const ahb_ratings_obj = await fetchRatings(ahb_id);
      if (ahb_ratings_obj) {
        return ahb_ratings_obj.ratings;
      } else {
        return [];
      }
    },
    [fetchRatings],
  );

  React.useEffect(() => {
    dispatch({ type: ActionTypes.SET_DETAILS_PATH, details_path: path });
  }, [path, dispatch]);

  React.useEffect(() => {
    if (tsel.length !== selection.length) {
      const _selection = [...selection];

      // New items found in state.selection.
      const newSelection = [];
      for (let i = 0; i < tsel.length; i++) {
        const pos = selection.findIndex(
          (item) => item.ahb_id === tsel[i].ahb_id,
        );
        if (pos === -1) {
          newSelection.push(tsel[i]);
        }
      }
      // Delete those items that no longer exist in the state.selection.
      const delSelection = [];
      for (let i = 0; i < _selection.length; i++) {
        const pos = tsel.findIndex(
          (item) => item.ahb_id === _selection[i].ahb_id,
        );
        if (pos === -1) {
          delSelection.push(_selection[i]);
        }
      }
      if (delSelection.length) {
        for (let i = 0; i < delSelection.length; i++) {
          _selection.splice(_selection.indexOf(delSelection[i]), 1);
        }
      }
      setSelection([..._selection, ...newSelection]);
    }
  }, [tsel, selection, setSelection]);

  React.useEffect(() => {
    const fetchData = async () => {
      const _ratings = [];
      for (let i = 0; i < selection.length; i++) {
        const fetched_ratings = await getElementRatings(selection[i].ahb_id);
        _ratings.push(fetched_ratings);
      }
      if (selection.length > 1) {
        // Find out what is the 'best of class' for each KPI.
        for (let kpi = 0; kpi < 16; kpi++) {
          // Extract the kpi values corresponding to the KPI to be compared.
          const kpiVals: number[] = _ratings.map((item) => item[kpi]);
          const max = Math.max(...kpiVals);
          const is_unique = kpiVals.indexOf(max) === kpiVals.lastIndexOf(max);
          if (is_unique) {
            // If is the best of class, we add 100 to the value.
            // Then when we have to render the value, if it's > 100,
            // we first substract 100 and then we highlight the bar in Yellow.
            const pos = kpiVals.indexOf(max);
            kpiVals[pos] += 100;
            // Update the _ratings.
            for (var j = 0; j < _ratings.length; j++) {
              _ratings[j][kpi] = kpiVals[j];
            }
          }
        }
      }
      setRatings(_ratings);
    };
    fetchData();
  }, [selection, setRatings, getElementRatings]);

  const reorder = (
    list: (ITarget | IModel)[],
    startIndex: number,
    endIndex: number,
  ) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const _selection = reorder(
      selection,
      result.source.index,
      result.destination.index,
    );
    setSelection(_selection);
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    width: "100%",
    backgroundColor: isDraggingOver ? "#ddedfd" : "white",
    display: "flex",
    marginRight: 142,
    padding: 0,
    paddingBottom: 16,
    overflow: "auto",
  });

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ) => ({
    backgroundColor: isDragging ? "#fdcc29" : "inherit",
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container maxWidth="lg">
        <Typography className={classes.header}>
          How Do Customers Rate your Vehicles?
          <Tooltip
            arrow
            placement="top"
            title="This is what shows up when I hover the small info icon"
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
          >
            <span className={classes.icon}>
              <SmallInfoIcon />
            </span>
          </Tooltip>
        </Typography>
        <div className={classes.secondHeader}>
          <div className={classes.legendDiv}>
            <span className={clsx("bestInClass")} />
            <Typography className={clsx("text")}>Best in class</Typography>
          </div>
          {/* <div className={classes.links}>
            <DateSelector hidden />
            <Link
              href="#"
              onClick={(ev: React.SyntheticEvent) => ev.preventDefault()}
            >
              <DownloadIcon />
              &nbsp;&nbsp;Download as PDF
            </Link>
          </div> */}
        </div>

        <div style={{ width: "100%", overflow: "hidden" }}>
          <Paper className={clsx(classes.flexRow, classes.paper)}>
            <KPIsColumn classes={classes} key={`kpi-names`} />
            <div className={clsx(classes.flexRow, classes.dropDiv)}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(
                  provided: DroppableProvided,
                  snapshot: DroppableStateSnapshot,
                ) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {selection.map((item: ITarget | IModel, idx: number) => (
                      <Draggable
                        key={`draggable-${item.ahb_id}`}
                        draggableId={item.ahb_id}
                        index={idx}
                      >
                        {(
                          provided: DraggableProvided,
                          snapshot: DraggableStateSnapshot,
                        ) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            <ItemColumn
                              classes={classes}
                              target={state.target}
                              item={item}
                              ratings={ratings[idx]}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </Paper>
        </div>
      </Container>
    </DragDropContext>
  );
}
