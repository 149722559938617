import React from "react";
import {
  Link as RouterLink,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";

import {
  Container,
  LinearProgress,
  Link,
  Paper,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import clsx from "clsx";
import { TagCloud } from "react-tagcloud";

import theme from "../../theme";
import { ActionTypes } from "../../reducers";
import {
  IAHBS2Cloud,
  ICloudItem,
  IModel,
  ITarget,
  TargetType,
} from "../../Ifaces.d";
import { StateCtx } from "../../contexts";
import {
  BarChartsIcon,
  CloudIcon,
  DownloadIcon,
  SmallInfoIcon,
} from "../../Icons";
import DateSelector from "../DateSelector";
import ShortTooltipContent from "./ShortTooltipContent";
import * as API from "../../API";

const defaultCloud: ICloudItem[] = [
  {
    term_id: "key",
    label: "Key",
    percentage: 21.681661750282522,
    weight: 2,
  },
  {
    term_id: "trunk_lid",
    label: "Trunk lid",
    percentage: 10.067115063812127,
    weight: 1,
  },
  {
    term_id: "cable",
    label: "Cable",
    percentage: 14.322722441555037,
    weight: 1,
  },
  {
    term_id: "charging_stations",
    label: "Charging stations",
    percentage: 91.0895896896313,
    weight: 5,
  },
  {
    term_id: "light",
    label: "Light",
    percentage: 98.91964863483858,
    weight: 5,
  },
  {
    term_id: "brake",
    label: "Brake",
    percentage: 23.918997542152965,
    weight: 2,
  },
  {
    term_id: "charging",
    label: "Charging",
    percentage: 79.77115685305772,
    weight: 4,
  },
  {
    term_id: "range",
    label: "Range",
    percentage: 29.194417280414054,
    weight: 2,
  },
  {
    term_id: "battery_performance",
    label: "Battery performance",
    percentage: 16.879437485441827,
    weight: 1,
  },
  {
    term_id: "seats",
    label: "Seats",
    percentage: 36.93251268744575,
    weight: 2,
  },
];

// --------------------------------------------------------------------
// CloudCart.

interface ICloudCardProps {
  classes: ReturnType<typeof useStyles>;
  target: TargetType;
  item: ITarget | IModel;
  cloud: ICloudItem[];
}

function CloudCard(props: ICloudCardProps) {
  const { classes, cloud } = props;
  const [anchors, setAnchors] = React.useState<AnchorType[]>([]);

  const handleOpenPopover = (tag: any, value: boolean) => (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    const pos = cloud.findIndex(
      (item: ICloudItem) => tag.term_id === item.term_id,
    );
    if (pos !== -1) {
      setAnchors((prevAnchors) => {
        const anchorsArray = [...prevAnchors];
        anchorsArray[pos] = value === false ? null : event.currentTarget;
        return anchorsArray;
      });
    }
  };

  const getTags = () => {
    const tags: any = [];
    cloud.forEach((item: ICloudItem) => {
      tags.push({
        value: item.label,
        count: item.weight,
        term_id: item.term_id,
      });
    });
    return tags;
  };

  const getContent = (term_id: string) => (
    <div style={{ padding: "24px 24px 8px", backgroundColor: "#fff" }}>
      <ShortTooltipContent item={props.item} termId={term_id} />
    </div>
  );

  const customRenderer = (tag: any, size: number) => {
    const fontSize = `${size}px`;
    const pos = cloud.findIndex(
      (item: ICloudItem) => tag.term_id === item.term_id,
    );

    return (
      <React.Fragment key={tag.value}>
        <Link
          style={{ display: "inline-block", color: "#000" }}
          className={clsx(classes.cloudWord, {
            [classes.cloudWordHover]: Boolean(anchors[pos]),
            [classes.notCloudWordHover]: !Boolean(anchors[pos]),
          })}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            handleOpenPopover(tag, true)(event);
          }}
        >
          <span
            style={{
              margin: "3px",
              padding: "3px",
              display: "inline-block",
              fontSize: fontSize,
              fontWeight: 400,
              cursor: "pointer",
            }}
          >
            {tag.value}
          </span>
        </Link>
        <Popover
          id={`popover-${tag.term_id}`}
          open={Boolean(anchors[pos])}
          onClose={handleOpenPopover(tag, false)}
          anchorEl={anchors[pos]}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          classes={{
            paper: classes.popoverDiv,
          }}
        >
          {getContent(tag.term_id)}
        </Popover>
      </React.Fragment>
    );
  };

  return (
    <div className={classes.cloudCardRoot}>
      <Paper>
        <div className={clsx("header")}>
          <span>{props.item.label}</span>
          {props.target === TargetType.brands && (
            <img
              src={`/assets/brands/${props.item.ahb_id}.svg`}
              alt={props.item.label}
              height="20"
            />
          )}
        </div>
        <div className={clsx("divider")} />
        <div className={classes.tagCloud}>
          <TagCloud
            minSize={12}
            maxSize={32}
            tags={getTags()}
            renderer={customRenderer}
            shuffle={false}
          />
        </div>
      </Paper>
    </div>
  );
}

// --------------------------------------------------------------------
// OverviewContent.

interface IOverviewProps {
  classes: ReturnType<typeof useStyles>;
  selection: (ITarget | IModel)[];
  clouds: ICloudItem[][];
}

function OverviewContent(props: IOverviewProps) {
  const { classes } = props;
  const { state, dispatch } = React.useContext(StateCtx);

  const { path } = useRouteMatch();

  React.useEffect(() => {
    dispatch({ type: ActionTypes.SET_DETAILS_PATH, details_path: path });
  }, [path, dispatch]);

  if (
    !props.clouds ||
    !props.clouds.length ||
    props.clouds.length !== props.selection.length
  ) {
    return <span />;
  }

  return (
    <div className={classes.overviewDiv}>
      {props.selection.map((item: ITarget | IModel, idx: number) => (
        <div key={`chart-card-${item.ahb_id}`}>
          <CloudCard
            classes={classes}
            target={state.target}
            item={item}
            cloud={props.clouds[idx]}
          />
        </div>
      ))}
    </div>
  );
}

// --------------------------------------------------------------------
// ColumnCard.

const BlueLine = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 3,
    },
    colorPrimary: {
      backgroundColor: "#dcdee1",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1c86ed",
    },
  }),
)(LinearProgress);

interface IColumnCardProps {
  classes: ReturnType<typeof useStyles>;
  target: TargetType;
  item: ITarget | IModel;
  cloud: ICloudItem[];
}

type AnchorType = HTMLElement | null;

function ColumnCard(props: IColumnCardProps) {
  const { classes, cloud } = props;
  const [anchors, setAnchors] = React.useState<AnchorType[]>([]);

  const handleOpenPopover = (index: number, value: boolean) => (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchors((prevAnchors) => {
      const anchorsArray = [...prevAnchors];
      anchorsArray[index] = value === false ? null : event.currentTarget;
      return anchorsArray;
    });
  };

  const getContent = (term_id: string) => (
    <div style={{ padding: "24px 24px 8px", backgroundColor: "#fff" }}>
      <ShortTooltipContent item={props.item} termId={term_id} />
    </div>
  );

  if (!cloud || !cloud.length) return <span />;

  return (
    <div className={classes.columnCardRoot}>
      <Paper>
        <div className={clsx("header")}>
          <span>{props.item.label}</span>
          {props.target === TargetType.brands && (
            <img
              src={`/assets/brands/${props.item.ahb_id}.svg`}
              alt={props.item.label}
              height="20"
            />
          )}
        </div>
        <div className={clsx("divider")} />
        <div style={{ marginTop: 27 }} className={classes.columnCardContent}>
          {cloud
            .sort((a: ICloudItem, b: ICloudItem) => b.percentage - a.percentage)
            .map((item: ICloudItem, index: number) => (
              <div key={`term-${index}`} style={{ margin: "19px 0" }}>
                <Link
                  className={clsx("title", {
                    [classes.linkHover]: Boolean(anchors[index]),
                    [classes.notLinkHover]: !Boolean(anchors[index]),
                  })}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    handleOpenPopover(index, true)(event);
                  }}
                >
                  {item.label}
                </Link>
                <Popover
                  key={`popover-s2check-${item.term_id}-${index}`}
                  id={`id-popover-s2check-${item.term_id}-${index}`}
                  open={Boolean(anchors[index])}
                  onClose={handleOpenPopover(index, false)}
                  anchorEl={anchors[index]}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  classes={{
                    paper: classes.popoverDiv,
                  }}
                >
                  {getContent(item.term_id)}
                </Popover>

                <Typography className={clsx("text")}>
                  {`${item.percentage.toFixed(1)}%`}
                </Typography>
                <BlueLine variant="determinate" value={item.percentage} />
              </div>
            ))}
        </div>
      </Paper>
    </div>
  );
}

// --------------------------------------------------------------------
// DetailsContent.

interface IDetailsProps {
  classes: ReturnType<typeof useStyles>;
  target: TargetType;
  selection: (ITarget | IModel)[];
  clouds: ICloudItem[][];
}

function DetailsContent(props: IDetailsProps) {
  const { classes } = props;
  const { dispatch } = React.useContext(StateCtx);
  const [leftShadow, setLeftShadow] = React.useState(false);
  const [rightShadow, setRightShadow] = React.useState(false);
  const { path } = useRouteMatch();

  React.useEffect(() => {
    function computeOnScroll() {
      if (elem) {
        if (elem.scrollWidth > elem.clientWidth + elem.scrollLeft) {
          setRightShadow(true);
        } else {
          setRightShadow(false);
        }
        if (elem.scrollLeft === 0) {
          setLeftShadow(false);
        } else {
          setLeftShadow(true);
        }
      }
    }

    const elem = document.getElementById("details");
    if (elem !== undefined) {
      elem?.addEventListener("scroll", computeOnScroll);
      computeOnScroll();
    }
  }, [setLeftShadow, setRightShadow, props.selection]);

  React.useEffect(() => {
    dispatch({ type: ActionTypes.SET_DETAILS_PATH, details_path: path });
  }, [path, dispatch]);

  return (
    <React.Fragment>
      <div
        id="details"
        className={
          rightShadow && leftShadow
            ? classes.detailsDivBothShadows
            : rightShadow
            ? classes.detailsDivRightShadow
            : leftShadow
            ? classes.detailsDivLeftShadow
            : classes.detailsDiv
        }
      >
        {props.selection.map((item: ITarget | IModel, idx: number) => (
          <div key={`column-${item.ahb_id}`}>
            <ColumnCard
              classes={classes}
              target={props.target}
              item={item}
              cloud={props.clouds[idx]}
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

// --------------------------------------------------------------------
// S2CheckTab styles and component.

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: "inline",
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    links: {
      "& > *": {
        marginRight: 24,
        display: "inline-flex",
        alignItems: "center",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
        color: "#454c53",
      },
      "& > *:last-child": {
        marginRight: 0,
      },
    },
    tooltip: {
      width: 240,
      marginTop: 1,
      padding: "10px",
      color: "#000",
      backgroundColor: "#fff",
      borderRadius: 2,
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      fontSize: "15px",
      lineHeight: 1.4,
    },
    arrow: {
      color: "#fff",
    },
    icon: {
      marginLeft: 8,
      display: "inline",
      lineHeight: 1.1,
    },
    secondHeader: {
      marginTop: 19,
      marginBottom: 21,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    legendDiv: {
      height: 25,
    },
    content: {
      width: "100%",
      minHeight: 462,
      position: "relative",
    },
    overviewDiv: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "376px 376px 376px",
      gridGap: 36,
      padding: 0,
      paddingBottom: 25,
    },

    detailsDiv: {
      width: "100%",
      height: 885,
      display: "flex",
      overflow: "auto",
      "& > DIV": {
        marginRight: 36,
      },
      "& > DIV:last-child": {
        marginRight: 0,
      },
    },

    detailsDivLeftShadow: {
      width: "100%",
      height: 885,
      display: "flex",
      overflow: "auto",
      "& > DIV": {
        marginRight: 36,
      },
      "& > DIV:last-child": {
        marginRight: 0,
      },
      "&::before": {
        position: "absolute",
        boxShadow: "-10px 0 10px -10px #dedede inset",
        content: '""',
        height: 861,
        width: 20,
        left: -20,
        top: 0,
      },
    },

    detailsDivRightShadow: {
      width: "100%",
      height: 885,
      display: "flex",
      overflow: "auto",
      "& > DIV": {
        marginRight: 36,
      },
      "& > DIV:last-child": {
        marginRight: 0,
      },
      "&::after": {
        position: "absolute",
        boxShadow: "10px 0 10px -10px #dedede inset",
        content: '""',
        height: 861,
        width: 20,
        right: -20,
        top: 0,
      },
    },

    detailsDivBothShadows: {
      width: "100%",
      height: 885,
      display: "flex",
      overflow: "auto",

      "&::before": {
        position: "absolute",
        boxShadow: "-10px 0 10px -10px #dedede inset",
        content: '""',
        height: 861,
        width: 20,
        left: -20,
        top: 0,
      },
      "&::after": {
        position: "absolute",
        boxShadow: "10px 0 10px -10px #dedede inset",
        content: '""',
        height: 861,
        width: 20,
        right: -20,
        top: 0,
      },

      "& > DIV": {
        marginRight: 36,
      },
      "& > DIV:last-child": {
        marginRight: 0,
      },
    },

    // CloudCard styles.
    cloudCardRoot: {
      display: "inline-block",

      "& .MuiPaper-root": {
        width: 376,
        height: 437,
        backgroundColor: "white",
        padding: "25px 36px",
      },
      "& .header": {
        marginTop: 8,
        marginBottom: 8,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        overflow: "hidden",
        fontFamily: "Rajdhani",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color: "black",
      },
      "& .divider": {
        height: 1,
        backgroundColor: "#dcdee1",
      },

      "& SVG text": {
        fontFamily: "Open Sans",
      },
    },

    // ColumnCard styles.
    columnCardRoot: {
      "& .MuiPaper-root": {
        width: 376,
        height: 861,
        backgroundColor: "white",
        padding: "25px 36px",
      },
      "& .header": {
        margin: "8px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        overflow: "hidden",
        fontFamily: "Rajdhani",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color: "black",
      },
      "& .divider": {
        height: 1,
        backgroundColor: "#dcdee1",
      },
    },

    columnCardContent: {
      "& .MuiTypography-body1": {
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontWeight: 400,
      },
      "& .title": {
        fontFamily: "Open Sans",
        fontSize: "12px",
        display: "inline",
        fontWeight: 700,
        lineHeight: "16px",
        textDecoration: "underline",
        cursor: "pointer",
        "& .title:hover": {
          textDecoration: "none",
        },
      },
      "& .text": {
        lineHeight: "20px",
        letterSpacing: "0.3px",
      },
      "& .bold": {
        fontWeight: 700,
      },
      "& .MuiLinearProgress-root": {
        margin: "4px 0",
      },
    },

    tagCloud: {
      width: 304,
      height: 326,
      marginTop: 16,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .tag-cloud": {
        textAlign: "center",
      },
      "& .tag-cloud > SPAN": {
        padding: "4px 8px",
      },
    },

    popoverDiv: {
      marginTop: -8,
    },
    linkHover: {
      textDecoration: "none",
      color: "#1c86ed",
    },
    notLinkHover: {
      textDecoration: "underline",
      color: "inherit",
    },
    cloudWord: {
      "&:hover > SPAN": {
        textDecoration: "underline",
      },
    },
    cloudWordHover: {
      backgroundColor: "#ddedfd",
      "& > SPAN": {
        textDecoration: "underline",
      },
    },
    notCloudWordHover: {
      backgroundColor: "inherit",
      "& > SPAN": {
        textDecoration: "none",
      },
    },
  });

const useStyles = makeStyles(styles);

interface IPath2View {
  path: string;
  view: string;
}
const path2view: IPath2View[] = [
  { path: "/details/s2-check/details", view: "details" },
  { path: "/details/s2-check", view: "overview" },
];

export default function S2CheckTab() {
  const classes = useStyles(theme);
  const { state } = React.useContext(StateCtx);
  const [clouds, setClouds] = React.useState<ICloudItem[][]>([]);
  const [selection, setSelection] = React.useState<(ITarget | IModel)[]>([]);

  const tsel = React.useMemo(() => {
    if (state.target === TargetType.brands) return state.brand_selection;
    else return state.model_selection;
  }, [state.target, state.brand_selection, state.model_selection]);

  const view = React.useMemo(() => {
    const elem: IPath2View | undefined = path2view.find((item: IPath2View) =>
      state.details_path.startsWith(item.path),
    );
    if (elem !== undefined) {
      return elem.view;
    } else {
      return "overview";
    }
  }, [state.details_path]);

  const fetchS2Clouds = React.useCallback(
    async (
      ahb_id: string,
      targetType: TargetType,
    ): Promise<IAHBS2Cloud | undefined> => {
      try {
        const s2Clouds = await API.fetchS2Clouds(
          ahb_id,
          targetType,
          state.region.code.toUpperCase(),
        );
        return s2Clouds;
      } catch (error) {
        console.log(error);
      }
    },
    [state.region],
  );

  const getElementData = React.useCallback(
    async (ahb_id: string) => {
      const ahb_sov = await fetchS2Clouds(ahb_id, state.target);
      if (ahb_sov) {
        return ahb_sov.cloud;
      } else {
        return defaultCloud;
      }
    },
    [fetchS2Clouds, state.target],
  );

  React.useEffect(() => {
    setSelection([]); // Reset selection.
  }, [state.target]);

  React.useEffect(() => {
    if (tsel.length !== selection.length) {
      const _selection = [...selection];

      // New items found in tsel.
      const newSelection = [];
      for (let i = 0; i < tsel.length; i++) {
        const pos = selection.findIndex(
          (item) => item.ahb_id === tsel[i].ahb_id,
        );
        if (pos === -1) {
          newSelection.push(tsel[i]);
        }
      }
      // Delete those items that no longer exist in the tsel.
      const delSelection = [];
      for (let i = 0; i < _selection.length; i++) {
        const pos = tsel.findIndex(
          (item) => item.ahb_id === _selection[i].ahb_id,
        );
        if (pos === -1) {
          delSelection.push(_selection[i]);
        }
      }
      if (delSelection.length) {
        for (let i = 0; i < delSelection.length; i++) {
          _selection.splice(_selection.indexOf(delSelection[i]), 1);
        }
      }
      setSelection([..._selection, ...newSelection]);
    }
  }, [tsel, selection, setSelection]);

  React.useEffect(() => {
    const fetchData = async () => {
      const _clouds = [];

      for (let i = 0; i < selection.length; i++) {
        const s2Cloud = await getElementData(selection[i].ahb_id);
        _clouds.push(s2Cloud);
      }
      setClouds(_clouds);
    };
    fetchData();
  }, [selection, setClouds, getElementData]);

  return (
    <Container maxWidth="lg">
      <Typography className={classes.header}>
        Concerns and security issues users talk about
        <Tooltip
          arrow
          placement="top"
          title="This is what shows up when I hover the small info icon"
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
        >
          <span className={classes.icon}>
            <SmallInfoIcon />
          </span>
        </Tooltip>
      </Typography>

      <div className={classes.secondHeader}>
        <div className={classes.legendDiv} />
        <div className={classes.links}>
          <Link
            href="#"
            component={RouterLink}
            to={`/details/s2-check${view === "overview" ? "/details" : ""}`}
          >
            {view === "details" ? <CloudIcon /> : <BarChartsIcon />}
            &nbsp;&nbsp;{view === "details" ? "Word cloud" : "Detail"} view
          </Link>

          <DateSelector hidden />

          <Link
            href="#"
            onClick={(ev: React.SyntheticEvent) => ev.preventDefault()}
          >
            <DownloadIcon />
            &nbsp;&nbsp;Download as PDF
          </Link>
        </div>
      </div>

      <div className={classes.content}>
        <Switch>
          <Route exact path="/details/s2-check/details">
            <DetailsContent
              classes={classes}
              target={state.target}
              selection={selection}
              clouds={clouds}
            />
          </Route>
          <Route path="/details/s2-check">
            <OverviewContent
              classes={classes}
              selection={selection}
              clouds={clouds}
            />
          </Route>
        </Switch>
      </div>
    </Container>
  );
}
