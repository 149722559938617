import * as React from "react";

function SvgBarChartsIcon(props) {
  return (
    <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#454C53" fill="none" fillRule="evenodd">
        <path d="M2 15h8M2 10h12M2 5h16" />
      </g>
    </svg>
  );
}

export default SvgBarChartsIcon;
