import React from "react";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { StateCtx } from "./contexts";
import { AuthProvider } from "./AuthProvider";
import { Actions, IState, initialState, reducer } from "./reducers";

import Main from "./Main";

export default function App() {
  const [state, dispatch]: [
    IState,
    (action: Actions) => void,
  ] = React.useReducer(reducer, initialState);

  return (
    <AuthProvider>
      <StateCtx.Provider value={{ state, dispatch }}>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Main />
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </StateCtx.Provider>
    </AuthProvider>
  );
}
