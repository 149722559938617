import React from "react";
import { Link, Popover } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

import theme from "../../theme";
import { ITarget, IModel } from "../../Ifaces";

const styles = (theme: Theme) =>
  createStyles({
    withTip: {
      fontFamily: "Open Sans",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "34px",
      cursor: "pointer",
    },
    popoverDiv: {
      marginTop: -8,
    },
    linkHover: {
      textDecoration: "none",
      color: "#1c86ed",
    },
    notLinkHover: {
      textDecoration: "underline",
      color: "inherit",
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  item: ITarget | IModel;
  content: JSX.Element;
}

export default function SoVPopover(props: IProps) {
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Link
        className={clsx(classes.withTip, {
          [classes.linkHover]: open,
          [classes.notLinkHover]: !open,
        })}
        onClick={handlePopoverOpen}
      >
        {props.item.label}
      </Link>
      <Popover
        id={`sov-popover-${props.item.ahb_id}`}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{
          paper: classes.popoverDiv,
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div
          style={{
            padding: "24px 24px 8px",
            backgroundColor: "#fff",
          }}
        >
          {props.content}
        </div>
      </Popover>
    </React.Fragment>
  );
}
