import * as React from "react";

function SpeechBubble(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" fill={"#FFF"} {...props} transform="scale(0.9)">
			<rect fill="none" width="100" height="100" />
			<g>
				<path d="M12.913,91.899c-0.789,0-1.551-0.32-2.105-0.917c-0.778-0.82-0.991-2.02-0.565-3.063l6.522-15.885   C9.162,64.99,5,55.921,5,46.28C5,25.229,25.185,8.1,50,8.1s45,17.129,45,38.18c0,21.049-20.185,38.181-45,38.181   c-3.56,0-7.199-0.39-10.833-1.157L13.83,91.755C13.531,91.852,13.217,91.899,12.913,91.899z M50,13.874   c-21.629,0-39.224,14.536-39.224,32.406c0,8.559,4.007,16.631,11.296,22.739c0.964,0.81,1.295,2.147,0.815,3.309l-4.913,11.957   l20.121-6.708c0.501-0.165,1.039-0.192,1.551-0.075c3.474,0.789,6.964,1.184,10.354,1.184c21.634,0,39.229-14.537,39.229-32.405   C89.229,28.41,71.634,13.874,50,13.874z" />
			</g>
		</svg>
	);
}

export default SpeechBubble;