import {
  ThemeOptions, createMuiTheme, responsiveFontSizes
} from '@material-ui/core/styles';

import OpenSansBold from './assets/OpenSans-Bold.ttf';
import OpenSansRegular from './assets/OpenSans-Regular.ttf';
import OpenSansSemiBold from './assets/OpenSans-SemiBold.ttf';
import RajdhaniMedium from './assets/Rajdhani-Medium.ttf';
import RajdhaniBold from './assets/Rajdhani-Bold.ttf';


const openSansRegular = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('OpenSans-Regular'),
    url(${OpenSansRegular}) format('truetype')
  `
};

const openSansSemiBold = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('OpenSans-SemiBold'),
    url(${OpenSansSemiBold}) format('truetype')
  `
};

const openSansBold = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('OpenSans-Bold'),
    url(${OpenSansBold}) format('truetype')
  `
};

const rajdhaniMedium = {
  fontFamily: 'Rajdhani',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('Rajdhani-Medium'),
    url(${RajdhaniMedium}) format('truetype')
  `
};

const rajdhaniBold = {
  fontFamily: 'Rajdhani',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('Rajdhani-Bold'),
    url(${RajdhaniBold}) format('truetype')
  `
}

let theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 788,
      md: 960,
      lg: 1200,
      xl: 1920
    }
  },
  typography: {
    fontFamily: ["Open Sans", "Rajdhani"].join(","),
    fontSize: 16,
    h1: {
      fontFamily: "Rajdhani",
      fontWeight: 700
    },
    h2: {
      fontFamily: "Rajdhani",
      fontWeight: 700
    },
    h3: {
      fontFamily: "Rajdhani",
      fontWeight: 700
    },
    h4: {
      fontFamily: "Rajdhani",
      fontWeight: 700
    },
    h5: {
      fontFamily: "Rajdhani",
      fontWeight: 700
    },
    h6: {
      fontFamily: "Rajdhani",
      fontWeight: 700
    },
    subtitle1: {
      fontWeight: 400
    },
    subtitle2: {
      fontWeight: 400
    },
    body1: {
      fontWeight: 400
    },
    body2: {
      fontWeight: 400
    },
    button: {
      borderRadius: 24
    }
  },
  palette: {
    primary: {
      main: "#1c86ed"
    },
    secondary: {
      main: "#000000",
      light: "#555555",
      dark: "#333333",
    },
    background: {
      default: "#ffffff",
      paper: "#f2f2f2"
    },
    text: {
      primary: "#000000",
      secondary: "#ffffff"
    }
  },
  shape: {
    borderRadius: 8
  },
  shadows: [
    "none",
    "none", // DRM.
    "none",  // Button shadow.
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)", // Fab.
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [openSansRegular, openSansSemiBold, openSansBold,
                       rajdhaniMedium, rajdhaniBold],
        HTML: {
          backgroundColor: "#f6f7f8"
        },
        body: {
          backgroundColor: "#f6f7f8",
          fontWeight: 700,
          overflowY: "scroll"
        }
      }
    },
    MuiBadge: {
      badge: {
        fontFamily: "Open Sans",
        fontSize: "11px",
        fontWeight: 600,
        minWidth: 16,
        height: 16
      },
      anchorOriginBottomRightRectangle: {
        right: 1,
        bottom: 1
      }
    },
    MuiButton: {
      root: {
        borderRadius: 24,
        fontFamily: "'Open Sans'",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "18px",
        letterSpacing: "1px",
        padding: "8px 32px"
      },
      contained: {
        "&$disabled": {
          color: "#1c86ed",
          backgroundColor: "rgba(0, 0, 0, 0)"
        }
      },
    },
    MuiChip: {
      labelSmall: {
        paddingLeft: 12,
        paddingRight: 12,
        fontSize: "12px",
        lineHeight: "16px"
      },
      outlined: {
        backgroundColor: "#f6f7f8",
        border: "1px solid #dcdee1"
      }
    },
    MuiDialogActions: {
      root: {
        padding: "36px",
        justifyContent: "space-between"
      }
    },
    MuiDialogContent: {
      root: {
        padding: "8px 28px 8px 36px",
      }
    },
    MuiDialogContentText: {
      root: {
        fontFamily: "Open Sans",
        fontSize: "15px",
        color: "#000000",
        fontWeight: "normal"
      }
    },
    MuiDialogTitle: {
      root: {
        padding: "24px 28px 24px 36px",
        fontFamily: "Open Sans",
        fontSize: "18px",
      }
    },
    MuiDivider: {
      root: {
        width: "100%",
        backgroundColor: "#555555"
      }
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "black"
        }
      }
    },
    MuiIconButton: {
      colorSecondary: {
        color: "#ffffff"
      }
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: "none"
        },
        "&::before": {
          borderBottom: "none"
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "none"
        }
      }
    },
    MuiLink: {
      underlineHover: {
        "&:hover": {
          textDecoration: "none"
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#1c86ed",
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontFamily: "Rajdhani",
        fontWeight: 700,
        textTransform: "uppercase",

        "&.MuiTypography-h4": {
          fontSize: "24px"
        }
      }
    },
    MuiPickersMonth: {
      root: {
        fontFamily: "Open Sans",
        fontWeight: 600
      },
      yearSelected: {
        fontWeight: 600
      }
    },
    MuiPickersYear: {
      root: {
        fontFamily: "Open Sans",
        fontWeight: 600
      },
      yearSelected: {
        fontWeight: 600
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
      multiline: {
        borderRadius: 0,
        padding: "20px 28px 60px"
      },
      inputMultiline: {
        fontFamily: '"Open Sans"',
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: 1.5
      },
      input: {
        padding: "9px 12px",
        borderRadius: 2
      },
    },
    MuiRadio: {
      root: {
        color: "#1c86ed"
      }
    },
    MuiSelect: {
      select: {
        "&.MuiSelect-select": {
          paddingRight: 6
        },
        "&:focus": {
          backgroundColor: "inherit"
        }
      }
    },
    MuiTableCell: {
      body: {
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
      },
      head: {
        padding: "11px 8px",
        fontFamily: "Open Sans",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.5px",
        color: "#8f979f",
        textTransform: "uppercase"
      },
      root: {
        padding: "22px 8px"
      }
    },
    MuiTableSortLabel: {
      icon: {
        color: "#8f979f"
      },
      root: {
        "&:hover": {
          color: "#7b7e82"
        }
      }
    }
  }
} as ThemeOptions);

theme = responsiveFontSizes(theme);

export default theme;