import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import theme from "../theme";

const months3letters = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const tooltipStyles = (theme: Theme) =>
  createStyles({
    tooltipContainer: {
      padding: "8px 16px",
      backgroundColor: "#fff",
      border: "1px solid #aaa",
      borderRadius: 5,
    },
    tooltipTitle: {
      marginBottom: 4,
      fontWeight: 700,
      fontSize: "14px",
    },
    tooltipEntry: {
      fontWeight: 600,
      fontSize: "14px",
    },
  });

const useTooltipStyles = makeStyles(tooltipStyles);

/**
 * Custom Tooltip to order of brands or models in descending order of their
 * values. So if the user had selected brands BMW, Audi, Volkswagen, and at a
 * given point in the Y-axis the values where: BMW: 3, Audi: 5, Volkwagen: 4,
 * the Tooltip at that Y-axis point should display the brands in the order:
 * Audi, Volkswagen, BMW.
 *
 * @param props
 * @returns
 */
export default function GraphTooltipContent(props: any) {
  const classes = useTooltipStyles(theme);
  const { active, payload, label } = props;

  if (active && payload && payload.length) {
    let lbl = label;
    if (label.indexOf("-") > -1) {
      const bits = label.split("-");
      lbl = `${months3letters[parseInt(bits[1]) - 1]} - ${bits[0]}`;
    }

    const spayload = payload.sort((a: any, b: any) => {
      const a_val = parseFloat(a.value);
      const b_val = parseFloat(b.value);
      if (a_val < b_val) return 1;
      if (a_val > b_val) return -1;
      return 0;
    });
    return (
      <div className={classes.tooltipContainer}>
        <Typography className={classes.tooltipTitle}>{lbl}</Typography>
        {spayload.map((item: any) => (
          <Typography
            key={`key-${item.name}-${item.value}`}
            className={classes.tooltipEntry}
            style={{ color: item.color }}
          >
            {item.name}: {item.value}
          </Typography>
        ))}
        <p></p>
      </div>
    );
  } else return null;
}
