import React from "react";
import { Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";

import theme from "../theme";
import { StateCtx } from "../contexts";
import { ActionTypes } from "../reducers";
import { IModel, ITarget, ChangeImpact } from "../Ifaces.d";
import TopPopper from "./TopPopper";

interface IProps {
  placeholder?: string;
  changeImpact?: ChangeImpact
  onChange?: (params: Array<ITarget>)=>void
}

const styles = (theme: Theme) =>
  createStyles({
    autocompleteRoot: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
      "& .MuiOutlinedInput-root": {
        paddingLeft: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    autocompleteInput: {
      fontSize: "13px",
      lineHeight: "24px",
      color: "#8f979f",
    },
    myNoOptions: {
      backgroundColor: "#fff",
      borderTop: "1px solid #efefef",
      fontSize: "14px",
      lineHeight: 1,
      color: "#8f979f",
    },
  });

const useStyles = makeStyles(styles);

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option: IModel) => option.label,
});

export function ModelsAutocomplete(props: IProps) {
  const classes = useStyles(theme);
  const { state, dispatch } = React.useContext(StateCtx);
  const [value, setValue] = React.useState<IModel[]>([
    ...state.model_selection,
  ]);

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if( props.changeImpact === ChangeImpact.local ){
      if( props.onChange ){
        props.onChange(value)
      }
    }else{
      dispatch({
        type: ActionTypes.SET_MODEL_SELECTION,
        model_selection: value,
      });
    }
  }, [value, dispatch]);

  const handleChange = (event: any, newValue: (string | IModel)[] | null) => {
    if (newValue !== null && typeof newValue[0] !== "string") {
      if (newValue.length <= 10) {
        setValue(newValue as IModel[]);
      } else {
        enqueueSnackbar("There is a limit of 10 models.", { variant: "info" });
      }
    }
  };

  const getPlaceholder = () => {
    if (state.model_selection.length === 10) return "";
    else return props.placeholder ?? "Add models for comparison";
  };

  return (
    <div className={classes.autocompleteRoot}>
      <Autocomplete
        multiple
        id="tags"
        onChange={handleChange}
        options={state.models}
        filterOptions={filterOptions}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option: IModel, value: IModel) => {
          return (option.ahb_id === value.ahb_id);
        }}
        value={value}
        renderTags={(value: IModel[], getTagProps) =>
          value.map((option: IModel, index: number) => (
            <Chip
              variant="outlined"
              label={option.label}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={getPlaceholder()}
          />
        )}
        classes={{
          input: classes.autocompleteInput,
          noOptions: classes.myNoOptions,
        }}
        PopperComponent={TopPopper}
        noOptionsText={"No options"}
      />
    </div>
  );
}

export function OwnModelsAutocomplete() {
  const classes = useStyles(theme);
  const { state, dispatch } = React.useContext(StateCtx);
  const [value, setValue] = React.useState<IModel[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const opts = React.useMemo(() => {
    return state.models.filter((m: IModel) =>
      m.ahb_id.startsWith((state.brand as ITarget).ahb_id),
    );
  }, [state.brand, state.models]);

  React.useEffect(() => {
    // This useEffect loads the starting selection displayed when the user
    // shall see only models of her own brand.
    if (opts && opts.length > 0 && value.length === 0) {
      const _value = opts.filter((model: IModel) => {
        const pos = state.model_selection.findIndex(
          (m: IModel) => m.ahb_id === model.ahb_id,
        );
        if (pos !== -1) return true;
        else return false;
      });
      setValue(_value);
    }
  }, [opts, value, state.model_selection]);

  const handleSelection = (values: IModel[]) => {
    let selection: IModel[] = [...state.model_selection];
    // Add new values.
    for (let i = 0; i < values.length; i++) {
      const elem = values[i];
      const pos = selection.findIndex((item) => item.ahb_id === elem.ahb_id);
      if (pos === -1) selection.push(elem);
    }
    // Delete those that are not listed in 'values'.
    for (let i = 0; i < selection.length; i++) {
      if (!selection[i].ahb_id.startsWith((state.brand as ITarget).ahb_id))
        continue;
      const ahb_id = selection[i].ahb_id;
      const pos = values.findIndex((item: IModel) => item.ahb_id === ahb_id);
      if (pos === -1) {
        selection.splice(i, 1);
      }
    }
    selection = selection.sort((a: IModel, b: IModel) => {
      if (a.ahb_id < b.ahb_id) return -1;
      if (a.ahb_id > b.ahb_id) return 1;
      return 0;
    });
    dispatch({
      type: ActionTypes.SET_MODEL_SELECTION,
      model_selection: selection,
    });
  };

  const handleChange = (event: any, newValue: (string | IModel)[] | null) => {
    if (newValue !== null && typeof newValue[0] !== "string") {
      if (newValue.length <= 10) {
        setValue(newValue as IModel[]);
        handleSelection(newValue as IModel[]);
      } else {
        enqueueSnackbar("There is a limit of 10 models.", { variant: "info" });
      }
    }
  };

  const getPlaceholder = () => {
    if (value.length === opts.length) return "";
    return "Add models for comparison";
  };

  return (
    <div className={classes.autocompleteRoot}>
      <Autocomplete
        multiple
        id="tags"
        onChange={handleChange}
        options={opts}
        filterOptions={filterOptions}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option: IModel, value: IModel) => {
          return (option.ahb_id === value.ahb_id);
        }}
        value={value}
        renderTags={(value: IModel[], getTagProps) =>
          value.map((option: IModel, index: number) => (
            <Chip
              variant="outlined"
              label={option.label}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={getPlaceholder()}
          />
        )}
        classes={{
          input: classes.autocompleteInput,
          noOptions: classes.myNoOptions,
        }}
        PopperComponent={TopPopper}
        noOptionsText={"No options"}
      />
    </div>
  );
}
