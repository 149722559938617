import { Link as RouterLink } from "react-router-dom";
import { Link, Paper, Typography, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import theme from "../theme";
import {
  UserDiscussionsIcon,
  StarIcon,
  SafetyIcon,
  ShareVoiceIcon,
  PricingDevIcon,
  WrenchIcon,
} from "../Icons";

const styles = (theme: Theme) =>
  createStyles({
    paperRoot: {
      padding: 28,
      flexGrow: 1,
      borderRadius: 8,
      backgroundColor: "#fff",
    },
    content: {
      padding: 60,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    item: {
      minWidth: 180,
      padding: "0 16px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      borderLeft: "1px solid #dcdee1",
      "&:first-child": {
        borderLeft: "none",
      },
    },
    imageFrame: {
      width: 120,
      height: 120,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > *": {
        fill: "#fff",
      },
      "& > *:hover": {
        fill: "#CDE2F6",
      },
    },
    label: {
      minWidth: 196,
      paddingTop: 24,
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
      textAlign: "center",
      color: "#000",
    },
    secondaryHeader: {
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "1.2rem",
      textTransform: "uppercase",
      marginTop: "40px",
    },
  });

const useStyles = makeStyles(styles);

export default function FooterWidget() {
  const classes = useStyles(theme);

  return (
    <Paper className={classes.paperRoot}>
      <Typography
        className={classes.secondaryHeader}
        style={{ marginTop: "15px", marginLeft: "15px"}}
      >
        Please select the data you need
      </Typography>
      <div className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Link component={RouterLink} to="/car-specs">
              <div className={classes.item}>
                <div
                  className={classes.imageFrame}
                  style={{ alignItems: "baseline" }}
                >
                  <WrenchIcon fill={"#000"} transform={"scale(0.5)"}/>
                </div>
                <Typography className={classes.label}>Car Specs</Typography>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* <Link component={RouterLink} to="/details/vehicle-rating"> */}
            <Link component={RouterLink} to="/vehicle-rating">
              <div className={classes.item}>
                <div
                  className={classes.imageFrame}
                  style={{ alignItems: "baseline" }}
                >
                  <StarIcon />
                </div>
                <Typography className={classes.label}>
                  Vehicle Rating
                </Typography>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* <Link component={RouterLink} to="/details/user-discussions"> */}
            <Link component={RouterLink} to="/user-discussions">
              <div className={classes.item}>
                <div className={classes.imageFrame}>
                  <UserDiscussionsIcon />
                </div>
                <Typography className={classes.label}>
                  User Discussions
                </Typography>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* <Link component={RouterLink} to="/details/share-of-voice"> */}
            <Link component={RouterLink} to="/share-of-voice">
              <div className={classes.item}>
                <div className={classes.imageFrame}>
                  <ShareVoiceIcon />
                </div>
                <Typography className={classes.label}>
                  Share Of Voice
                </Typography>
              </div>
            </Link>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4}>
          <Link component={RouterLink} to="/details/s2-check">
            <div className={classes.item}>
              <div className={classes.imageFrame}>
                <SafetyIcon />
              </div>
              <Typography className={classes.label} style={{ marginTop: -5 }}>
                S<sup style={{ fontSize: "0.6em" }}>2</sup> Check
              </Typography>
            </div>
          </Link>
        </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4}>
          <Link component={RouterLink} to="/details/discount-development">
            <div className={classes.item}>
              <div className={classes.imageFrame}>
                <PricingDevIcon />
              </div>
              <Typography className={classes.label}>
                Discount Development
              </Typography>
            </div>
          </Link>
        </Grid> */}
        </Grid>
      </div>
    </Paper>
  );
}
