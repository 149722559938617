import React from "react";

import { AuthCtx, User } from "./contexts";
import { auth, firestore } from "./Firebase";

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<User>(null);
  const [loadingAuth, setLoadingAuth] = React.useState<boolean>(true);

  const initializeUser = async ( uid: string ) => {
    const docRef = firestore.collection('USER_DEFAULTS').doc(uid);
    const doc = await docRef.get();
    if(!doc.exists){
      docRef.set({
        alerts: 0,
        brand: "BMW",
        own_brand: "BMW",
        region: "DE"
      })
    }
  }

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      initializeUser(firebaseUser?.uid ?? "").catch(e => {
        console.log(e)
        //  TODO: show error alert
      }).finally(()=> {
        setUser(firebaseUser);
        setLoadingAuth(false);
      })
    });

    return unsubscribe;
  }, []);

  return (
    <AuthCtx.Provider
      value={{
        user: user,
        loading: loadingAuth,
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};
