import React from "react";
import { Link } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { getDownloadURL, analytics } from "../Firebase";
import theme from "../theme";

const styles = (theme: Theme) =>
  createStyles({
    download: {
      marginTop: "30px",
      width: "100%",
      height: "80px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      fontFamily: "Rajdhani",
      fontWeight: 600,
      fontSize: "1.3rem",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: "#FFF",
      cursor: "pointer",
      background: "#2563EB",
      borderRadius: "10px",
      userSelect: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  });

const useStyles = makeStyles(styles);

export interface DataDownloadButtonProps {
  handleDownloadButtonPressed?: () => Promise<void>;
}

export default function DataDownloadButton(props: DataDownloadButtonProps) {
  const classes = useStyles(theme);
  const [loadingDownloadURL, setLoadingDownloadURL] = React.useState(false);

  const handleDownloadJob = async () => {
    if (loadingDownloadURL || !props.handleDownloadButtonPressed) return;
    setLoadingDownloadURL(true);
    try {
      await props.handleDownloadButtonPressed()
      setLoadingDownloadURL(false);
    } catch (error) {
      setLoadingDownloadURL(false);
      throw error;
    }
  };

  return (
    <Link className={classes.download} onClick={handleDownloadJob}>
      {loadingDownloadURL ? "DOWNLOADING..." : "DOWNLOAD"}
    </Link>
  );
}
