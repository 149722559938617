import React from "react";
import {
  Link as RouterLink,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { Container, Link, Paper, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";

import theme from "../../theme";
import {
  IAHBKpis,
  IAHBUserDiscussions,
  ITarget,
  IModel,
  TargetType,
} from "../../Ifaces.d";
import { StateCtx } from "../../contexts";
import { ActionTypes } from "../../reducers";
import { BarChartsIcon, DownloadIcon, SmallInfoIcon } from "../../Icons";
import { UserDiscussionChart, UserDiscussionDetails } from "./index";
import DateSelector from "../DateSelector";
import * as API from "../../API";

// --------------------------------------------------------------------
// ChartCart.

interface IChartCardProps {
  classes: ReturnType<typeof useStyles>;
  target: TargetType;
  item: ITarget | IModel;
  ratings: number[][];
  isLoading: boolean;
}

function ChartCard(props: IChartCardProps) {
  const { classes, ratings = false, isLoading = false } = props;

  const [positive, setPositive] = React.useState(0);
  const [neutral, setNeutral] = React.useState(0);
  const [negative, setNegative] = React.useState(0);

  React.useEffect(() => {
    if (ratings && ratings.length === 16) {
      setPositive(ratings.map((n) => n[0]).reduce((a, b) => a + b) / 16);
      setNeutral(ratings.map((n) => n[1]).reduce((a, b) => a + b) / 16);
      setNegative(ratings.map((n) => n[0]).reduce((a, b) => a + b) / 16);
    }
  }, [ratings]);

  return (
    <div className={classes.chartCardRoot}>
      <Paper>
        <div className={clsx("header")}>
          <span>{props.item.label}</span>
          {props.target === TargetType.brands && (
            <img
              src={`/assets/brands/${props.item.ahb_id}.svg`}
              alt={props.item.label}
              height="20"
            />
          )}
        </div>
        <div className={clsx("divider")} />
        {isLoading ? (
          <div className={classes.progressContainer}>
            <CircularProgress className={classes.progress} color="secondary" />
          </div>
        ) : (
          <UserDiscussionChart
            positive={positive}
            neutral={neutral}
            negative={negative}
          />
        )}
      </Paper>
    </div>
  );
}

// --------------------------------------------------------------------
// OverviewContent.

interface IOverviewProps {
  classes: ReturnType<typeof useStyles>;
  selection: (ITarget | IModel)[];
  ratings: number[][][];
  isLoading: boolean;
}

function OverviewContent(props: IOverviewProps) {
  const { classes, isLoading = true } = props;
  const { state, dispatch } = React.useContext(StateCtx);

  const { path } = useRouteMatch();

  React.useEffect(() => {
    dispatch({ type: ActionTypes.SET_DETAILS_PATH, details_path: path });
  }, [path, dispatch]);

  if (!props.ratings) return <span />;

  return (
    <div className={classes.overviewDiv}>
      {props.selection.map((item: ITarget | IModel, idx: number) => (
        <div key={`chart-card-${item.ahb_id}`}>
          {
            <ChartCard
              classes={classes}
              target={state.target}
              item={item}
              ratings={props.ratings[idx]}
              isLoading={isLoading}
            />
          }
        </div>
      ))}
    </div>
  );
}

// --------------------------------------------------------------------
// ColumnCard.

interface IColumnCardProps {
  classes: ReturnType<typeof useStyles>;
  target: TargetType;
  item: ITarget | IModel;
  ratings: number[][];
  kpis: IAHBKpis;
  isLoading: boolean;
}

function ColumnCard(props: IColumnCardProps) {
  const { classes, isLoading = false } = props;

  return (
    <div className={classes.columnCardRoot}>
      <Paper>
        <div className={clsx("header")}>
          <span>{props.item.label}</span>
          {props.target === TargetType.brands && (
            <img
              src={`/assets/brands/${props.item.ahb_id}.svg`}
              alt={props.item.label}
              height="20"
            />
          )}
        </div>
        <div className={clsx("divider")} />
        <div style={{ marginTop: 32 }}>
          {isLoading ? (
            <div className={classes.progressContainer}>
              <CircularProgress
                className={classes.progress}
                color="secondary"
              />
            </div>
          ) : (
            <UserDiscussionDetails ratings={props.ratings} kpis={props.kpis} />
          )}
        </div>
      </Paper>
    </div>
  );
}

// --------------------------------------------------------------------
// DetailsContent.

interface IDetailsProps {
  classes: ReturnType<typeof useStyles>;
  target: TargetType;
  selection: (ITarget | IModel)[];
  ratings: number[][][];
  kpis: IAHBKpis;
  isLoading: boolean;
}

function DetailsContent(props: IDetailsProps) {
  const { classes, isLoading = false } = props;
  const { dispatch } = React.useContext(StateCtx);
  const [leftShadow, setLeftShadow] = React.useState(false);
  const [rightShadow, setRightShadow] = React.useState(false);
  const { path } = useRouteMatch();

  React.useEffect(() => {
    function computeOnScroll() {
      if (elem) {
        if (elem.scrollWidth > elem.clientWidth + elem.scrollLeft) {
          setRightShadow(true);
        } else {
          setRightShadow(false);
        }
        if (elem.scrollLeft === 0) {
          setLeftShadow(false);
        } else {
          setLeftShadow(true);
        }
      }
    }

    const elem = document.getElementById("details");
    if (elem !== undefined) {
      elem?.addEventListener("scroll", computeOnScroll);
      computeOnScroll();
    }
  }, [setLeftShadow, setRightShadow, props.selection]);

  React.useEffect(() => {
    dispatch({ type: ActionTypes.SET_DETAILS_PATH, details_path: path });
  }, [path, dispatch]);

  return (
    <React.Fragment>
      <div
        id="details"
        className={
          rightShadow && leftShadow
            ? classes.detailsDivBothShadows
            : rightShadow
            ? classes.detailsDivRightShadow
            : leftShadow
            ? classes.detailsDivLeftShadow
            : classes.detailsDiv
        }
      >
        {props.selection.map((item: ITarget | IModel, idx: number) => (
          <div key={`column-${item.ahb_id}`}>
            <ColumnCard
              classes={classes}
              target={props.target}
              item={item}
              ratings={props.ratings[idx]}
              kpis={props.kpis}
              isLoading={isLoading}
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

// --------------------------------------------------------------------
// UserDiscussionTab styles and component.

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: "block",
      fontFamily: "Rajdhani",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
    },
    links: {
      "& > *": {
        marginRight: 24,
        display: "inline-flex",
        alignItems: "center",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
        color: "#454c53",
      },
      "& > *:last-child": {
        marginRight: 0,
      },
    },
    tooltip: {
      width: 240,
      marginTop: 1,
      padding: "10px",
      color: "#000",
      backgroundColor: "#fff",
      borderRadius: 2,
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      fontSize: "15px",
      lineHeight: 1.4,
    },
    arrow: {
      color: "#fff",
    },
    icon: {
      marginLeft: 8,
      display: "inline",
      lineHeight: 1.1,
    },
    secondHeader: {
      marginTop: 19,
      marginBottom: 21,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    legendDiv: {
      height: 25,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "& .text": {
        fontFamily: "Open Sans",
        fontStyle: "italic",
        fontSize: "12px",
      },
      "& .positive": {
        width: 20,
        height: 6,
        backgroundColor: "#70d57d",
        marginRight: 8,
      },
      "& .neutral": {
        marginLeft: 20,
        width: 20,
        height: 6,
        backgroundColor: "#b2b7bc",
        marginRight: 8,
      },
      "& .negative": {
        marginLeft: 20,
        width: 20,
        height: 6,
        backgroundColor: "#ee754f",
        marginRight: 8,
      },
    },
    content: {
      width: "100%",
      minHeight: 453,
      position: "relative",
    },
    overviewDiv: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "376px 376px 376px",
      gridGap: 36,
      padding: 0,
      paddingBottom: 16,
    },

    detailsDiv: {
      width: "100%",
      height: 885,
      display: "flex",
      overflow: "auto",
      "& > DIV": {
        marginRight: 36,
      },
      "& > DIV:last-child": {
        marginRight: 0,
      },
    },

    detailsDivLeftShadow: {
      width: "100%",
      height: 885,
      display: "flex",
      overflow: "auto",
      "& > DIV": {
        marginRight: 36,
      },
      "& > DIV:last-child": {
        marginRight: 0,
      },
      "&::before": {
        position: "absolute",
        boxShadow: "-10px 0 10px -10px #dedede inset",
        content: '""',
        height: 861,
        width: 20,
        left: -20,
        top: 0,
      },
    },

    detailsDivRightShadow: {
      width: "100%",
      height: 885,
      display: "flex",
      overflow: "auto",
      "& > DIV": {
        marginRight: 36,
      },
      "& > DIV:last-child": {
        marginRight: 0,
      },
      "&::after": {
        position: "absolute",
        boxShadow: "10px 0 10px -10px #dedede inset",
        content: '""',
        height: 861,
        width: 20,
        right: -20,
        top: 0,
      },
    },

    detailsDivBothShadows: {
      width: "100%",
      height: 885,
      display: "flex",
      overflow: "auto",

      "&::before": {
        position: "absolute",
        boxShadow: "-10px 0 10px -10px #dedede inset",
        content: '""',
        height: 861,
        width: 20,
        left: -20,
        top: 0,
      },
      "&::after": {
        position: "absolute",
        boxShadow: "10px 0 10px -10px #dedede inset",
        content: '""',
        height: 861,
        width: 20,
        right: -20,
        top: 0,
      },

      "& > DIV": {
        marginRight: 36,
      },
      "& > DIV:last-child": {
        marginRight: 0,
      },
    },

    // ChartCard styles.
    chartCardRoot: {
      display: "inline-block",

      "& .MuiPaper-root": {
        width: 376,
        height: 437,
        backgroundColor: "white",
        padding: "25px 36px",
      },
      "& .header": {
        marginTop: 8,
        marginBottom: 8,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        overflow: "hidden",
        fontFamily: "Rajdhani",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color: "black",
      },
      "& .divider": {
        height: 1,
        backgroundColor: "#dcdee1",
      },
    },

    // ColumnCard styles.
    columnCardRoot: {
      "& .MuiPaper-root": {
        width: 376,
        height: 861,
        backgroundColor: "white",
        padding: "25px 36px",
      },
      "& .header": {
        margin: "8px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        overflow: "hidden",
        fontFamily: "Rajdhani",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color: "black",
      },
      "& .divider": {
        height: 1,
        backgroundColor: "#dcdee1",
      },
    },

    //  progress
    progressContainer: {
      textAlign: "center",
    },
    progress: {
      marginTop: "150px",
    },
  });

const useStyles = makeStyles(styles);

interface IPath2View {
  path: string;
  view: string;
}
const path2view: IPath2View[] = [
  { path: "/details/user-discussions/details", view: "details" },
  { path: "/details/user-discussions", view: "overview" },
];

export default function UserDiscussionsTab() {
  const classes = useStyles(theme);
  const { state } = React.useContext(StateCtx);
  const [ratings, setRatings] = React.useState<number[][][]>([]);
  const [selection, setSelection] = React.useState<(ITarget | IModel)[]>([]);
  const [kpis, setKpis] = React.useState<IAHBKpis>([]);
  const [isLoading, setLoadingState] = React.useState(false);

  const tsel = React.useMemo(() => {
    if (state.target === TargetType.brands) return state.brand_selection;
    else return state.model_selection;
  }, [state.target, state.brand_selection, state.model_selection]);

  const view = React.useMemo(() => {
    const elem: IPath2View | undefined = path2view.find((item: IPath2View) =>
      state.details_path.startsWith(item.path),
    );
    if (elem !== undefined) {
      return elem.view;
    } else {
      return "overview";
    }
  }, [state.details_path]);

  const fetchUserDiscussions = React.useCallback(
    async (
      ahb_id: string,
      targetType: TargetType,
    ): Promise<IAHBUserDiscussions | undefined> => {
      try {
        const ratings = await API.fetchUserDiscussions(
          ahb_id,
          targetType,
          state.region.code.toUpperCase(),
        );
        return ratings;
      } catch (error) {
        console.log(error);
      }
    },
    [state.region],
  );

  const fetchKpis = React.useCallback(async (): Promise<
    IAHBKpis | undefined
  > => {
    try {
      const kpis = await API.fetchKpis();
      return kpis;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getElementRatings = React.useCallback(
    async (ahb_id: string) => {
      const ahb_user_discussions = await fetchUserDiscussions(
        ahb_id,
        state.target,
      );
      if (ahb_user_discussions) {
        return ahb_user_discussions.ratings;
      } else {
        return [];
      }
    },
    [state.target, fetchUserDiscussions],
  );

  React.useEffect(() => {
    setSelection([]); // Reset selection.
  }, [state.target]);

  React.useEffect(() => {
    if (tsel.length !== selection.length) {
      const _selection = [...selection];

      // New items found in tsel.
      const newSelection = [];
      for (let i = 0; i < tsel.length; i++) {
        const pos = selection.findIndex(
          (item) => item.ahb_id === tsel[i].ahb_id,
        );
        if (pos === -1) {
          newSelection.push(tsel[i]);
        }
      }
      // Delete those items that no longer exist in the tsel.
      const delSelection = [];
      for (let i = 0; i < _selection.length; i++) {
        const pos = tsel.findIndex(
          (item) => item.ahb_id === _selection[i].ahb_id,
        );
        if (pos === -1) {
          delSelection.push(_selection[i]);
        }
      }
      if (delSelection.length) {
        for (let i = 0; i < delSelection.length; i++) {
          _selection.splice(_selection.indexOf(delSelection[i]), 1);
        }
      }
      setSelection([..._selection, ...newSelection]);
    }
  }, [tsel, selection, setSelection]);

  React.useEffect(() => {
    const fetchData = async () => {
      const _ratings = [];
      setLoadingState(true);
      for (let i = 0; i < selection.length; i++) {
        const user_discussions = await getElementRatings(selection[i].ahb_id);
        _ratings.push(user_discussions);
      }
      setLoadingState(false);
      const kpis = await fetchKpis();
      setRatings(_ratings);
      setKpis(kpis ?? []);
    };
    fetchData();
  }, [selection, setRatings, getElementRatings, fetchKpis]);

  return (
    <Container maxWidth="lg">
      <Typography className={classes.header}>
        What do customers talk about your brand?
        <Tooltip
          arrow
          placement="top"
          title="This is what shows up when I hover the small info icon"
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
        >
          <span className={classes.icon}>
            <SmallInfoIcon />
          </span>
        </Tooltip>
      </Typography>
      <div className={classes.secondHeader}>
        <div className={classes.legendDiv}>
          <span className={clsx("positive")} />
          <Typography className={clsx("text")}>Positive</Typography>
          <span className={clsx("neutral")} />
          <Typography className={clsx("text")}>Neutral</Typography>
          <span className={clsx("negative")} />
          <Typography className={clsx("text")}>Negative</Typography>
        </div>
        <div className={classes.links}>
          <Link
            href="#"
            component={RouterLink}
            to={`/details/user-discussions${
              view === "overview" ? "/details" : ""
            }`}
          >
            <BarChartsIcon />
            &nbsp;&nbsp;{view === "details" ? "Overview" : "Detail"} view
          </Link>

          <DateSelector hidden />

          <Link
            href="#"
            onClick={(ev: React.SyntheticEvent) => ev.preventDefault()}
          >
            <DownloadIcon />
            &nbsp;&nbsp;Download as PDF
          </Link>
        </div>
      </div>

      <div className={classes.content}>
        <Switch>
          <Route exact path="/details/user-discussions/details">
            <DetailsContent
              classes={classes}
              target={state.target}
              selection={selection}
              ratings={ratings}
              kpis={kpis}
              isLoading={isLoading}
            />
          </Route>
          <Route path="/details/user-discussions">
            <OverviewContent
              classes={classes}
              selection={selection}
              ratings={ratings}
              isLoading={isLoading}
            />
          </Route>
        </Switch>
      </div>
    </Container>
  );
}
