import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import theme from "./theme";
import { auth } from "./Firebase";
import IndexPage from "./pages/Index";
// import DashboardPage from "./pages/Dashboard";
// import DetailsPage from "./pages/Details";
// import MeasuresPage from "./pages/Measures";
import UserRatingsPage from "./pages/UserRatingsPage";
import UserDiscussionsPage from "./pages/UserDiscussionsPage";
import ShareOfVoicePage from "./pages/ShareOfVoicePage";
// import CautionPage from "./pages/Caution";
import FAQPage from "./pages/FAQPage";
import DataPage from "./pages/Data";
import Selection from "./components/Selection";
import NavigationBar from "./components/NavigationBar";

const styles = (theme: Theme) =>
  createStyles({
    body: {
      flexGrow: 1,
      paddingBottom: 104,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
      paddingLeft: "71px",
      "& .MuiContainer-root": {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    whiteBand: {
      backgroundColor: "#fff",
    },
  });

const useStyles = makeStyles(styles);


export default function AppRouter() {
  const classes = useStyles(theme);

  const handleUserLogout = async () => {
    await auth.signOut();
  };

  const routesWithSelection = [
    "vehicle-rating",
    "user-discussions",
    "share-of-voice",
  ]

  return (
    <BrowserRouter>
      {/* <Grid container justify="space-between" alignItems="stretch"> */}
        <NavigationBar handleLogout={handleUserLogout} />
        <div className={classes.body}>
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route path="/car-specs" component={DataPage} />
            {/* <Route path="/details" component={DetailsPage} /> */}
            {/* <Route path="/measures" component={MeasuresPage} /> */}
            <Route path="/vehicle-rating" component={UserRatingsPage} />
            <Route path="/user-discussions" component={UserDiscussionsPage} />
            <Route path="/share-of-voice" component={ShareOfVoicePage} />
            <Route path="/faq" component={FAQPage} />
            {/* <Route path="/caution" component={CautionPage} /> */}
          </Switch>
        </div>
      {/* </Grid> */}
    </BrowserRouter>
  );
}
