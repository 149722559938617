import React from "react";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import theme from "../../theme";
import { ITarget, IModel } from "../../Ifaces.d";

const styles = (theme: Theme) =>
  createStyles({
    tooltipDiv: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      "& > DIV": {
        flexGrow: 1,
      },
      "& UL": {
        padding: "0 16px",
        paddingInlineStart: 0,
      },
      "& LI": {
        padding: "4px 0",
        listStyleType: "none",
        opacity: "90%",
        color: "black",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    tooltipHeaders: {
      letterSpacing: "0.5px",
      lineHeight: "16px",
      color: "#1c86ed",
      fontWeight: 600,
      fontSize: "12px",
      textTransform: "uppercase",
    },
  });

const useStyles = makeStyles(styles);

interface IProps {
  // Use the props to fetch extra data for the title.
  // Or maybe do it in the parent component, so that
  // when you need the Tooltip rendered the content
  // is already available.
  item: ITarget | IModel;
  termId: string;
}

export default function ShortTooltipContent(props: IProps) {
  const classes = useStyles(theme);

  return (
    <div className={classes.tooltipDiv}>
      <div>
        <Typography className={classes.tooltipHeaders}>
          Customer Statements
        </Typography>
        <ul>
          <li>
            <q>Expect 10% less range than specified.</q>
          </li>
          <li>
            <q>Beware of cold weather.</q>
          </li>
          <li>
            <q>Loading takes longer than I thought.</q>
          </li>
          <li>
            <q>Better compare with other models.</q>
          </li>
        </ul>
      </div>
    </div>
  );
}
